import { useAppSelector, useAppDispatch } from "../../hooks";
import { Link, Navigate } from "react-router-dom";
import { Layout, Menu, Content, Header } from "../UI/Layout";
import { Button } from "../UI/Controls/Button";
import ProfileDropdownMenu from "../Menu/ProfileDropdown";
import { doLogout } from "../../store/auth";
import { BUILD } from "../../constants/api";

export const Home = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const merchant = useAppSelector((state) => ({
    object: state.merchant.object,
  }));
  const auth = useAppSelector((state) => ({
    me: state.auth.me,
  }));
  const { object: { title = "", logo = "" } = {} } = merchant || {};

  if (!(auth.me && auth.me._id)) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  const handleMenuClick = (object: any) => {
    const { key } = object;
    if (key === "logout") {
      doLogout()(dispatch);
    }
  };

  const menu = [];
  menu.push(
    <Menu.Item key="/" className="profile-menu">
      <ProfileDropdownMenu
        me={auth.me}
        handleMenuClick={handleMenuClick}
      ></ProfileDropdownMenu>
    </Menu.Item>
  );

  return (
    <Layout>
      <Header>
        <div className="flexFlowRow flexJCSpaceBetween">
          <div className="app-branding">
            <Link to="/" className="app-title">
              {title}
            </Link>
          </div>
          {auth.me ? (
            <Menu theme="dark" className="app-menu" mode="horizontal">
              {menu}
            </Menu>
          ) : null}
        </div>
      </Header>
      <Content className="app-content">
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "60px",
            }}
          >
            {logo ? (
              <img
                src={logo}
                alt={title}
                style={{ maxWidth: "500px", width: "100%" }}
              />
            ) : null}
            {auth.me ? (
              <div style={{ marginTop: "60px" }}>
                <Button
                  size="lg"
                  type="primary"
                  label="Start New Order"
                  to="/register"
                />
              </div>
            ) : null}
            <span className="text-center text-xs mt-20 text-color-grayed">
              Build: {BUILD}
            </span>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
