import { useEffect, useRef } from "react";
import { Input } from "../../components/UI/Layout";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ScanOutlined } from "@ant-design/icons";
import { setSiderCollapsed, setSiderMode } from "../../store/sider";
import { dispatchTyped, dispatchFailure } from "../../store/notify";
import { ScaleModule } from "../../store/IGWExtension";
import { ProductAPI } from "../../util/API";
import { getErrorString } from "../../util/helpers";
import { genHash, itemsSelector } from "../../store/selector/cart";
import { ICartItem, IProduct } from "../../store/types";
import { addOrderItem } from "../../store/order";
import { Debounced } from "../../util/Debounced";
import { UPC } from "../../util/UPC";
import ProdByBarcodeValidator from "../../util/ProductBarcode";
const { decodePriceEmbedded, isPriceEmbedded, isRecognizeBarcode } = UPC;

export const ScannerInput = (): JSX.Element => {
  const inputRef = useRef<any>(null);
  const pathname = useLocation().pathname;
  const url = pathname; // NEEDS CHECKING

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ c: string; 0: string; m: string; p: string }>();
  const { p = "" } = params;

  const { items, store, igwExtension } = useAppSelector((state) => ({
    items: itemsSelector(state),
    product: itemsSelector(state).find((o: ICartItem) => o.hash === p),
    igwExtension: state.igwExtension,
    store: state.store.selected,
    order: state.order,
  }));

  useEffect(() => {
    if (inputRef) {
      inputRef.current!.focus({
        cursor: "all",
      });
    }
  }, []);

  useEffect(() => {
    if (igwExtension.scannerValue) {
      onStartSearch(igwExtension.scannerValue);
    }
  }, [igwExtension.scannerValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onReadWeight = () => {
    if (igwExtension.status === "success") {
      ScaleModule.readWeight()(dispatch);
    }
  };

  const onSearch = (e: any) => {
    const barcode = e.target.value; /// this might be wrong
    onStartSearch(barcode);
  };

  const onStartSearch = (barcode: string) => {
    const { name: storeName } = store;
    /*
        1) isSoldByWeight
        */
    const isSoldByWeight = barcode && isPriceEmbedded({ upc: barcode });
    const isDroppedBarcode =
      barcode && barcode.length === 12 && +barcode[0] === 2
        ? isRecognizeBarcode(0 + barcode)
        : false;
    const droppedBarcode = isDroppedBarcode ? 0 + barcode : false;
    const decoded = decodePriceEmbedded({ upc: barcode });
    const sku = isSoldByWeight ? decoded.sku || "" : "";

    // const {name: storeName} = store;

    const dontSearchWeHaveBarCode = () => {
      Debounced.stop("orderProductSearchStart");
    };

    const addMessage = (message: any) => {
      if (message && message.lenght) {
        dispatchFailure(message)(dispatch);
      } else {
        if (message && message.type) {
          dispatchTyped(message.type, message.content)(dispatch);
        } else {
          dispatchFailure(message)(dispatch);
        }
      }
      inputRef.current!.focus({
        cursor: "all",
      });
    };

    // FINISH - stop search
    const onError = (content: any) => {
      addMessage(`Product not found by barcode.`);
      console.log(
        `Product not found by barcode. SKU = ${sku} ERROR = ${content}`
      );
      inputRef.current!.focus({
        cursor: "all",
      });
    };

    // FINISH - not found
    const onNotFound = (messages: any) => {
      console.log(
        `Product not found by barcode. BARCODE = ${barcode} SKU = ${sku}`
      );
      addMessage(messages);
    };

    // FINISH - product found
    const onAdd = (product: any, formatBarcode: string) => {
      if (
        (formatBarcode === "weighted" || isSoldByWeight) &&
        typeof onReadWeight === "function"
      ) {
        onReadWeight();
      }
      inputRef.current!.focus({
        cursor: "all",
      });
      if (product) {
        const { category } = product;
        const c = category.replace(/\s+/g, "-").toLowerCase();
        const urlProd = `/register/c/${c}/p`;
        const newElement = {
          ...product,
          quantity: product.quantity || 1,
          url: urlProd,
        };
        if (product.subcategories && product.subcategories.length) {
          newElement.hash = genHash();
          // newElement.url = `${url}/${genHash()}`;
        } else {
          const existItem =
            !(formatBarcode === "weighted" || isSoldByWeight) &&
            items.find((o: ICartItem) => o._id === product._id);
          if (existItem && existItem.hash) {
            newElement.hash = existItem.hash;
          } else {
            newElement.hash = genHash();
          }
        }
        const { hash, subcategories: categoryModifiers = [] } = newElement;
        if (categoryModifiers && categoryModifiers.length) {
          navigate(`/register/${hash}/m`);
        } else {
          if (!p) {
            navigate(`${url || ""}`);
          }
        }
        addOrderItem(newElement)(dispatch);
        setSiderCollapsed(false)(dispatch);
        setSiderMode("view")(dispatch);
      }
    };

    // STEP 3
    const ResultValidator = (
      products: IProduct[],
      query: string,
      onNext?: Function
    ) => {
      ProdByBarcodeValidator(products, barcode, query, store)
        .then((result: any) => {
          const [prod] = result;
          const { formatBarcode } = prod;
          onAdd(prod, formatBarcode);
        })
        // GO TO NEXT
        .catch((mes: any) => {
          if (typeof onNext === "function") {
            onNext(mes);
          } else {
            onNotFound(mes);
          }
        });
    };

    // STEP 5
    const onSearchDroppedFirstDigit = (validationMessages: any) => {
      if (droppedBarcode) {
        const { length = 0 } = droppedBarcode.toString();
        const count = length % 2 ? 7 : 6;
        const searchPart = droppedBarcode.slice(0, count);
        console.log(
          `Product SEARCH DROPPED QUERY (By Weightable Barcode) = ${searchPart}`
        );
        ProductAPI.search(`upc_starts_with=${searchPart}`)
          .then((products) => {
            const err = getErrorString(products);
            if (!err) {
              ProdByBarcodeValidator(
                products.result,
                droppedBarcode,
                searchPart,
                store
              )
                .then((result: any) => {
                  const [prod] = result;
                  const { formatBarcode } = prod;
                  onAdd(prod, formatBarcode);
                })
                .catch((mes: any) => {
                  onNotFound(mes);
                });
            } else onError(err);
          })
          .catch(onError);
      } else onNotFound(validationMessages);
    };

    // STEP 4
    const onSearchByWeightAbleBarcode = (validationMessages: any) => {
      if (isSoldByWeight) {
        const { length = 0 } = barcode.toString();
        const count = length % 2 ? 7 : 6;
        const searchPart = barcode.slice(0, count);
        console.log(
          `Product SEARCH QUERY (By Weightable Barcode) = ${searchPart}`
        );
        ProductAPI.search(`upc_starts_with=${searchPart}`)
          .then((products) => {
            const err = getErrorString(products);
            if (!err) {
              if (length === 12 && +barcode[0] === 2) {
                ResultValidator(
                  products.result,
                  searchPart,
                  onSearchDroppedFirstDigit
                );
              } else {
                ResultValidator(products.result, searchPart, onError);
              }
            } else onError(err);
          })
          .catch(onError);
      } else onNotFound(validationMessages);
    };

    // STEP 2
    const onSearchBySKU = () => {
      if (sku) {
        dontSearchWeHaveBarCode();
        console.log(`Product SEARCH QUERY (SKU) = ${sku}`);
        ProductAPI.search(`sku=${sku}`)
          .then((products) => {
            dontSearchWeHaveBarCode();
            const err = getErrorString(products);
            // GO TO STEP 3
            if (!err)
              ResultValidator(
                products.result,
                sku,
                onSearchByWeightAbleBarcode
              );
            else onError(err);
          })
          .catch(onError);
      } else {
        onError(
          `Missing params. Please check: SEARCH SKU = ${sku} BARCODE = ${barcode} STORE = ${storeName} `
        );
      }
    };

    // STEP 1
    if (barcode && storeName) {
      dontSearchWeHaveBarCode();
      console.log(`Product SEARCH QUERY (barcode) = ${barcode}`);
      ProductAPI.search(`upc=${barcode}`)
        .then((products) => {
          dontSearchWeHaveBarCode();
          const err = getErrorString(products);
          // GO TO STEP 2
          if (!err) {
            ResultValidator(
              products.result,
              barcode,
              isSoldByWeight ? onSearchByWeightAbleBarcode : onSearchBySKU
            );
          } else onError(err);
        })
        .catch(onError);
    } else {
      onError(
        `Missing params. Please check: SEARCH  BARCODE = ${barcode} SKU = ${sku} STORE = ${storeName} `
      );
    }
  };

  return (
    <Input
      key="search"
      style={{ width: "100%", marginBottom: "15px" }}
      ref={inputRef}
      id="searchInput"
      onPressEnter={onSearch}
      placeholder=" Scanner Barcode"
      prefix={<ScanOutlined />}
    />
  );
};
