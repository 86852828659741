import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { setOrderStore } from "../../store/order";
import type { IStore } from "../../store/types";
import BrowserStores from "../../components/Stores/BrowserStores";

export const Stores = (): JSX.Element => {
  const navigate = useNavigate();
  const { stores, loading } = useAppSelector((state) => ({
    stores: state.store.list,
    loading: state.store.loading,
  }));

  const onStore = (store: IStore) => {
    if (store && store != null) {
      setOrderStore(store)(useAppDispatch());
    }
    navigate("/register/stations");
  };

  return (
    <BrowserStores
      {...{
        loading,
        list: stores,
        onSelect: onStore,
      }}
    />
  );
};
