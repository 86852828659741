import { Menu, Dropdown } from "../../components/UI/Layout";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { isObject, isEmptyObject } from "../../util/variableTypes";

interface IProps {
  me: any;
  handleMenuClick: any;
  children?: JSX.Element | string;
}

const ProfileDropdownMenu = ({
  me,
  handleMenuClick,
  children,
}: IProps): JSX.Element => {
  if (!(isObject(me) && !isEmptyObject(me))) return null;
  const { firstName = "", lastName = "", role } = me || {};
  const dropdownMenu = (
    <Menu
      onClick={handleMenuClick}
      className="profile-dropdown-menu"
      style={{ minWidth: "200px" }}
    >
      <Menu.Item key="user-info" className="dropdown-item" disabled>
        <div
          className="user-full-name-info"
          style={{ color: "rgba(0, 0, 0, 0.65)" }}
        >
          {firstName} {lastName}
        </div>
        <div className="username-info">{role}</div>
      </Menu.Item>
      <Menu.Divider />
      {children}
      <Menu.Item key="logout" className="dropdown-item">
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={["click"]}
      getPopupContainer={() =>
        document.getElementsByClassName("profile-menu")[0] as HTMLElement
      }
    >
      <a className="ant-dropdown-link" href="#top">
        <UserOutlined />
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default ProfileDropdownMenu;
