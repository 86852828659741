import { Link } from "react-router-dom";
import Icon from "@ant-design/icons";

interface IProps {
  id?: string;
  icon?: string;
  className?: string;
  style?: object;
  to?: string;
  type?: "primary" | "danger" | "warning" | "default" | "success";
  label?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  size?: string;
  title?: string;
  onClick?: Function;
  actionType?: "button" | "submit" | "reset" | undefined;
}

export const Button = ({
  id = "",
  icon = "",
  className = "",
  style = {},
  to = "",
  type = "primary",
  label = "",
  children,
  onClick,
  disabled = false,
  size = "",
  title = "",
  actionType,
}: IProps): JSX.Element => {
  return !to ? (
    <button
      id={id}
      title={title}
      style={style}
      onClick={() => (disabled ? {} : onClick())}
      type={actionType}
      className={`ant-btn ant-btn-${type || "primary"} ant-btn-${size || ""}
                ${disabled ? "disabled" : ""} ${className}`}
    >
      <span>
        {icon ? <Icon type={`${icon}`} /> : ""}
        {icon && label ? <span>&nbsp;</span> : null}
        {label ? <span>{label}</span> : ""}
        {children ? <span>{children}</span> : ""}
      </span>
    </button>
  ) : (
    <Link
      className={`ant-btn ant-btn-${type || "primary"} ant-btn-${
        size || ""
      } ${className}`}
      to={to}
      style={style}
      id={id}
      type={actionType}
      title={title}
    >
      {icon ? <Icon type={`${icon}`} /> : ""}
      {icon && label ? <span>&nbsp;</span> : null}
      {label ? <span>{label}</span> : ""}
      {children ? <span>{children}</span> : ""}
    </Link>
  );
};
