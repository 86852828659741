import { useState, useEffect } from "react";
import { CartItem } from "../CartItem";
import { CartTopButtons } from "./CartTopButtons";
import { CartProductEdit } from "./CartProductEdit";
import type { ICartItem } from "../../../../store/types";
import { groupArrByKey } from "../../../../util/helpers";

interface IProps {
  item: ICartItem;
  scaleWeight: string;
  classes?: string;
  onBackMode: any;
  onRemoveItem: any;
  onChangeCartItem: any;
}

export const CartEdit = ({
  item,
  scaleWeight,
  onRemoveItem,
  onChangeCartItem,
  onBackMode,
}: IProps): JSX.Element => {
  const [editingItem, setEditingItem] = useState<ICartItem | null>(null);

  useEffect(() => {
    if (item) {
      setEditingItem(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scaleWeight) {
      if (editingItem && editingItem._id === item._id) {
        onChangeCartItem(item || null, "quantity", scaleWeight);
      } else {
        const modifiers =
          item.modifiers && item.modifiers.length ? item.modifiers : null;
        if (modifiers && editingItem) {
          const newModifiers = modifiers.map((el: ICartItem) => {
            if (el && el._id === editingItem._id) {
              return { ...el, quantity: scaleWeight };
            }
            return el;
          });
          onChangeCartItem(item, "modifiers", newModifiers);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleWeight]);

  const modifiers =
    item && item.modifiers && item.modifiers.length
      ? groupArrByKey(item.modifiers, "category")
      : {};

  const onChangModifier = (
    modifier: ICartItem | null,
    field: string,
    value: number
  ) => {
    if (modifier) {
      const modifiers =
        item.modifiers && item.modifiers.length ? item.modifiers : null;
      if (modifiers) {
        const newModifiers = modifiers.map((el: ICartItem) => {
          if (el && el._id === modifier._id) {
            return { ...el, [field]: value };
          }
          return el;
        });
        onChangeCartItem(item, "modifiers", newModifiers);
      }
    }
  };

  const onRemoveModifier = (modifier: ICartItem | null) => {
    if (modifier) {
      const modifiers =
        item.modifiers && item.modifiers.length ? item.modifiers : null;
      if (modifiers) {
        const newModifiers = modifiers.filter(
          (el: ICartItem) => el && el._id !== modifier._id
        );
        onChangeCartItem(item, "modifiers", newModifiers);
      }
    }
  };

  return (
    <div>
      <CartTopButtons {...{ onBackMode, onRemove: onRemoveItem }} />
      <div className="flexFlowCol" style={{ height: "calc(100% - 40px)" }}>
        <div
          style={{
            flex: 1,
            color: "#fff",
            overflow: "auto",
            height: "calc(100vh - 320px)",
          }}
        >
          {editingItem && item && editingItem._id === item._id ? (
            <CartProductEdit
              item={item}
              descriptionEditable={true}
              classes={"cart__title cart__title_selected uppercase bold"}
              onChange={(f: string, v: number) =>
                onChangeCartItem(item || null, f, v)
              }
            />
          ) : (
            <div className="cart m15">
              <CartItem
                {...{
                  classes: "cart__title cart__title_selectable uppercase bold",
                  item: { ...item, modifiers: [] },
                  onClickItem: () => setEditingItem(item),
                }}
              />
            </div>
          )}
          {Object.keys(modifiers) && Object.keys(modifiers).length
            ? Object.keys(modifiers).map((key) => (
                <div key={key}>
                  <div className="flexFlowRow flexAICenter m15 block__header">
                    <span>{key}</span>
                  </div>
                  {modifiers[key].map((modifier: ICartItem, i: number) => {
                    return (
                      <div key={i}>
                        {editingItem && editingItem._id === modifier._id ? (
                          <CartProductEdit
                            item={modifier}
                            classes="cart__title cart__title_selected"
                            onBackMode={() => setEditingItem(null)}
                            onRemove={() => onRemoveModifier(modifier)}
                            onChange={(f: string, v: number) =>
                              onChangModifier(modifier || null, f, v)
                            }
                          />
                        ) : (
                          <div className="cart m15">
                            <CartItem
                              {...{
                                classes:
                                  "cart__title_selectable cart__title_sm",
                                item: modifier,
                                onClickItem: () => setEditingItem(modifier),
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
