import { useAppSelector, useAppDispatch } from "../../hooks";
import { useParams, useLocation } from "react-router-dom";
import { GroupOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { catByUrlSelector, catSelector } from "../../store/selector/category";
import { ScannerInput } from "../../components/Order/ScannerInput";
import type { ICartItem, ICategory } from "../../store/types";
import { itemsSelector } from "../../store/selector/cart";
import type { IMode } from "../../util/Navigation";
import { ProductsByCategory } from "./ProductsByCategory";
import { setProductView } from "../../store/product";
import { OrderCustomer } from "../../components/Order/OrderCustomer";
import { Button } from "../../components/UI/Controls/Button";

export const CategoryList = ({ mode }: { mode: IMode }): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams<{ c: string; sc: string; p: string }>();
  const { c = "", p = "", sc = "" } = params;

  const { category, items, product, categoryList, view, auth } = useAppSelector(
    (state) => {
      return {
        auth: state.auth.me,
        items: itemsSelector(state),
        view: state.product.view,
        categoryList: catSelector(state),
        category: catByUrlSelector(state)[c],
        product: itemsSelector(state).find((o: ICartItem) => o.hash === p),
        loading: state.product.loading || state.category.loading,
      };
    }
  );
  const { categories = [], modifiers = [] } = categoryList || {};
  const { subcategories: oldSubcategories = [] } = category || {};
  const allCategories = [...categories, ...modifiers];
  const subcategories = allCategories.filter((o: any) =>
    oldSubcategories.some((el: any) => o.title === el.title)
  );
  const { subcategories: productModifiers = [] } = product || {};
  const productModifiersLabels = productModifiers.map((o: any) => o.label);
  const productCategoryModifiers =
    [...categories, ...modifiers].filter((item) =>
      productModifiersLabels.includes(item.title)
    ) || [];

  const list =
    mode === "subcategory"
      ? subcategories
      : mode === "categoryModifier"
      ? productCategoryModifiers
      : categories;
  const hasItems = items && items.length;

  return (
    <div className={`${hasItems ? "has-right-sider-collapsed" : ""}`}>
      <div
        className="order-top"
        style={{ padding: "16px 24px", background: "#f0f2f5" }}
      >
        {auth && auth.role && auth.role !== "customer" ? (
          <div style={{ flex: 1 }}>
            <OrderCustomer />
          </div>
        ) : null}
        <div style={{ flex: 1 }}>
          <div className="flexFlowRow" style={{ alignItems: "baseline" }}>
            <span style={{ color: "#5a5a5a" }}>&nbsp;View:&nbsp;</span>
            <Button
              onClick={(_) => setProductView("group")(dispatch)}
              className="ml5"
              title="Group"
              type={view === "group" ? "primary" : "warning"}
            >
              <GroupOutlined style={{ verticalAlign: "2px" }} />
            </Button>
            <Button
              onClick={(_) => setProductView("list")(dispatch)}
              type={view === "list" ? "primary" : "warning"}
              className="ml5 mr5"
              title="List"
            >
              <UnorderedListOutlined style={{ verticalAlign: "2px" }} />
            </Button>
            <ScannerInput />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "16px 34px",
          background: "#f0f2f5",
          marginTop: "140px",
        }}
        className="cards"
      >
        {mode !== "product" ? (
          list
            .filter((category: ICategory) => {
              if (sc && mode != "categoryModifier" && sc != category.title)
                return false;
              return true;
            })
            .map((category: ICategory) => (
              <div
                id={category.title}
                key={category.title}
                style={{ paddingBottom: "15px" }}
              >
                <p style={{ color: "#5a5a5a", textTransform: "uppercase" }}>
                  <b>{category.title}</b>
                </p>
                {category.subcategories && category.subcategories.length ? (
                  category.subcategories.map((category: ICategory) => (
                    <div key={category.title} style={{ paddingBottom: "15px" }}>
                      <p style={{ color: "#5a5a5a" }}>
                        <b>{category.title}</b>
                      </p>
                      <ProductsByCategory mode={mode} category={category} />
                    </div>
                  ))
                ) : (
                  <ProductsByCategory mode={mode} category={category} />
                )}
              </div>
            ))
        ) : category ? (
          <div key={category.title}>
            <p style={{ color: "#5a5a5a" }}>
              <b>{category.title}</b>
            </p>
            <ProductsByCategory mode={mode} category={category} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
