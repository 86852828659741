import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { ICategory } from "./types";
import { CategoryAPI, ProductAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type CategoryState = {
  list: ICategory[];
  selected: ICategory | null;
  loading: boolean;
  error: string;
};

const initialState: CategoryState = {
  list: [],
  selected: null,
  loading: false,
  error: "",
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    start: (state): CategoryState => ({
      ...state,
      list: [],
      loading: true,
      error: "",
    }),
    fail: (state, action: PayloadAction<string>): CategoryState => ({
      ...state,
      list: [],
      loading: false,
      error: action.payload,
    }),
    success: (state, action: PayloadAction<ICategory[]>): CategoryState => ({
      ...state,
      list: action.payload,
      loading: false,
    }),
    select: (state, action: PayloadAction<ICategory>): CategoryState => ({
      ...state,
      selected: action.payload,
    }),
    hide: (state, action: PayloadAction<[string, boolean]>): CategoryState => {
      const id = action.payload[0];
      const value = action.payload[1];
      const list = state.list.map((o: ICategory) =>
        o.title.toLowerCase() === id ? { ...o, hide: value } : o
      );
      return { ...state, list };
    },
  },
});
const { fail, success, select, hide, start } = categorySlice.actions;
export default categorySlice.reducer;

export const fetchCategories = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    CategoryAPI.fetchAll()
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          if (response.list) {
            ProductAPI.fetchByCategoryStat()
              .then(({ categories }) => {
                const result = response.list.map((o: ICategory) => {
                  o.productsLength = categories[o.title] || 0;
                  return o;
                });
                dispatch(success(result));
              })
              .catch((error) => {
                const err = getErrorString(error);
                dispatch(fail(err));
              });
          }
        } else {
          dispatch(fail(err));
        }
      })
      .catch((error) => {
        dispatch(fail(getErrorString(error)));
      });
  };
};

export const setCategories = (categories: ICategory[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(success(categories));
  };
};

export const setCategory = (category: ICategory | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(select(category));
  };
};
