import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreditCardOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { balanceSelector } from "../../store/selector/cart";
import { Col, Row, Result } from "../../components/UI/Layout";
import { Statistic, Card, Modal } from "../../components/UI/Layout";
import { onStartPayment } from "../../store/payment";
import { onStartAutosubmitPayment } from "../../store/order";
import { checkFormCardSelector } from "../../store/selector/payment";
import { Button } from "../../components/UI/Controls/Button";
import { PoslinkPayment } from "../Payment/PoslinkPayment";
import { DejavooPayment } from "../Payment/DejavooPayment";
import { FormCard } from "../../components/Order/FormCard";
import { addOrderPayment } from "../../store/order";

export const PaymentCard = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state); // NEEDS CHECKING
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const {
    order,
    balance,
    payment = { amount: 0 },
    loading,
    result,
    station,
    isValidFormCard = false,
  } = useAppSelector((state) => ({
    order: state.order,
    payment: state.payment.object,
    isValidFormCard: checkFormCardSelector(state),
    result: state.payment.result,
    station: state.store.station,
    loading: state.payment.loading,
    balance: balanceSelector(state),
  }));
  const { amount } = payment || { amount: 0 };
  const onBack = () => {
    navigate("/register/checkout");
  };

  const onManualType = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (order && order._id) {
      navigate("/register/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (result && result.amount) {
      addOrderPayment(result)(dispatch);
      navigate("/register/checkout");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const handleOk = () => {
    setIsModalVisible(false);
    const { amount = 0 } = payment;
    if (+amount === +balance) {
      onStartAutosubmitPayment(state)(dispatch);
    } else {
      onStartPayment(state)(dispatch);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { stationPaymentEngine: device = "" } = station || {};

  return (
    <div
      style={
        window.innerWidth > 600
          ? { margin: "16px 80px" }
          : { margin: "5px 5px" }
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Statistic
              valueStyle={{ color: "#3f8600" }}
              title="Payment Amount"
              style={{ flex: 1 }}
              value={amount}
              precision={2}
              prefix="$"
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} offset={6}>
          <Button
            size="lg"
            disabled={loading || !amount}
            className="mt15 mr15"
            onClick={onManualType}
            style={{ width: "100%" }}
          >
            <CreditCardOutlined style={{ verticalAlign: "2px" }} />
            <span>&nbsp;Pay with card</span>
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} offset={6}>
          {device === "poslink" ? <PoslinkPayment /> : null}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} offset={6}>
          {device === "dejavoo" ? <DejavooPayment /> : null}
        </Col>
      </Row>
      {result ? (
        <Result status="success" title="Successfully Purchased!" />
      ) : null}
      <Modal
        title="Pay with card"
        open={isModalVisible}
        onOk={handleOk}
        okButtonProps={{ disabled: !isValidFormCard }}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={!isValidFormCard}
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            <CreditCardOutlined />
            <span>&nbsp;Submit</span>
          </Button>,
        ]}
      >
        <FormCard />
      </Modal>
      {!loading ? (
        <Button
          size="lg"
          style={{ bottom: 0, position: "absolute", marginBottom: "30px" }}
          onClick={onBack}
        >
          <ArrowLeftOutlined />
          &nbsp;Return to Checkout
        </Button>
      ) : null}
    </div>
  );
};
