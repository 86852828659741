import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import { INotification } from "./types";

type NotificationState = {
  list: INotification[];
};

const initialState: NotificationState = { list: [] };

const notifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<INotification>) => {
      const key = action.payload.key || +new Date() + "";
      const type = action.payload.type;
      const message = action.payload.message;
      const placement = action.payload.placement || "topRight";
      return {
        ...state,
        list: [...state.list, { key, type, message, placement }],
      };
    },
    rm: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        list: state.list.filter((o: INotification) => o.key !== action.payload),
      };
    },
    clear: (state) => {
      return { ...state, list: [] };
    },
  },
});

const { set, rm, clear } = notifySlice.actions;
export default notifySlice.reducer;

export const dispatchFailure = (error: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(set({ type: "error", message: error }));
  };
};

export const dispatchTyped = (
  type: "error" | "info" | "warn",
  error: string
) => {
  return (dispatch: AppDispatch) => {
    dispatch(set({ type, message: error }));
  };
};

export const deleteNotification = (key: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(rm(key));
  };
};
