import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks";
import { WalletOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import type { ICartItem, IOrder } from "../../../../store/types";
import { Button } from "../../../UI/Controls/Button";
import { CartTopButton } from "./CartTopButtons";
import { CartTotals } from "../CartTotals";
import { CartItems } from "../CartItems";

interface IProps {
  order: IOrder;
  item: ICartItem;
  onSave: any;
  onReset: any;
  onCheckout: any;
  onSetCartItem: any;
}

export const CartView = ({
  order,
  item,
  onSave,
  onReset,
  onSetCartItem,
  onCheckout,
}: IProps): JSX.Element => {
  const navigate = useNavigate();
  const hasItems = order.items && order.items.length;
  const [shake, setShake] = useState("");
  const { auth } = useAppSelector((state) => {
    return {
      auth: state.auth.me,
    };
  });

  useEffect(() => {
    if (order && order._id) {
      navigate("/register/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order._id]);

  useEffect(() => {
    setShake("shake-bottom");
    let shakeTimeout = setTimeout(() => setShake(""), 500);
    return () => {
      clearTimeout(shakeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.items]);

  return (
    <>
      {hasItems ? (
        <div id="cart-div" className={shake}>
          <ShoppingCartOutlined id="cart-icon" />
          <div style={{ color: "white" }} id="number-item">
            {order.items.length}
          </div>
        </div>
      ) : null}
      {hasItems ? <CartTopButton {...{ onSave, onReset }} /> : null}
      {hasItems ? (
        <div className="flexFlowCol" style={{ height: "calc(100% - 220px)" }}>
          <div style={{ flex: 1, color: "#fff", overflow: "auto" }}>
            <CartItems
              currentItem={item}
              items={order.items}
              onClickItem={(item: ICartItem) => onSetCartItem(item)}
            />
          </div>
          <div>
            <div
              className="m-5 mx-0 p-5"
              style={{ color: "#fff", borderTop: "1px solid #fff" }}
            >
              <CartTotals totals={order.totals} />
            </div>
            <Button
              disabled={auth && auth.role && auth.role === "customer"}
              size="lg"
              style={{ width: "100%" }}
              onClick={onCheckout}
            >
              <WalletOutlined
                style={{ verticalAlign: "0px", fontSize: "22px" }}
              />
              <span>&nbsp;Checkout</span>
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};
