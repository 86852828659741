import { isEmptyString, isString } from "../../util/variableTypes";

interface IProps {
  stationPaymentEngine: any;
  serialNumber: any;
  deviceKey: any;
  deviceID: any;
  registerId: any;
}

export const PaymentDevice = ({
  stationPaymentEngine,
  serialNumber,
  deviceKey,
  deviceID,
  registerId,
}: IProps): JSX.Element => {
  const device = [deviceKey, deviceID, registerId].find(
    (v) => isString(v) && !isEmptyString(v)
  );
  if (!(isString(device) && !isEmptyString(device))) return null;
  const length = device.length;
  if (length > 3) {
    const l = Math.min(~~(length / 3), 4);
    const n = Math.min(length - l * 2, 4);
    const prefix = device.substring(0, l);
    const suffix = device.substring(length - l);
    const maskedCharacters = "*".repeat(n);
    if (stationPaymentEngine && stationPaymentEngine === "poslink") {
      return (
        <div>
          {serialNumber ? (
            <span>Serial Number:&nbsp;</span>
          ) : (
            <span>Device Key:&nbsp;</span>
          )}
          <b>{serialNumber || deviceID}</b>
        </div>
      );
    }
    if (stationPaymentEngine === "dejavoo" && registerId) {
      return (
        <div>
          <span>Register ID:&nbsp;</span>
          <b>{registerId}</b>
        </div>
      );
    }
    return (
      <div>
        <span>Device Key:&nbsp;</span>
        <b>{`${prefix}${maskedCharacters}${suffix}`}</b>
      </div>
    );
  }
  return (
    <div>
      <span>Device Key: </span>
      <b>{"*".repeat(length)}</b>
    </div>
  );
};
