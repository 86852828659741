import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { Row, Col, Divider } from "../../components/UI/Layout";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SmileOutlined, GiftOutlined } from "@ant-design/icons";
import { PayrollDeductModal } from "../../components/Order/Payment/Modals/PayrollDeductModal";
import { GiftCardModal } from "../../components/Order/Payment/Modals/GiftCardModal";
import { genHash } from "../../store/selector/cart";
import { TipsModal } from "../../components/Order/Payment/Modals/TipsModal";
import { addOrderPayment, completeOrder } from "../../store/order";
import { PriceInput } from "../../components/UI/Controls/PriceInput";
import { OrderCustomer } from "../../components/Order/OrderCustomer";
import { PaidList } from "../../components/Order/Payment/PaidList";
import { GiftCard } from "../../components/Order/Payment/GiftCard";
import { Balance } from "../../components/Order/Payment/Balance";
import { Button } from "../../components/UI/Controls/Button";
import { setPayment } from "../../store/payment";
import { setOrderTips } from "../../store/order";
import type { IPayment } from "../../store/types";
import { fixedTo2 } from "../../util/helpers";
import { balanceSelector, totalSelector } from "../../store/selector/cart";
import { paymentTypesSelector } from "../../store/selector/store";

export const Checkout = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);

  const [amount, setAmount] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<string>("");
  const [disabledComplete, setDisabledComplete] = useState<boolean>(false);

  const { order, paymentTypes, balance } = useAppSelector((state) => ({
    order: { ...state.order, totals: totalSelector(state) },
    paymentTypes: paymentTypesSelector(state),
    balance: balanceSelector(state),
  }));
  const {
    payment = [],
    customer = { employeeNum: "" },
    totals: { grandTotal = 0 } = {},
  } = order;
  const { employeeNum = "" } = customer;
  const usedGiftCard = payment.find((o: IPayment) => o.type === "giftCard");
  useEffect(() => {
    if (order && order._id) {
      navigate("/register/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (!balance) {
      onSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  useEffect(() => {
    if (order && order.giftCard) {
      const gBalance = order.giftCard.balance;
      const max = Math.min(amount, gBalance);
      setAmount(0);
      addSimplePayment("giftCard", max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.giftCard]);

  const onSelect = (key: string) => {
    if (key === "creditCard") {
      setAmount(0);
      setPayment({ amount })(dispatch);
      navigate("/register/payment");
    } else if (key === "employeePayrollDeduct") {
      if (employeeNum) {
        handleOk("employeePayrollDeduct");
      } else {
        setModalVisible("payroll");
      }
    } else if (key === "tips") {
      setModalVisible("tips");
    // } else if (key === "giftCard") {
    //   const gBalance = order.giftCard.balance;
    //   const max = Math.min(amount, gBalance);
    //   console.log({ key, max });
    //
    //   setAmount(0);
    //   addSimplePayment(key, max);
    } else {
      setAmount(0);
      addSimplePayment(key, amount);
    }
  };

  const onBack = () => {
    navigate("/register");
  };

  const addSimplePayment = (key: string, amount: number) => {
    const newElement = {
      type: key,
      hash: "",
      amount,
    };
    const existItem = payment.find((o: IPayment) => o.type === key);

    if (existItem && existItem.hash) {
      if (
        key === "employeePayrollDeduct" &&
        employeeNum !== existItem.employeeNum
      ) {
        newElement.hash = genHash();
      } else {
        newElement.hash = existItem.hash;
      }
    } else {
      newElement.hash = genHash();
    }
    if (key === "employeePayrollDeduct") {
      addOrderPayment({ ...newElement, employeeNum })(dispatch);
    } else if (key === "giftCard") {
      addOrderPayment({
        ...newElement,
        giftCardCode: order.giftCard.code,
        balance: +fixedTo2(+order.giftCard.balance - +amount),
      })(dispatch);
    } else {
      addOrderPayment(newElement)(dispatch);
    }

    if (amount > balance) {
      const change = {
        type: "change",
        hash: "",
        amount: +fixedTo2(amount - balance),
      };
      const existChange = payment.find((o: IPayment) => o.type === "change");
      if (existChange && existChange.hash) {
        change.hash = existChange.hash;
      } else {
        change.hash = genHash();
      }
      addOrderPayment(change)(dispatch);
    }
  };

  const onSave = () => {
    setDisabledComplete(true);
    completeOrder(state)(dispatch);
  };

  const handleOk = (key: string) => {
    setModalVisible("");
    addSimplePayment(key, amount);
  };

  const handleNoTips = () => {
    setOrderTips(0)(dispatch);
    setModalVisible("");
  };

  const handleCancel = () => {
    setModalVisible("");
  };

  return (
    <div
      style={
        window.innerWidth > 600
          ? { margin: "16px 80px" }
          : { margin: "5px 5px" }
      }
    >
      <Balance totalDue={grandTotal} balance={balance} />
      <Divider orientation="left">Payments</Divider>
      <Row
        gutter={[16, 16]}
        style={
          window.innerWidth < 600 ? { flexDirection: "column-reverse" } : {}
        }
      >
        <Col span={12} style={{ maxWidth: "100%" }}>
          <div className="flexFlowRow">
            <PriceInput
              value={amount}
              className="form-control pl15 pr15"
              onChange={(value: number) => setAmount(value)}
            />
            <Button
              size="lg"
              style={{ width: "100%" }}
              onClick={() => setAmount(balance)}
              className="ml15"
              disabled={balance <= 0}
            >
              <span>Exact</span>
            </Button>
          </div>
          {paymentTypes.map((object: any, i: number) => {
            const { id: key, name: label, icon } = object;
            return (
              <div key={i} style={{ display: "flex" }}>
                {key !== "giftCard" ? (
                  <Button
                    className="mt15"
                    size="lg"
                    style={{ width: "100%" }}
                    onClick={() => onSelect(key)}
                    disabled={balance <= 0 || amount <= 0}
                  >
                    {icon}&nbsp;
                    <span>Pay by {label}</span>
                  </Button>
                ) : !usedGiftCard && order.giftCard ? (
                  <>
                    <Button
                      size="lg"
                      className="mt15"
                      style={{ width: "100%" }}
                      onClick={() => onSelect(key)}
                      disabled={balance <= 0 || amount <= 0}
                    >
                      {icon}&nbsp;
                      <span>Pay by {label}</span>
                    </Button>
                    {!usedGiftCard && order.giftCard ? (
                      <GiftCard
                        {...{
                          code: order.giftCard.code,
                          balance: order.giftCard.balance,
                        }}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            );
          })}
          <div style={{ display: "flex" }}>
            <Button
              className="mt15"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => onSelect("tips")}
              disabled={grandTotal <= 0}
            >
              <SmileOutlined style={{ verticalAlign: "2px" }} />
              &nbsp;
              <span>TIPS</span>
            </Button>
          </div>

          {!order.giftCard ? (
            <div style={{ display: "flex" }}>
              <Button
                size="lg"
                type="danger"
                className="mt15"
                style={{ width: "100%" }}
                onClick={() => setModalVisible("giftCard")}
              >
                <GiftOutlined style={{ verticalAlign: "2px" }} />
                &nbsp;
                <span>USE GIFT CARD</span>
              </Button>
            </div>
          ) : null}
        </Col>
        <Col span={window.innerWidth > 600 ? 12 : 24}>
          <OrderCustomer />
          {payment && payment.length ? <PaidList /> : null}
          {balance === 0 ? (
            <div
              style={
                window.innerWidth < 600
                  ? { width: "100%", display: "flex" }
                  : {}
              }
            >
              <Button
                className=""
                disabled={disabledComplete}
                onClick={onSave}
                style={
                  window.innerWidth < 600
                    ? {
                        width: "200px",
                        height: "auto",
                        margin: "10px auto 15px auto",
                      }
                    : { width: "100%", height: "auto" }
                }
              >
                <div className="my-2">
                  <SaveOutlined style={{ verticalAlign: "2px" }} />
                  &nbsp;COMPLETE
                </div>
              </Button>
            </div>
          ) : null}
        </Col>
      </Row>
      <PayrollDeductModal
        {...{
          visible: modalVisible === "payroll",
          handleOk: () => handleOk("employeePayrollDeduct"),
          handleCancel,
          disabled: !employeeNum,
        }}
      />
      {modalVisible === "giftCard" ? (
        <GiftCardModal
          {...{
            visible: modalVisible === "giftCard",
            handleCancel,
            disabled: false,
          }}
        />
      ) : null}
      <TipsModal
        {...{
          visible: modalVisible === "tips",
          handleNoTips,
          handleOk: () => handleOk("tips"),
          handleCancel,
        }}
      />
      <Button
        size="lg"
        style={{ bottom: 0, position: "absolute", marginBottom: "30px" }}
        onClick={onBack}
      >
        <ArrowLeftOutlined />
        &nbsp;Return to Order
      </Button>
    </div>
  );
};
