type IDebounced = {
  queue: {
    [key: string]: any;
  };
  saveInterval: number;
  start: Function;
  stop: Function;
};

export const Debounced: IDebounced = {
  queue: {},
  saveInterval: 500, // 10 seconds

  start: (id: string, callback: any, ms: any) => {
    if (typeof callback !== "function") {
      throw new Error(
        "Debounced.start: invalid arguments, at least callback expected"
      );
    }
    const msValue = ms || 500;
    const timeoutId = Debounced.queue[id];
    clearTimeout(timeoutId);
    Debounced.queue[id] = setTimeout(() => {
      callback();
      delete Debounced.queue[id];
    }, msValue);
  },
  stop: (id: string) => {
    const timeoutId = Debounced.queue[id];
    if (timeoutId) {
      clearTimeout(timeoutId);
      delete Debounced.queue[id];
    }
  },
};

export default Debounced;
