import { VarType } from "./variableTypes";
import { isDigit } from "./helpers";

const { isString, isEmptyString, isValue } = VarType;

const barcode12DiscardedMiddleDigitMask =
  /^([0-9]{1})([0-9]{5})([0-9]{1})([0-9]{2})([0-9]{2})([0-9]{1})$/;
const barcode13DiscardedMiddleDigitMask =
  /^([0-9]{1})([0-9]{1})([0-9]{5})([0-9]{1})([0-9]{2})([0-9]{2})([0-9]{1})$/;
const barcode13IncludedMiddleDigitMask =
  /^([0-9]{1})([0-9]{1})([0-9]{5})([0-9]{3})([0-9]{2})([0-9]{1})$/;
const getCheckDigit = (val: string) => {
  const s = val.toString();
  let calcSum = 0;

  s.split("").forEach((number: any, index: number) => {
    number = parseInt(number, 10);
    if ((s.length + 1) % 2 === 0) {
      index += 1;
    }
    if (index % 2 === 0) {
      calcSum += number;
    } else {
      calcSum += number * 3;
    }
  });

  calcSum %= 10;
  return calcSum === 0 ? 0 : 10 - calcSum;
};

export const isCustomSKUBarcode = (value: string, decode = false) => {
  if (!value || !value.toString().includes("-")) return false;
  try {
    const [sku, price] = value.toString().split("-");
    if (!isDigit(price)) return false;
    return !decode ? { sku: value } : { sku, price };
  } catch (e) {
    console.log("Barcode is not recognized =", value, JSON.stringify(e));
    return false;
  }
};

export const isEAN13Mask = (mask: any, upc: string, decode = false) => {
  try {
    const upcString = upc.toString();
    const [, first, second, sku, middle, currency, cent, checkDigit] =
      mask.exec(upcString);
    const prefix = first + second;
    const expectedCheckDigit = getCheckDigit(
      prefix + sku + middle + currency + cent
    );
    if (
      isString(prefix) &&
      !isEmptyString(prefix) &&
      isString(sku) &&
      !isEmptyString(sku) &&
      isString(currency) &&
      !isEmptyString(currency) &&
      isString(cent) &&
      !isEmptyString(cent) &&
      isString(checkDigit) &&
      !isEmptyString(checkDigit) &&
      +expectedCheckDigit === +checkDigit
    ) {
      return !decode ? upcString : { prefix, sku, currency, cent, checkDigit };
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const isEAN13PriceEmbeddedMask = (
  mask: any,
  upc: string,
  decode = false
) => {
  try {
    const upcString = upc.toString();
    const [, first, second, sku, currency, cent, checkDigit] =
      mask.exec(upcString);
    const prefix = first + second;
    const expectedCheckDigit = getCheckDigit(prefix + sku + currency + cent);

    if (
      isString(prefix) &&
      !isEmptyString(prefix) &&
      isString(sku) &&
      !isEmptyString(sku) &&
      isString(currency) &&
      !isEmptyString(currency) &&
      isString(cent) &&
      !isEmptyString(cent) &&
      isString(checkDigit) &&
      !isEmptyString(checkDigit) &&
      +expectedCheckDigit === +checkDigit
    ) {
      return !decode ? upcString : { prefix, sku, currency, cent, checkDigit };
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const isUPC12digitsMask = (mask: any, upc: any, decoded = false) => {
  try {
    const upcString = upc.toString();
    const [, prefix, sku, middle, currency, cent, checkDigit] =
      mask.exec(upcString);
    const expectedCheckDigit = getCheckDigit(
      prefix + sku + middle + currency + cent
    );
    if (
      isString(prefix) &&
      !isEmptyString(prefix) &&
      isString(sku) &&
      !isEmptyString(sku) &&
      isString(currency) &&
      !isEmptyString(currency) &&
      isString(cent) &&
      !isEmptyString(cent) &&
      isString(checkDigit) &&
      !isEmptyString(checkDigit) &&
      +expectedCheckDigit === +checkDigit
    ) {
      return !decoded
        ? upcString
        : { prefix, sku, middle, currency, cent, checkDigit };
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const isNotPriceEmbeddedUPCA = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const priceEmbeddedUPC = isPriceEmbeddedUPCA({ upc: upcString });
    if (priceEmbeddedUPC) {
      return false;
    }
    const { length } = upcString;
    if (length === 12) {
      return isUPC12digitsMask(barcode12DiscardedMiddleDigitMask, upc);
    }
  }
  return false;
};

export const isNotPriceEmbeddedEAN13 = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const priceEmbedded = isPriceEmbeddedEAN13({ upc: upcString });
    if (priceEmbedded) {
      return false;
    }
    const { length } = upcString;
    if (length === 13) {
      return isEAN13Mask(barcode13DiscardedMiddleDigitMask, upc);
    }
  }
  return false;
};

export const isPriceEmbeddedUPCA = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const { length } = upcString;
    if (length !== 12) return false;
    if (+upcString[0] === 2) {
      return isUPC12digitsMask(barcode12DiscardedMiddleDigitMask, upc);
    }
  }
  return false;
};

export const isPriceEmbeddedEAN13 = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const { length } = upcString;
    if (length !== 13) return false;
    if (+upcString[0] === +2) {
      return isEAN13Mask(barcode13DiscardedMiddleDigitMask, upc);
    } else if (+upcString.slice(0, 2) === +"02") {
      return isEAN13PriceEmbeddedMask(barcode13IncludedMiddleDigitMask, upc);
    }
  }
  return false;
};

export const isPriceEmbedded = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const { length } = upcString;
    if ([12, 13].includes(length)) {
      if ([12].includes(length)) {
        return isPriceEmbeddedUPCA({ upc: upcString });
      } else if (length === 13) {
        return isPriceEmbeddedEAN13({ upc: upcString });
      }
    }
  }
  return false;
};

export const isNotPriceEmbedded = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const { length } = upcString;
    if ([12, 13].includes(length)) {
      if ([12].includes(length)) {
        return isNotPriceEmbeddedUPCA(upcString);
      } else if (length === 13) {
        return isNotPriceEmbeddedEAN13({ upc: upcString });
      }
    }
  }
  return false;
};

export const decodeBarcodeByUpc = ({ upc }: { upc: any }) => {
  const priceEmbedded = isPriceEmbedded({ upc });
  const notPriceEmbedded = isNotPriceEmbedded({ upc });

  if (priceEmbedded) {
    return decodePriceEmbedded({ upc: priceEmbedded });
  }
  if (notPriceEmbedded) {
    return decodeNotPriceEmbedded({ upc: notPriceEmbedded });
  }
  return false;
};

export const decodeNotPriceEmbedded = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    if (isNotPriceEmbeddedUPCA(upcString)) {
      return isUPC12digitsMask(barcode12DiscardedMiddleDigitMask, upc, true);
    }
    if (isNotPriceEmbeddedEAN13({ upc })) {
      return isEAN13Mask(barcode13DiscardedMiddleDigitMask, upc, true);
    }
  }
  return false;
};

export const decodePriceEmbedded = ({ upc }: { upc: any }) => {
  if (isValue(upc) && isDigit(upc)) {
    const upcString = upc.toString();
    const { length } = upcString;
    if ([12, 13].includes(length)) {
      if (length === 12) {
        if (+upcString[0] === +2) {
          return isUPC12digitsMask(
            barcode12DiscardedMiddleDigitMask,
            upc,
            true
          );
        }
        if (+upcString.slice(0, 2) === +"02") {
          return isUPC12digitsMask(
            barcode12DiscardedMiddleDigitMask,
            upc,
            true
          );
        }
        return false;
      } else if (length === 13) {
        if (+upcString[0] === +2) {
          return isEAN13Mask(barcode13DiscardedMiddleDigitMask, upc, true);
        } else if (+upcString.slice(0, 2) === +"02") {
          return isEAN13PriceEmbeddedMask(
            barcode13IncludedMiddleDigitMask,
            upc,
            true
          );
        }
      }
    }
  }
  return false;
};

// const priceToString = value => {
//     if (!value) return '0.00'
//     const arr = value.toString().split('.')
//     if (arr.length >= 2) {
//         const part1 = arr[0]
//         const part2 = arr[1]
//         return `${part1}.${part2}`
//     } else {
//         const part = arr[0]
//         return `${part}.00`
//     }
// }
//
// export const genUpcPriceEmbeddedByType = ({ upc, type, sku, price = 0.00 }) => {
//     if (sku && isValue(sku) && isDigit(sku) && isDigit(price)) {
//         const upcString = upc ? upc.toString() : ''
//         const skuString = sku.toString()
//         const priceString = priceToString(price)
//         const { length } = skuString;
//         if (length === 5) {
//             const [, currency, cent] = priceMaskRegex.exec(priceString);
//             if (type === 'ean-13') {
//                 const tempPrefix = upcString ? upcString.slice(0,2) : '02';
//                 const prefix = ['02', '22'].includes(tempPrefix) ? tempPrefix : '02'
//                 const checkSKU = getCheckDigit(sku);
//                 const pad3Currency = +currency < 100 ? 0 + pad2(currency) : pad2(currency)
//                 const newCurrency = +prefix === +'02' ? pad3Currency : checkSKU + pad2(currency)
//                 const body = prefix + sku + newCurrency + pad2(cent);
//                 const checkDigit = getCheckDigit(body);
//                 const newUpc = body + checkDigit;
//                 return newUpc;
//             } else if (type === 'upc-a') {
//                 if (upcString && upcString.length === 12) {
//                     const priceEmbedded = isPriceEmbeddedUPCA({ upc })
//                     const { prefix = '2', sku: prevSku = sku } = priceEmbedded ? decodeBarcodeByUpc({ upc }) : {}
//                     const checkSKU = prevSku ? getCheckDigit(prevSku) : 0;
//                     const body = prefix + prevSku + checkSKU + pad2(currency) + pad2(cent);
//                     const checkDigit = getCheckDigit(body);
//                     const newUpc = body + checkDigit;
//                     return newUpc;
//                 } else {
//                     const prefix = '2';
//                     const checkSKU = getCheckDigit(sku);
//                     const body = prefix + sku + checkSKU + pad2(currency) + pad2(cent);
//                     const checkDigit = getCheckDigit(body);
//                     const newUpc = body + checkDigit;
//                     return newUpc;
//                 }
//             }
//         }
//     }
//     return false
// };
//
// export const isExistedUpcType = (type) => {
//     return isString(type) && !isEmptyString(type) ? barcodeTypes.includes(type) : false;
// };
//
// export const getTypeByUPC = ({ upc }) => {
//     if (!isDigit(upc)) return 'sku';
//     if (isPriceEmbeddedUPCA(upc) || isNotPriceEmbeddedUPCA(upc)) return 'upc-a';
//     if (isPriceEmbeddedEAN13({ upc }) || isNotPriceEmbeddedEAN13({ upc })) return 'ean-13';
//     return '';
// };
//
// // BARCODE HELPERS //

export const isRecognizeBarcode = (barcode: string) => {
  if (isCustomSKUBarcode(barcode)) return true;
  return decodeBarcodeByUpc({ upc: barcode });
};

// export const getPrintedBarcode = (object) => {
//     const { upc, sku, formatBarcode, price } = object;
//     const isValidType = isExistedUpcType(formatBarcode);
//     const newType = getTypeByUPC({ upc });
//     const type = isValidType ? formatBarcode : newType;
//
//     const isAutoGen = isAutoGenerateBarcode(type);
//     const isVariableWeight = isAutoGen && isPriceEmbedded({ upc });
//     if (isVariableWeight) {
//         const newPrice = calcPriceForWeight(object);
//         return genUpcPriceEmbeddedByType({ ...object, type: object.formatBarcode, price: newPrice });
//     }
//     if (type === 'sku') {
//         return `${sku}-${fixedTo2(price)}`
//     }
//     return upc;
// };
//
// export const getServiceBarcodePrintFormat = ({ upc, sku, formatBarcode }) => {
//
//     if (formatBarcode) {
//         if (formatBarcode === 'weighted') {
//             return 'CODE128'
//         }
//         if (formatBarcode === 'sku' && isDigit(sku)) {
//             return 'CODE128'
//         }
//         if (formatBarcode === 'upc') {
//             return 'CODE128'
//         }
//         if (formatBarcode === 'upc-a') {
//             const priceEmbeddedUPCA = isPriceEmbeddedUPCA({ upc })
//             return priceEmbeddedUPCA && isDigit(sku) ? 'UPC' : 'CODE128'
//         }
//         if (formatBarcode === 'ean-13') {
//             const priceEmbeddedUPCA = isPriceEmbeddedEAN13({ upc })
//             return priceEmbeddedUPCA && isDigit(sku) ? 'EAN13' : 'CODE128'
//         }
//     }
//     return ''
// };

export const UPC = {
  decodePriceEmbedded,
  isPriceEmbedded,
  isRecognizeBarcode,
  isCustomSKUBarcode,
};
