import userReducer from "./user";
import authReducer from "./auth";
import storeReducer from "./store";
import orderReducer from "./order";
import siderReducer from "./sider";
import productReducer from "./product";
import paymentReducer from "./payment";
import customerReducer from "./customer";
import categoryReducer from "./category";
import merchantReducer from "./merchant";
import orderListReducer from "./orderList";
import departmentReducer from "./department";
import breadcrumbReducer from "./breadcrumb";
import notifyReducer from "./notify";
import igwExtensionReducer from "./IGWExtension";

export const reducer = {
  user: userReducer,
  auth: authReducer,
  store: storeReducer,
  order: orderReducer,
  sider: siderReducer,
  payment: paymentReducer,
  product: productReducer,
  merchant: merchantReducer,
  customer: customerReducer,
  category: categoryReducer,
  orderList: orderListReducer,
  department: departmentReducer,
  breadcrumb: breadcrumbReducer,
  igwExtension: igwExtensionReducer,
  notify: notifyReducer,
};
