export const CartItemName = ({
  name,
  price,
}: {
  name: string;
  price: string | number;
}): JSX.Element => (
  <>
    {+price === 0 ? (
      <>
        <span>{name}</span>
        <span className="badge">FREE</span>
      </>
    ) : (
      <span>{name}</span>
    )}
  </>
);
