import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import { isString, isEmptyString } from "../util/variableTypes";
import { CustomerAPI } from "../util/API";
import { getErrorString } from "../util/helpers";
import type { ParamName } from "../util/API";
import type { ICustomer } from "../store/types";

type CustomerState = {
  list: ICustomer[];
  loading: boolean;
  error: string;
};

const initialState: CustomerState = {
  list: [],
  loading: false,
  error: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    start: (state, action: PayloadAction<string>): CustomerState => ({
      ...state,
      list: [],
      loading: true,
      error: "",
    }),
    fail: (state, action: PayloadAction<string>): CustomerState => ({
      ...state,
      list: [],
      loading: false,
      error: action.payload,
    }),
    success: (state, action: PayloadAction<ICustomer[]>): CustomerState => ({
      ...state,
      list: action.payload,
      loading: false,
      error: "",
    }),
    clear: (state): CustomerState => ({
      ...state,
      list: [],
      loading: false,
      error: "",
    }),
  },
});
const { fail, success, start, clear } = customerSlice.actions;
export default customerSlice.reducer;

export const arrayUniqueByKey = (array: ICustomer[], key: string) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

export const fetchCustomers = (query: ParamName) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start(query));
    const params = [];
    if (query && isString(query) && !isEmptyString(query)) {
      params.push(`q=${query}`);
    }
    CustomerAPI.search(params.join("&"))
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          const list = response.rows;
          const customers = arrayUniqueByKey(list, "_id");
          dispatch(success(customers));
        } else {
          dispatch(fail(query));
        }
      })
      .catch((error) => {
        dispatch(fail(query));
      });
  };
};

export const clearCustomers = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(clear());
  };
};
