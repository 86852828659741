import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { IMerchant } from "./types";
import { getMerchant, MerchantAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type MerchantState = {
  object?: IMerchant;
  loading: boolean;
  error: string;
};

const initialState: MerchantState = {
  object: undefined,
  loading: false,
  error: "",
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    start: (state) => {
      return { ...state, loading: true, error: "" };
    },
    fail: (state, action: PayloadAction<string>) => {
      console.warn("merchant", action.payload);
      return { ...state, loading: false, error: action.payload };
    },
    success: (state, action: PayloadAction<IMerchant>) => {
      const _id = getMerchant();
      console.info("merchant", _id, action.payload);
      return { ...state, object: { ...action.payload, _id }, loading: false, error: "" };
    },
    reset: (state) => {
      return { ...initialState };
    },
  },
});

const { fail, success, start, reset } = merchantSlice.actions;
export default merchantSlice.reducer;

export const fetchMerchant = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    MerchantAPI.fetchOne()
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          dispatch(success(response));
        } else {
          dispatch(fail(err));
        }
      })
      .catch((error) => {
        dispatch(fail(getErrorString(error)));
      });
  };
};

export const resetMerchant = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(reset());
  };
};
