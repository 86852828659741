import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { IDepartment } from "./types";
import { DepartmentAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type DepartmentState = {
  list: IDepartment[];
  loading: boolean;
  error: string;
};

const initialState: DepartmentState = { list: [], loading: false, error: "" };

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    start: (): DepartmentState => ({ list: [], loading: true, error: "" }),
    fail: (state, action: PayloadAction<string>): DepartmentState => ({
      ...state,
      list: [],
      loading: false,
      error: action.payload,
    }),
    success: (
      state,
      action: PayloadAction<IDepartment[]>
    ): DepartmentState => ({ ...state, list: action.payload, loading: false }),
  },
});
const { fail, success, start } = departmentSlice.actions;
export default departmentSlice.reducer;

// methods for public usage are below

export const fetchAllDepartments = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    DepartmentAPI.fetchAll()
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          dispatch(success(response));
        } else {
          dispatch(fail(err));
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(fail(err));
      });
  };
};
