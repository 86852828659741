import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { IProduct, IProductListByCategory } from "./types";
import { ParamName, ProductAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type IProductView = "group" | "list";

type ProductState = {
  list: IProductListByCategory;
  view: IProductView;
  loading: boolean;
  error: string;
  selected: IProduct | null;
};

const initialState: ProductState = {
  list: {},
  view: "group",
  loading: false,
  error: "",
  selected: null,
};

type ProductCategoryPayload = {
  category: string;
  rows: IProduct[];
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    start: (state, action: PayloadAction<string>): ProductState => {
      const category = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [category]: { rows: [], loading: true, error: "" },
        },
      };
    },
    fail: (
      state,
      action: PayloadAction<{ category: string; error: string }>
    ): ProductState => {
      const { category, error } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [category]: { rows: [], loading: false, error },
        },
      };
    },
    success: (
      state,
      action: PayloadAction<ProductCategoryPayload>
    ): ProductState => {
      const { category, rows } = action.payload;
      const loading = false;
      const error = "";
      console.warn('PRODUCT LIST', category, rows);
      return {
        ...state,
        list: { ...state.list, [category]: { rows, loading, error } },
      };
    },
    setView: (state, action: PayloadAction<IProductView>): ProductState => ({
      ...state,
      view: action.payload,
    }),
    select: (state, action: PayloadAction<IProduct>): ProductState => ({
      ...state,
      selected: action.payload,
    }),
    clear: (state): ProductState => ({ ...state, selected: null }),
  },
});

const { fail, success, start, setView, select, clear } = productSlice.actions;
export default productSlice.reducer;

// public methods are below

export const fetchProductsByCategory = (category: ParamName) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start(category));
    ProductAPI.fetchByCategory(category)
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          if (response.result) {
            dispatch(success({ category, rows: response.result }));
          }
        } else {
          dispatch(fail({ category, error: err }));
        }
      })
      .catch((error) => {
        dispatch(fail({ category, error: getErrorString(error) }));
      });
  };
};

export const setProduct = (product: IProduct | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(select(product));
  };
};

export const setProductView = (view: IProductView) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setView(view));
  };
};

// Example:
// import { fetchProductsByCategory, setProduct } from './store/product';
//
// const dispatch = useAppDispatch();
// fetchProductsByCategory('category1')(dispatch);
