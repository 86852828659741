import {
  DollarCircleOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  GiftOutlined,
} from "@ant-design/icons";

export const refundAliases = [
  "refundcash",
  "checkrefund",
  "refund",
  "payrolldeductrefund",
];
export const negativePaymentsAliases = [
  ...refundAliases,
  // "giftCard",
  "void",
  "change",
];

export const paymentTypesIcons = {
  cash: <DollarCircleOutlined style={{ verticalAlign: "2px" }} />,
  creditCard: <CreditCardOutlined style={{ verticalAlign: "2px" }} />,
  employeePayrollDeduct: <IdcardOutlined style={{ verticalAlign: "2px" }} />,
  giftCard: <GiftOutlined style={{ verticalAlign: "2px" }} />,
};
