import { useState } from "react";
import { Form, Tabs, Input } from "../UI/Layout";
import { Button } from "../UI/Controls/Button";

interface IProps {
  handleSubmit: any;
  firstName?: string;
  lastName?: string;
  username?: string;
  password?: string;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

// eslint-disable-next-line
const PHONE_REGEX = new RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
);

export const SignUpForm = ({ handleSubmit }: IProps): JSX.Element => {
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState("email");
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={handleSubmit}
      scrollToFirstError
    >
      <Tabs onChange={onChange}>
        <Tabs.TabPane tab="Email" key="email">
          <Form.Item
            key="email"
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
              {
                required: activeKey === "email",
                message: "Please enter your E-mail",
              },
            ]}
          >
            <Input placeholder="example@domain.com" />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Phone Number" key="phone">
          <Form.Item
            key="phone"
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: activeKey === "phone",
                message: "Please enter your phone number",
              },
              {
                message: "Please enter a valid phone number.\n",
                pattern: PHONE_REGEX,
              },
            ]}
          >
            <Input placeholder="XXX-XXX-XXXX" style={{ width: "100%" }} />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please enter your password",
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="******" />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match")
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder="******" />
      </Form.Item>
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please enter your First Name",
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          {
            required: true,
            message: "Please enter your Last Name",
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <div className="form-item">
        <Button actionType="submit" size="lg" onClick={() => {}}>
          Create an Account
        </Button>
      </div>
    </Form>
  );
};
