import { Modal } from "../../../UI/Layout";
import { Button } from "../../../UI/Controls/Button";
import { TipsContainer } from "../../Tips";

interface IProps {
  visible: boolean;
  handleCancel: any;
  handleNoTips: any;
}

export const TipsModal = ({
  visible,
  handleCancel,
  handleNoTips,
}: IProps): JSX.Element => (
  <Modal
    title="Tips"
    open={visible}
    okButtonProps={{}}
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleNoTips}>
        No Tip
      </Button>,
      <Button key="cancel" type="primary" onClick={handleCancel}>
        <span>&nbsp;Cancel</span>
      </Button>,
    ]}
  >
    <TipsContainer handleOkTips={handleCancel} />
  </Modal>
);
