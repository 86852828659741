import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { IOrder } from "./types";
import { OrderAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type OrderListState = {
  list: IOrder[];
  loading: boolean;
  error: string;
};

const initialState: OrderListState = { list: [], loading: false, error: "" };

const orderListSlice = createSlice({
  name: "orderList",
  initialState,
  reducers: {
    start: (): OrderListState => ({ list: [], loading: true, error: "" }),
    fail: (state, action: PayloadAction<string>): OrderListState => ({
      list: [],
      loading: false,
      error: action.payload,
    }),
    success: (state, action: PayloadAction<IOrder[]>): OrderListState => ({
      list: action.payload,
      loading: false,
      error: "",
    }),
    clear: (): OrderListState => ({ list: [], loading: false, error: "" }),
  },
});

const { start, fail, success, clear } = orderListSlice.actions;
export default orderListSlice.reducer;

export const fetchOrderList = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    OrderAPI.fetchOnHold()
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          if (response.rows) {
            dispatch(success(response.rows.map((o) => o.doc)));
          } else {
            dispatch(fail("Response is not recognized"));
          }
        } else {
          dispatch(fail(err));
        }
      })
      .catch((error) => {
        dispatch(fail(getErrorString(error)));
      });
  };
};
