import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { Result, Progress, Row, Col } from "../../components/UI/Layout";
import { useNavigate } from "react-router-dom";
import { PrinterModule } from "../../store/IGWExtension";
import { PUBLIC_BASE_URL } from "../../constants/api";
import { setSiderCollapsed } from "../../store/sider";
import { setCategory } from "../../store/category";
import { setProduct } from "../../store/product";
import { resetOrder } from "../../store/order";
import { resetPayment } from "../../store/payment";
import { Button } from "../UI/Controls/Button";

export const OrderThankyou = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  console.log("OrderThankyou");

  const [loadingPdf, setLoadingPdf] = useState<boolean>(true);

  const { order, config } = useAppSelector((state) => ({
    order: { ...state.order },
    config: state.merchant.object,
  }));

  const { printToExtension = false } = config;

  const onBackMode = () => {
    navigate("/register");
    setCategory(null)(dispatch);
    setProduct(null)(dispatch);
    setSiderCollapsed(true)(dispatch);
  };

  const onResetOrder = () => {
    onBackMode();
    resetOrder()(dispatch);
    resetPayment()(dispatch);
  };

  const pdfUrl = PUBLIC_BASE_URL + `/receipt/50mm/#/order/${order._id}`;

  const onLoad = () => {
    setLoadingPdf(false);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12} offset={6}>
          <div className="flexFlowRow">
            <Button
              size="lg"
              type="primary"
              className="m15"
              onClick={onResetOrder}
              style={{ width: "100%" }}
            >
              New Order
            </Button>
            {printToExtension ? (
              <Button
                size="lg"
                label="Print"
                type="primary"
                className="m15"
                style={{ width: "100%" }}
                onClick={() => PrinterModule.send(order, config)(dispatch)}
              />
            ) : null}
          </div>
        </Col>
      </Row>
      {loadingPdf ? (
        <Result
          status="success"
          title="Successfully Purchased!"
          subTitle={`Order #: ${order._id}`}
          extra={[
            <Progress
              key="progress"
              type="circle"
              format={(percent) => `Loading PDF`}
              percent={69}
              status="active"
            />,
          ]}
        />
      ) : null}
      <div style={{ overflow: "scroll", height: "calc(100vh - 200px)" }}>
        <iframe
          style={{ border: "1px solid #CCC" }}
          title={`Order #: ${order._id}`}
          scrolling="auto"
          onLoad={onLoad}
          id={order._id}
          height={loadingPdf ? 0 : "100%"}
          src={pdfUrl}
          width="100%"
        ></iframe>
      </div>
    </>
  );
};
