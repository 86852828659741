import { getId, getPort, validate } from "../constants/extension";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { IMerchant } from "./types";
import { isObject, isEmptyObject } from "./../util/variableTypes";
import { isDigit } from "../util/helpers";

const getQueueName = (cmd: { module?: string }) => {
  if (cmd && cmd.module) {
    const module = cmd.module;
    const nameQueue = `${module}Queue`;
    return nameQueue;
  }
  return "queue";
};

// const getExtentionError = {
//     invalidTerminalLocation: (storeLocation: any, availStores: any = []) =>
//         (`EXTENSION CONFIGURATION ERROR. Store Location '${storeLocation}' is not recognized. Available stores: '${availStores.join('\', \'')}'`)
// };

type IGWExtensionState = {
  status: string;      // connection status, received from extension
  scaleWeight: string;
  scannerValue: string;
  queueStatus: any;
  inProgress: any;
};

const initialState: IGWExtensionState = {
  status: "",
  scaleWeight: "",
  scannerValue: "",
  queueStatus: {},
  inProgress: {},
} as any;

const igwExtensionSlice = createSlice({
  name: "IGWExtension",
  initialState,
  reducers: {
    setConnectStatus: (state, action: PayloadAction<any>) => {
      return { ...state, status: action.payload };
    },
    setQueueActive: (state, action: PayloadAction<any>) => {
      const cmd = action.payload;
      if (cmd && cmd.module) {
        const newState = { ...state, inProgress: cmd };
        console.log(
          "IGW EXTENSION STATE AFTER SET ACTIVE ELEMENT IN QUEUE" +
          JSON.stringify(newState)
        );
        return newState;
      }
      return state;
    },
    addQueue: (state, action: PayloadAction<any>) => {
      const cmd = action.payload;
      if (cmd && cmd.module) {
        const nameQueue = getQueueName(cmd);
        const queueStatus = { ...state.queueStatus };
        const existedQueue = state.queueStatus[nameQueue] || [];
        const newArr = [action.payload, ...existedQueue];
        queueStatus[nameQueue] = newArr;
        const newState = { ...state, queueStatus };
        console.log(
          "IGW EXTENSION STATE AFTER ADD TO QUEUE" + JSON.stringify(newState)
        );
        return newState;
      }
      return state;
    },
    removeQueue: (state, action: PayloadAction<any>) => {
      const cmd = action.payload;
      if (cmd && cmd.module && cmd.reqId) {
        const nameQueue = getQueueName(cmd);
        const existedQueue = (state.queueStatus[nameQueue] || []) as any;
        if (existedQueue.length) {
          const newQueue =
            existedQueue.filter((obj: any) => obj.reqId !== cmd.reqId) || [];
          const queueStatus = { ...(state.queueStatus || {}) } as any;
          queueStatus[nameQueue] = newQueue;
          const newState = { ...state, queueStatus, inProgress: {} };
          console.log(
            "IGW EXTENSION STATE AFTER REMOVE FROM QUEUE" +
            JSON.stringify(newState)
          );
          return newState;
        }
      }
      return state;
    },
    weight: (state, action: PayloadAction<any>) => {
      return { ...state, scaleWeight: action.payload };
    },
    scaleClean: (state, action: PayloadAction<any>) => {
      return { ...state, scaleWeight: "" };
    },
    scannerEvent: (state, action: PayloadAction<any>) => {
      return { ...state, scannerValue: action.payload };
    },
    scannerClean: (state, action: PayloadAction<any>) => {
      return { ...state, scannerValue: "" };
    },
  },
});

const {
  setConnectStatus,
  setQueueActive,
  addQueue,
  removeQueue,
  weight,
  scaleClean,
  scannerEvent,
  scannerClean,
} = igwExtensionSlice.actions;
export default igwExtensionSlice.reducer;

// TODO: listener and each module

const onListenerIGWExtension = (e: any, dispatch: AppDispatch) => {
  if (e) {
    const msg = e.data || e;
    console.log("IGW EXTENSION RESPONSE: " + JSON.stringify(msg));
    dispatch(removeQueue(msg));
    if (msg.module && msg.command) {
      // ------------SYSTEM------------
      if (msg.module === "system") {
        if (["connectionEvent", "connect"].includes(msg.command)) {
          const { status } = msg;
          console.log("IGW EXTENSION CONNECT STATUS: " + status);
          dispatch(setConnectStatus(status));
          if (status === "success") {
            SystemModule.getTerminalID()(dispatch);
          }
        }
      }
      // ------------SCALE------------
      if (msg.module === "scale") {
        if (msg.command === "readWeight") {
          const { message, status } = msg;
          console.log(
            "IGW EXTENSION SCALE READ WEIGHT MESSAGE: " +
            message +
            " STATUS: " +
            status
          );
          if (
            msg.status === "success" &&
            isDigit(message) &&
            message.toString().length
          ) {
            dispatch(weight(message));
            setTimeout(() => dispatch(scaleClean), 100);
          }
        }
        if (msg.command === "zeroScale") {
          console.log("IGW EXTENSION ZERO SCALE STATUS: " + msg.status);
          if (msg.status === "success") {
            dispatch({ type: "READ_WEIGHT", payload: 0 });
            setTimeout(() => dispatch(scaleClean), 100);
          }
        }
      }
      // ------------SCANNER------------
      if (msg.module === "scanner") {
        if (msg.command === "registerListener") {
          console.log("IGW EXTENSION REGISTER LISTENER STATUS: " + msg.status);
        }
        // Unsolicited Scanner Event
        if (["scannerEvent", "scanEvent"].includes(msg.command)) {
          const { message } = msg;
          console.log("IGW EXTENSION SCANNER EVENT: " + message);
          dispatch(scannerEvent(message.trim()));
          setTimeout(() => dispatch(scannerClean), 100);
        }
      }
      // ------------PRINTER------------
      if (msg.module === "printer") {
        if (msg.command === "printData") {
          const { status } = msg;
          console.log("IGW EXTENSION PRINTER STATUS: " + status);
        }
      }
    }
  }
};

export const registerReceiver = () => (dispatch: AppDispatch) => {
  if (!validate()) return;
  try {
    // extension connection description - this value must be used
    console.log("IGW USING EXTENSION: " + getId());
    // open two way communication to chrome extension
    const port = getPort();
    // required to be notified of responses to commands sent to
    // the controlling extension
    const onListener = (dispatch: AppDispatch) => (msg: string) => {
      onListenerIGWExtension(msg, dispatch);
    }
    port.onMessage.addListener(onListener(dispatch));
    (window as any).emulateIgwMessage = onListener(dispatch);
  } catch (e) {
    console.error("IGW EXTENSION RECEIVER ERROR: ", e);
  }
};

export const sendNextInQueue = (state: IGWExtensionState) => (dispatch: AppDispatch) => {
  if (!validate()) return;
  try {
    console.log(
      "IGW EXTENSION STATE BEFORE TAKING NEXT" +
      JSON.stringify(state)
    );

    const queueStatus = state.queueStatus;
    if (queueStatus && typeof queueStatus === "object") {
      Object.keys(queueStatus).forEach((queueName) => {
        try {
          if (queueName && queueStatus[queueName]) {
            const queue = queueStatus[queueName] || [];
            if (queue && queue.length) {
              const deviceCmd = queue[queue.length - 1];
              if (deviceCmd) {
                SystemModule.execute(deviceCmd)(dispatch);
              } else {
                console.info("IGWExtension NO CMD", queueName);
              }
            } else {
              console.info("IGWExtension QUEUE EMPTY", queueName);
            }
          } else {
            console.info(
              "IGWExtension QUEUE NOT FOUND or EMPTY",
              queueName,
              queueStatus[queueName]
            );
          }
        } catch (e) {
          console.log("Error getting queue", e);
        }
      });
    }


  } catch (e) {
    console.error("IGW EXTENSION SENDER ERROR: ", e);
  }
};


const buildExtCmd = (
  mod: string,
  command: string,
  message?: string,
  printObj?: any,
  error?: any,
  receiptStatus?: any
) => {
  if (typeof mod === "undefined") return null;
  if (typeof command === "undefined") command = "";
  if (typeof message === "undefined") message = "";
  if (typeof printObj === "undefined") printObj = "";
  if (typeof receiptStatus === "undefined") receiptStatus = "";
  if (typeof error === "undefined") error = "";

  const reqId =
    new Date().getTime() + "-" + parseInt(Math.random() * 10000 + "", 10);
  const cmd = {} as any;

  cmd.module = mod;
  cmd.reqId = reqId;

  if (command.length > 0) cmd["command"] = command;
  if (message.length > 0) cmd["message"] = message;
  if (receiptStatus.length > 0) cmd["receiptStatus"] = receiptStatus;
  if (isObject(error) && !isEmptyObject(error)) cmd["error"] = error;

  if (
    printObj &&
    typeof printObj === "object" &&
    Object.keys(printObj).length > 0
  )
    cmd["printObj"] = printObj;
  return cmd;
};

const postToIGWDeviceUtil = (port: any, cmd: any) => {
  if (port) {
    console.warn("POSTING TO IGW EXTENSION: " + JSON.stringify(cmd));
    port.postMessage(cmd);
  }
};

export const PrinterModule = {
  send: (doc: any, merchantConfig: IMerchant) => {
    return (dispatch: AppDispatch) => {
      const { status } = doc || {};
      const printObj = { ...doc, id: doc._id, merchantConfig };
      console.info("IGW EXTENSION START PRINTING", printObj.id);
      try {
        const receiptStatus = status !== "ready" ? "active" : "closed";
        const deviceCmd = buildExtCmd(
          "printer",
          "printData",
          "",
          printObj,
          "",
          receiptStatus
        );
        dispatch(addQueue(deviceCmd));
      } catch (e) {
        console.error("IGW EXTENSION ERROR", e);
      }
    };
  },
};

export const ScaleModule = {
  readWeight: () => {
    return (dispatch: AppDispatch) => {
      try {
        const deviceCmd = buildExtCmd("scale", "readWeight");
        dispatch(addQueue(deviceCmd));
      } catch (e) {
        console.error("IGW EXTENSION READ WEIGHT SCALE: " + e);
      }
    };
  },
  zeroScale: () => {
    return (dispatch: AppDispatch) => {
      try {
        const deviceCmd = buildExtCmd("scale", "zeroScale");
        dispatch(addQueue(deviceCmd));
      } catch (e) {
        console.error("IGW EXTENSION ZERO SCALE", e);
      }
    };
  },
};

export const scanner = () => {
  return (dispatch: AppDispatch) => {
    try {
      const deviceCmd = buildExtCmd("scanner", "registerListener");
      dispatch(addQueue(deviceCmd));
    } catch (e) {
      console.error("IGW EXTENSION SCANNER" + e);
    }
  };
};

export const SystemModule = {
  getTerminalID: () => {
    return (dispatch: AppDispatch) => {
      try {
        // device command to execute
        const deviceCmd = buildExtCmd("system", "getTerminalID");
        dispatch(addQueue(deviceCmd));
      } catch (e) {
        console.error("IGW EXTENSION TERMINAL ID: " + e);
      }
    };
  },
  // execute command from the queue
  execute: (cmd: any) => {
    if (!validate()) return;
    return (dispatch: AppDispatch) => {
      try {
        // map command that we are executing it
        dispatch(setQueueActive(cmd));
        // actually send the command to the extension
        postToIGWDeviceUtil(getPort(), cmd);
      } catch (e) {
        console.error("IGW EXTENSION ADD TO QUEUE: " + e);
      }
    };
  },
  error: (message: string) => { // NEVER USED
    return (dispatch: AppDispatch) => {
      try {
        // device command to execute
        const error = {
          message,
          severity: "warn",
        };
        const deviceCmd = buildExtCmd(
          "system",
          "errorNotification",
          "",
          "",
          error
        );
        dispatch(addQueue(deviceCmd));
      } catch (e) {
        console.error("IGW EXTENSION ERROR NOTIFICATION: " + e);
      }
    };
  },
};

/* For test in console - command that can receive from the extension 

window.emulateIgwMessage({ module: "system", command: "getTerminalID", status: "success", message: "123" }, "*");

window.emulateIgwMessage({ module: "system", command: "connect", status: "success" }, "*");

window.emulateIgwMessage({
  module: "system", command: "getDejavooSettings", message: {
    storeLocation: "Rabers Kountry Store",
    stationId: "Station",
    registerId: "001",
    tpn: "000"
  }
}, "*");

window.emulateIgwMessage({ module: "scale", command: "readWeight", status: "success", message: "1.00" }, "*");

window.emulateIgwMessage({ module: "scale", command: "zeroScale", status: "success" }, "*");

window.emulateIgwMessage({ module: "scanner", command: "registerListener", status: "success" }, "*");

window.emulateIgwMessage({ module: "scanner", command: "scannerEvent", message: "012345678901" }, "*");

window.emulateIgwMessage({ module: "printer", command: "printData", status: "success" }, "*");
 
*/
