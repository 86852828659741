import { isArray, isEmptyArray } from "./variableTypes";
import { UPC } from "./UPC";
import type { IProduct, IStore } from "../store/types";

const { decodePriceEmbedded, isCustomSKUBarcode, isPriceEmbedded } = UPC;

const isAvailProduct = (product: any, store: IStore) => {
  const { doc } = product;
  const { infinity, onHand = [], isService } = doc || product;
  const { name, negativeInventory } = store;
  const item =
    onHand && onHand.length
      ? onHand.find((item: any) => item.store && item.store === name) || {}
      : {};
  const { amount = 0, infinityPerStore = false } = item;
  const isValid =
    infinityPerStore ||
    infinity ||
    negativeInventory === "Yes" ||
    amount > 0 ||
    isService;
  console.log(
    "product isValid=",
    isValid,
    "infinity=",
    infinity,
    "isService=",
    isService,
    "infinityPerStore=",
    infinityPerStore,
    "store name=",
    name
  );
  return isValid;
};

const filterByUPCSKU = (doc: any, q: string) =>
  [doc.upc, doc.sku].some((o) => o && o.toString().indexOf(q) === 0);

export const isInactiveObject = (prod: IProduct) =>
  prod ? prod.status && prod.status === "inactive" : false;

export const isWeightedTypeBarcode = (type: string | undefined) =>
  type ? ["upc-a", "ean-13", "weighted"].includes(type) : false;

export const ProdByBarcodeValidator = (
  response: any,
  barcode: string,
  query: string,
  store: IStore
) => {
  let products = response;
  const result: IProduct[] = [];
  const messages: string[] = [];
  const addMsg = (content: string) => messages.push(content);
  const finish = () =>
    new Promise((resolve, reject) => {
      if (messages.length) {
        console.log("Products validator: " + messages.join(". "));
      }
      if (result.length) {
        console.log("Products validator: result length = " + result.length);
      }
      if (!messages.length && result.length) resolve(result);
      else reject(messages.join(". "));
    });

  if (isArray(response) && !isEmptyArray(response)) {
    products = response.map((obj) => obj.doc || obj);
  } else {
    if (response._id && !response.rows) {
      products = [response];
    } else if (response.rows) {
      products = response.rows.map((obj) => obj.doc || obj);
    } else {
      addMsg(`Product not found by barcode '${barcode}'`);
      return finish();
    }
  }

  if (!(isArray(products) && !isEmptyArray(products))) {
    addMsg(`Product not found by barcode '${barcode}'`);
    return finish();
  } else {
    const activeItems =
      products.filter(
        (doc) => !isInactiveObject(doc) && filterByUPCSKU(doc, query)
      ) || [];

    if (!(isArray(activeItems) && !isEmptyArray(activeItems))) {
      addMsg(`Product is not present in stoke by barcode '${barcode}'`);
      return finish();
    }

    const availProd = !!store
      ? activeItems.filter((prod: IProduct) => isAvailProduct(prod, store)) ||
        []
      : activeItems;
    console.log(
      `FOUND PRODUCTS = ${products.length} AVAIL PRODUCTS = ${availProd.length}`
    );

    if (!(isArray(availProd) && !isEmptyArray(availProd))) {
      addMsg(`Product not found in stoke by barcode '${barcode}'`);
      return finish();
    } else {
      availProd.forEach((item: IProduct) => {
        const { formatBarcode, upc } = item;
        const type = formatBarcode;
        const isWeightedType = isWeightedTypeBarcode(formatBarcode);
        console.log(
          "item = " + JSON.stringify(item) + " type barcode = " + type
        );

        const priceEmbedded =
          isWeightedType && isPriceEmbedded({ upc: barcode });

        if (priceEmbedded) {
          const newPriceObject: { [key: string]: any } = {
            price: "",
            oldPrice: "",
          };
          if (priceEmbedded) {
            const { currency, cent } =
              decodePriceEmbedded({ upc: barcode }) || {};
            newPriceObject.price = `${currency}.${cent}`;
            newPriceObject.oldPrice = item.price;
          }
          console.log("1.newPrice = " + JSON.stringify(newPriceObject));
          console.log("old upc = " + upc + " new upc = " + barcode);
          if (!Object.keys(newPriceObject).length)
            addMsg(`Product price is not valid from barcode '${barcode}'`);

          if (+item.price > -1) {
            const quantity =
              +item.price > 0 ? newPriceObject.price / +item.price : 0;
            if (!quantity && parseFloat(newPriceObject.price)) {
              const quantity = 1;
              const updatedItem = {
                ...item,
                quantity,
                price: newPriceObject.price,
                upc: barcode,
              };
              console.log("quantity = " + quantity);
              result.push(updatedItem);
            } else {
              const updatedItem = {
                ...item,
                quantity,
                upc: barcode,
                soldByWeight: true,
              };
              console.log("quantity = " + quantity);
              result.push(updatedItem);
            }
          } else {
            const quantity = 1;
            const updatedItem = {
              ...item,
              quantity,
              price: newPriceObject.price,
              upc: barcode,
            };
            console.log("quantity = " + quantity);
            result.push(updatedItem);
          }
        } else {
          if (type === "sku") {
            const newPriceObject: { [key: string]: any } = {};
            if (priceEmbedded) {
              const { price: newPrice = "" } = isCustomSKUBarcode(
                upc,
                true
              ) || { price: "" };
              newPriceObject.price = newPrice;
              newPriceObject.oldPrice = item.price;
            }

            console.log("2.newPrice = " + newPriceObject);
            const updatedItem = { ...item, ...newPriceObject };
            result.push(updatedItem);
          } else {
            console.log("3.Price = " + item.price);
            result.push(item);
          }
        }
      });
    }
  }

  return finish();
};

export default ProdByBarcodeValidator;
