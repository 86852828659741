import { InputNumber } from "../../UI/Layout";

interface IProps {
  min?: number;
  max?: number;
  className: string;
  disabled?: boolean;
  value: number | undefined;
  onChange: any;
}

export const PriceInput = (props: IProps): JSX.Element => (
  <InputNumber
    formatter={(value: any) => {
      if (value) return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "";
    }}
    parser={(value: any) => {
      if (value) return value.replace(/\$\s?|(,*)/g, "");
      return "";
    }}
    className={props.className}
    min={0}
    max={props.max}
    onChange={props.onChange}
    value={props.value}
    disabled={props.disabled}
  />
);
