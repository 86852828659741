declare const window: any;

interface ImportMeta {
  env: {
    VITE_EXTENSION_ID: string | null | undefined;
  };
}

export const getId = () => {
  if (localStorage.getItem("IgwExtensionId")) {
    // let localstorage be a priority
    return localStorage.getItem("IgwExtensionId");
  }
  return (
    import.meta.env.VITE_EXTENSION_ID || "onpflhkenmoolchkmdidbnkcankalchj"
  );
};

let port: any;
const extensionConn = { name: "igwdeviceutility" };
export const getPort = () => {
  if (!port || typeof port !== "object") {
    port = window.chrome.runtime.connect(getId(), extensionConn);
    console.log("start: window.chrome.runtime.connect ", port, getId());
    port.onDisconnect.addListener(() => {
      console.info("IGW EXTENSION: disconnected");
    });
    console.log("end: window.chrome.runtime.connect", port);
  }
  return port;
};

export const validate = () => {
  if (typeof window.chrome !== "object") {
    console.warn("TERMINAL ERROR: Not in chrome");
    return false;
  }
  if (typeof window.chrome.runtime !== "object") {
    console.error("TERMINAL ERROR: Cannot find chrome.runtime");
    return false;
  }
  if (typeof window.chrome.runtime.connect !== "function") {
    console.error("TERMINAL ERROR: chrome.runtime.connect is not a function");
    return false;
  }
  return true;
};
