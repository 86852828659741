import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { Statistic, Card } from "../../components/UI/Layout";
import { PriceInput } from "../UI/Controls/PriceInput";
import { getPercentage, totalSelectorNoTips } from "../../store/selector/cart";
import { Button } from "../../components/UI/Controls/Button";
import { getMoneyString } from "../../util/helpers";
import { setOrderTips } from "../../store/order";

interface IProps {
  handleOkTips: any;
}

const optionsPercent = [15, 20, 25];
const optionsDollar = [0.5, 1, 2];

export const TipsContainer = ({ handleOkTips }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [amount, setAmount] = useState<number>(0);
  const { order } = useAppSelector((state) => ({
    order: { ...state.order, totals: totalSelectorNoTips(state) },
  }));

  const { totals: { grandTotal = 0 } = {} } = order;

  const handleOk = () => {
    setOrderTips(amount)(dispatch);
    handleOkTips();
  };

  const onClick = (value: number) => {
    setOrderTips(value)(dispatch);
    handleOkTips();
  };

  return (
    <div className="flexFlowCol pt30 p15" style={{ background: "#cccccc3d" }}>
      {grandTotal ? (
        <Card>
          <Statistic
            prefix="$"
            precision={2}
            style={{ flex: 1 }}
            title="Total"
            value={grandTotal}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card>
      ) : null}
      <div
        className="flexFlowRow"
        style={
          window.innerWidth < 600
            ? { display: "flex", justifyContent: "center" }
            : { margin: "0 auto", width: "100%" }
        }
      >
        <div
          className="flexFlowRow mt15"
          style={
            window.innerWidth < 600
              ? { flexDirection: "column", minWidth: "100%" }
              : { alignItems: "center" }
          }
        >
          <div style={window.innerWidth < 600 ? { textAlign: "end" } : {}}>
            Amount:&nbsp;
          </div>
          <PriceInput
            value={amount}
            className="form-control pl15 pr15"
            onChange={(value: number) => setAmount(value)}
          />
          <Button
            size="lg"
            style={
              window.innerWidth < 600
                ? { width: "100%" }
                : { width: "300px", marginLeft: "15px" }
            }
            onClick={handleOk}
            disabled={grandTotal <= 0}
          >
            <span>Apply</span>
          </Button>
        </div>
      </div>
      <div
        className="flexFlowRow"
        style={
          window.innerWidth < 600
            ? {
                margin: "15px auto",
                width: "100%",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }
            : { margin: "0px auto", marginTop: "15px", width: "100%" }
        }
      >
        {optionsDollar.map((val: number) => (
          <Button
            key={val}
            size="lg"
            type="success"
            className="mb15"
            style={{ width: "100%" }}
            onClick={() => onClick(val)}
            label={`${getMoneyString(val)}`}
          />
        ))}
      </div>
      <div
        className="flexFlowRow"
        style={
          window.innerWidth < 600
            ? {
                margin: "15px auto",
                width: "100%",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }
            : { margin: "0 auto", width: "100%" }
        }
      >
        {optionsPercent.map((val: number) => (
          <Button
            key={val}
            size="lg"
            type="success"
            className="mb15"
            style={{ width: "100%" }}
            onClick={() => onClick(getPercentage(grandTotal, val))}
            label={`${val}% - ${getMoneyString(
              getPercentage(grandTotal, val)
            )}`}
          />
        ))}
      </div>
    </div>
  );
};
