import { LoadingOutlined } from "@ant-design/icons";

interface IProps {
  error: string;
  loading: boolean;
  children: JSX.Element;
}

export const LoadingIndicator = (props: IProps): JSX.Element => {
  if (props.error) {
    return (
      <div className="m20 text-center text-3xl font-bold text-color-error">
        Error: {props.error}
      </div>
    );
  }
  if (props.loading) {
    return (
      <LoadingOutlined
        style={{
          fontSize: "50px",
          color: "#08c",
          marginTop: "calc(100vh/2 - 70px)",
        }}
      />
    );
  }
  return props.children;
};
