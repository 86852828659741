import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.css";
import "./index.css";
import { store } from "./store";
import { App } from "./App";

// its good to leave here a React.StrictMode wrapper
// but it renders App twice
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
