import { useState } from "react";
import { AutoComplete, Input, Button } from "../../components/UI/Layout";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { SearchOutlined } from "@ant-design/icons";
import type { ICustomer, ICustomerOption } from "../../store/types";
import { setOrderCustomer } from "../../store/order";
import { FormCustomer } from "../../containers/Order/FormCustomer";
import { clearCustomers, fetchCustomers } from "../../store/customer";
import { customerOptions } from "../../store/selector/customer";

export const CustomerInput = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [modalVisible, setModalVisible] = useState<string>("");

  const { options, customers, customer, loading } = useAppSelector((state) => {
    return {
      options: customerOptions(state),
      customers: state.customer.list,
      customer: state.order.customer,
      loading: state.customer.loading,
    };
  });

  const onSearch = (searchText: string) => {
    if (!searchText) {
      clearCustomers()(dispatch);
    } else {
      fetchCustomers(searchText)(dispatch);
    }
  };

  const onSelect = (data: string) => {
    const customer = customers.find((o: ICustomer) => o._id === data);
    if (customer) {
      setOrderCustomer(customer)(dispatch);
    }
    setValue("");
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  const setCustomer = (customer: ICustomer) => {
    setModalVisible("");
    setOrderCustomer({ _id: "", _rev: "", ...customer })(dispatch);
  };

  const optionsNotFound = [
    {
      value: "Not Found",
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Not Found
          <Button onClick={(_) => setModalVisible("customer")}>
            &nbsp;ADD NEW
          </Button>
        </div>
      ),
    },
  ];

  const renderItem = ({
    label,
    value,
    details,
  }: {
    label: string;
    value: string;
    details?: string;
  }) => ({
    value: value,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {label}
        {details ? <span>emp. # {details}</span> : null}
      </div>
    ),
  });

  const dropdownItems = (
    options: { label: string; value: string; details?: string }[]
  ): { value: string; label: JSX.Element }[] => {
    const out = options.map((o: ICustomerOption) => renderItem(o));
    out.push({
      label: (
        <div style={{ textAlign: "right" }}>
          <Button onClick={(_) => setModalVisible("customer")}>
            &nbsp;ADD NEW
          </Button>
        </div>
      ),
      value: "",
    });
    return out;
  };

  return (
    <div>
      <AutoComplete
        value={value}
        allowClear={true}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        notFoundContent={loading ? "Loading" : "Not Found"}
        options={
          !options.length && value ? optionsNotFound : dropdownItems(options)
        }
        style={{ width: "100%", verticalAlign: "middle" }}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder=" Customer"
          className="mb15"
          style={{ width: "100%", maxWidth: "400px", minWidth: "100px" }}
        />
      </AutoComplete>
      {modalVisible === "customer" ? (
        <FormCustomer
          {...{
            visible: modalVisible === "customer",
            handleOk: setCustomer,
            customer,
            handleCancel: (_) => setModalVisible(""),
          }}
        />
      ) : null}
    </div>
  );
};
