import { List } from "../../UI/Layout";
import { CloseOutlined } from "@ant-design/icons";
import { negativePaymentsAliases } from "../../../constants/cart";
import { getMoneyString } from "../../../util/helpers";
import type { IPayment, IPaymentType } from "../../../store/types";
import { Button } from "../../UI/Controls/Button";

interface IProps {
  payment: IPayment[];
  paymentTypes: IPaymentType[];
  onRemove?: any;
}

export const PaymentRender = ({
  payment,
  paymentTypes,
  onRemove,
}: IProps): JSX.Element => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={payment}
      renderItem={(item: IPayment) => {
        const {
          type,
          amount,
          employeeNum = "",
          fee = "",
          convenienceFee = "",
          surcharge = "",
          SHFee = "",
          giftCardCode = ""
        } = item;
        const isNegative = negativePaymentsAliases.includes(type);
        const value = !isNegative ? amount : amount * -1;
        const suffix = !isNegative ? "Paid by" : "";
        const paymentType = paymentTypes.find((o: any) => o.id === type);
        const label = paymentType ? paymentType.name : type;
        return (
          <List.Item
            actions={[
              onRemove ? (
                <Button size="sm" type="danger" onClick={() => onRemove(item)}>
                  <CloseOutlined />
                </Button>
              ) : null,
            ]}
          >
            <List.Item.Meta
              title={
                <div>
                  <div>
                    <span>
                      {suffix}&nbsp;{label}:
                    </span>
                    &nbsp;{getMoneyString(value)}
                  </div>
                  {+fee > 0 ? (
                    <div>
                      <span>Fee:</span>&nbsp;{getMoneyString(fee)}
                    </div>
                  ) : null}
                  {+convenienceFee > 0 ? (
                    <div>
                      <span>Convenience Fee:</span>
                      &nbsp;{getMoneyString(convenienceFee)}
                    </div>
                  ) : null}
                  {+surcharge > 0 ? (
                    <div>
                      <span>Surcharge:</span>&nbsp;{getMoneyString(surcharge)}
                    </div>
                  ) : null}
                  {+SHFee > 0 ? (
                    <div>
                      <span>SHFee:</span>&nbsp;{getMoneyString(SHFee)}
                    </div>
                  ) : null}
                </div>
              }
              description={
                [employeeNum ? (
                  <span key="employeeNum">
                    <span>Employee #:&nbsp;</span>
                    <span>{employeeNum}</span>
                  </span>
                ) : null,
                giftCardCode ? (
                  <span key="giftCardCode">
                    <span>Code #:&nbsp;</span>
                    <span>{giftCardCode}</span>
                  </span>
                ) : null]
              }
            />
          </List.Item>
        );
      }}
    />
  );
};
