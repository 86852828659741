import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState, AppDispatch } from "../store";
import type { IPayment } from "./types";
import { getCardType, getSecureCardNumber } from "../util/CardNumbers";
import {POSLink, Dejavoo, OnlineTransactionAPI, CustomerAPI, GiftCardAPI, OrderAPI} from "../util/API";
import { getErrorString } from "../util/helpers";
import { VarType } from "../util/variableTypes";
import { dispatchFailure } from "./notify";
import {balanceSelector, genHash, totalSelector} from "./selector/cart";
import { itemsSelector } from "./selector/cart";
import { getMaskedCardNumber } from "../util/CardNumbers";
import {PrinterModule} from "./IGWExtension";
import {thankyouOrder} from "./order";
import {IOrder} from "./types";


const { isEmptyString, isString } = VarType;

// state and reducers are below

type PaymentState = {
  object: IPayment | null;
  loading: boolean;
  error: string;
  result: any;
};

const initialState: PaymentState = {
  object: null,
  result: null,
  loading: false,
  error: "",
};
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IPayment>): PaymentState => ({
      ...state,
      object: action.payload,
    }),
    reset: (state): PaymentState => ({
      ...state,
      object: null,
      result: null,
    }),
    update: (
      state,
      action: PayloadAction<Partial<IPayment>>
    ): PaymentState => ({
      ...state,
      object: { ...state.object, ...action.payload },
    }),
    start: (): PaymentState => {
      return ({ ...initialState, loading: true, error: "" })
    },
    fail: (state, action: PayloadAction<string>): PaymentState => ({
      ...initialState,
      loading: false,
      error: action.payload,
    }),
    success: (state, action: PayloadAction<any>): PaymentState => ({
      ...state,
      result: action.payload,
      loading: false,
      error: "",
    })
  },
});

const { set, update, reset, start, fail, success } = paymentSlice.actions;
export default paymentSlice.reducer;


/// public methods are below

export const onStartPayment = (state: RootState) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const store = state.store.selected;
    const station = state.store.station;
    const payment = state.payment.object;
    const items = itemsSelector(state);
    const { department = "", paymentEngine = {} } = store || {};
    const { type: processor = "nocharge" } = paymentEngine;
    const { deviceID } = station || {};
    const payload = { department, items, payment, deviceID: "" };
    if (deviceID) {
      payload.deviceID = deviceID;
    }
    OnlineTransactionAPI.sale(payload)
      .then((response: any) => {
        const { result, error, reason, status } = response;
        const okSignal = ["refnum", "transactionId"];
        const transaction = response.transaction || response;
        const isOk = Object.keys(transaction).some((k: string) =>
          okSignal.includes(k.toLowerCase()) ? transaction[k] : false
        );

        if (error || (result && result.toLowerCase() === "error")) {
          const errorDetails = [];
          if (reason && isString(reason) && !isEmptyString(reason)) {
            errorDetails.push(reason);
          } else if (error && isString(error) && !isEmptyString(error)) {
            errorDetails.push(error);
          }
          if (status && isString(status) && !isEmptyString(status)) {
            errorDetails.push(status);
          }
          dispatch(fail(errorDetails.join(" ")));
          dispatchFailure(errorDetails.join(" "))(dispatch);
        } else if (!isOk) {
          dispatch(fail('Unexpected response: missing "refnum"'));
          dispatchFailure('Unexpected response: missing "refnum"')(dispatch);
        } else {
          const cardNumber = getSecureCardNumber(payment.cardNumber);
          const cardType = getCardType(payment.cardNumber);
          const result = {
            cardHolder: payment.cardHolder,
            amount: payment.amount,
            cardNumber,
            cardType,
            type: "creditCard",
            hash: genHash(),
            processor,
            transactions: [{ ...transaction, type: "Sale" }],
          };
          dispatch(success(result));
        }
      })
      .catch((error: any) => {
        const err = getErrorString(error);
        dispatch(fail(err));
        dispatchFailure(err)(dispatch);
      });
  };
};

export const ERROR_TRAN_STATUS =
  "Sorry, no response from server while checking transaction status";

export const setPayment = (payment: IPayment) => {
  return async (dispatch: AppDispatch) => {
    dispatch(set(payment));
  };
};

// state: store, payment, order, auth
export const onStartPaymentDejavoo = (state: RootState) => {
  return async (dispatch: AppDispatch): Promise<string> => {
    dispatch(start());

    const station = state.store.station;
    const payment = state.payment.object;
    const order = {
      store: state.store.selected,
      station: state.store.station,
      user: state.auth.me,
      items: state.order.items,
      payment: state.order.payment,
      totals: totalSelector(state),
    };
    const params = {
      ...station,
      paymentType: "Credit",
      refId: "",
      order,
      amount: payment.amount,
      autoComplete: false,
    };
    const balance = balanceSelector(state);
    const {amount = 0} = payment;
    if (+amount === +balance) {
      params.autoComplete = true;
    }
    const payload = Object.keys(params).reduce((result: any, curr: string) => {
      if (isString(params[curr]) && !isEmptyString(params[curr])) {
        result[curr] = params[curr].trim();
      } else {
        result[curr] = params[curr];
      }
      return result;
    }, {});

    return Dejavoo.start(payload)
      .then((res: any) => {
        const err = getErrorString(res);
        if (err) {
          dispatch(fail(err));
          dispatchFailure(err)(dispatch);
        } else {
          const { requestId } = res;
          if (isString(requestId) && !isEmptyString(requestId)) {
            dispatch(update(res));
            return requestId;
          } else {
            const error = "Missing 'Reference ID' from intial response";
            dispatch(fail(error));
            dispatchFailure(error)(dispatch);
            return '';
          }
        }
      })
      .catch((e: any) => {
        const error = getErrorString(e);
        dispatch(fail(error));
        dispatchFailure(error)(dispatch);
        return '';
      });
  };
};

const dejavooStop = (requestId: string) => {
  if (isString(requestId) && !isEmptyString(requestId)) {
    Dejavoo.stop(requestId)
      .then((response: any) => {
        console.log("STOP DEJAVOO STATION JOBS", response);
      })
      .catch((e: any) => {
        const error = getErrorString(e);
        console.log("STOP DEJAVOO STATION JOBS", error);
      });
  }
};

const dejavooSaveResult = (result: string, state: RootState) => {
  return async (dispatch: AppDispatch) => {
    console.warn("DEJAVOO SAVED", result);
    try {
      const transaction = JSON.parse(result);
      const { resultCode, respMSG, message, _id } = transaction;
      if (_id) {
        const merchantConfig = state.merchant.object;
        OrderAPI.fetch(_id)
          .then((order: IOrder) => {
            const newOrder = {
              ...order
            };
            if (order.giftCard) {
              const { payment } = order;
              const usedGiftCard = payment.find(
                (o: IPayment) => o.type === "giftCard"
              );
              GiftCardAPI.saveOne({
                id: order.giftCard._id,
                balance: usedGiftCard.balance,
              }).catch((e) => {
                const err = getErrorString(e);
                console.log({ err });
              });
            }
            const customer = state.order.customer;
            if (customer && customer.lastName && !customer._id) {
              const newCustomer = { ...customer };
              delete newCustomer._id;
              delete newCustomer._rev;
              CustomerAPI.create(newCustomer)
                .then((res) => {
                  const err = getErrorString(res);
                  if (!err) {
                    newCustomer._id = res._id;
                    newCustomer._rev = res._rev;
                    newCustomer.dateCreated = res.dateCreated;
                    newOrder.customer = newCustomer;
                    OrderAPI.save(newOrder)
                      .then((_) => {
                      try {
                        PrinterModule.send(newOrder, merchantConfig)(dispatch);
                      } catch (e) {
                        console.log({e});
                      }
                        thankyouOrder(newOrder)(dispatch);
                      })
                      .catch((e) => {
                        const err = getErrorString(e);
                        console.log({ err });
                      });
                  } else {
                    console.log({ createCustomerError: err });
                  }
                })
                .catch((error) => {
                  console.log({ createCustomerError: getErrorString(error) });
                });
            } else {
              try {
                PrinterModule.send(newOrder, merchantConfig)(dispatch);
              } catch (e) {
                console.log({e});
              }
              thankyouOrder(newOrder)(dispatch);
            }
            dispatch(success({}));
          }).catch((e: any) => {
            const error = getErrorString(e);
            console.error("DEJAVOO FAIL", error);
            dispatch(fail(error));
            dispatchFailure(error)(dispatch);
            return true;
          });
      } else {
        if (
          +resultCode === 0 &&
          message &&
          ["approved", "success"].includes(message.toLowerCase())
        ) {
          const {
            cardType,
            totalAmt,
            name,
            acntLast4,
            acntFirst4,
            SVC,
            fee,
            SHFee,
          } = transaction;
          const cardNumber = getMaskedCardNumber(
            acntFirst4,
            acntLast4
          );
          const cartTypeByNumber = getCardType(cardNumber);
          const resultPayment = {
            hash: genHash(),
            source: "device",
            type: "creditCard",
            processor: "dejavoo",
            amount: totalAmt,
            cardType: transaction.cardType,
            cardHolder: name,
            cardNumber: cardNumber,
            surcharge: SVC,
            fee,
            SHFee,
            convenienceFee: transaction.convenienceFee,
            transactions: [
              {
                ...transaction,
                type: "Sale",
                cardType: cartTypeByNumber || cardType,
              },
            ],
          };
          dispatch(success(resultPayment));
        } else {
          const error = respMSG || message || ERROR_TRAN_STATUS;
          dispatch(fail(error));
          dispatchFailure(error)(dispatch);
        }
      }
    } catch (e) {
      const error = getErrorString(e);
      dispatch(fail(error));
      dispatchFailure(error)(dispatch);
    }
  }
};

export const onCheckDejavoo = (requestId: string, state: RootState) => {
  return async (dispatch: AppDispatch): Promise<boolean> => {
    console.log("DEJAVOO", requestId);
    return Dejavoo.check(requestId)
      .then((res: any) => {
        const err = getErrorString(res);
        if (err) {
          console.error("DEJAVOO ERROR", err);
          dispatch(fail(err));
          dispatchFailure(err)(dispatch);
          dejavooStop(requestId);
          return true;
        } else {
          const {
            requestId: refId = "",
            status = "",
            result = "",
            message = "",
          } = res;
          if (refId !== requestId) {
            const error = `Mismatch 'Reference ID': received ${refId} but expected ${requestId}`;
            dispatch(fail(error));
            dispatchFailure(err)(dispatch);
            dejavooStop(requestId);
            return true;
          } else {
            dispatch(update(res));
            if (status == "Processing terminated") {
              console.log("DEJAVOO RES message=", message, "status=", status, "result is", typeof result);
              if (isString(result) && !isEmptyString(result)) {
                console.warn("DEJAVOO HAVE RESULT", result);
                dejavooSaveResult(result, state)(dispatch);
              } else {
                console.log("DEJAVOO NO RESULT", message);
                dispatch(fail(message));
                dispatchFailure(message)(dispatch);
              }
              return true
            } else {
              console.log("DEJAVOO status=", status);
            }
          }
          return false;
        }
      })
      .catch((e: any) => {
        const error = getErrorString(e);
        console.error("DEJAVOO FAIL", error);
        dispatch(fail(error));
        dispatchFailure(error)(dispatch);
        return true;
      });
  }
};

export const onStartPaymentPoslink = (state: RootState) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const station = state.store.station;
    const payment = state.payment.object;
    const order = {
      store: state.store.selected,
      station: state.store.station,
      user: state.auth.me,
      items: state.order.items,
      payment: state.order.payment,
      totals: totalSelector(state),
    };
    const params = { ...station, order, amount: payment.amount };
    const payload = Object.keys(params).reduce((result: any, curr: string) => {
      if (isString(params[curr]) && !isEmptyString(params[curr])) {
        result[curr] = params[curr].trim();
      } else {
        result[curr] = params[curr];
      }
      return result;
    }, {});

    POSLink.start(payload)
      .then((res: any) => {
        const err = getErrorString(res);
        if (err) {
          dispatch(fail(err));
          dispatchFailure(err)(dispatch);
        } else {
          const { requestId } = res;
          if (isString(requestId) && !isEmptyString(requestId)) {
            dispatch(update(res));
            onWatchPoslink(state)(dispatch);
          } else {
            const error = "Missing 'Reference ID' from response";
            dispatch(fail(error));
            dispatchFailure(error)(dispatch);
          }
        }
      })
      .catch((e: any) => {
        const error = getErrorString(e);
        dispatch(fail(error));
        dispatchFailure(error)(dispatch);
      });
  };
};

const onStopWatchPOSLink = (state: RootState) => {
  return async (dispatch: AppDispatch) => {
    const payment = state.payment.object;
    const { requestId } = payment;
    if (isString(requestId) && !isEmptyString(requestId)) {
      POSLink.stop(requestId)
        .then((response: any) => {
          console.log("STOP POSLINK STATION JOBS", response);
        })
        .catch((e: any) => {
          const error = getErrorString(e);
          console.log("STOP POSLINK STATION JOBS", error);
        });
    }
  };
};

const onWatchPoslink = (state: RootState) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const payment = state.payment.object;
    const { requestId } = payment;

    if (!requestId) {
      const error = `Missing 'Reference ID'`;
      onStopWatchPOSLink(state)(dispatch);
      dispatch(fail(error));
      dispatchFailure(error)(dispatch);
    } else {
      POSLink.check(requestId)
        .then((res: any) => {
          const err = getErrorString(res);
          if (err) {
            dispatch(fail(err));
            dispatchFailure(err)(dispatch);
            onStopWatchPOSLink(state)(dispatch);
          } else {
            const {
              requestId: refId = "",
              status = "",
              result = "",
              message = "",
            } = res;
            if (refId !== requestId) {
              const error = `Mismatch 'Reference ID': received ${refId} but expected ${requestId}`;
              dispatch(fail(error));
              dispatchFailure(error)(dispatch);
              onStopWatchPOSLink(state)(dispatch);
            } else {
              dispatch(update(res));
              if (status === "Processing terminated") {
                onStopWatchPOSLink(state)(dispatch);
                if (isString(result) && !isEmptyString(result)) {
                  try {
                    const transaction = JSON.parse(result);
                    const resultPayment = {
                      hash: genHash(),
                      source: "device",
                      type: "creditCard",
                      processor: "poslink",
                      amount: transaction.amount,
                      cardType: transaction.cardType,
                      cardHolder: transaction.cardHolder,
                      cardNumber: transaction.cardNumber,
                      convenienceFee: transaction.convenienceFee,
                      transactions: [{ ...transaction, type: "Sale" }],
                    };
                    dispatch(success(resultPayment));
                  } catch (e) {
                    const error = getErrorString(e);
                    dispatch(fail(error));
                    dispatchFailure(error)(dispatch);
                  }
                } else {
                  dispatch(fail(message));
                  dispatchFailure(message)(dispatch);
                }
              } else {
                setTimeout(() => onWatchPoslink(state)(dispatch), 4000);
              }
            }
          }
        })
        .catch((e: any) => {
          onStopWatchPOSLink(state)(dispatch);
          const error = getErrorString(e);
          dispatch(fail(error));
          dispatchFailure(error)(dispatch);
        });
    }
  };
};

export const resetPayment = () => {
  return async (dispatch: AppDispatch) => {
    console.info('resetPayment');
    dispatch(reset());
  }
};
