import { Layout } from "../UI/Layout";
import { Button } from "../UI/Controls/Button";
import { useLocation } from "react-router-dom";

export const NotFound = (): JSX.Element => {
  const pathname = useLocation();
  return (
    <Layout>
      <div
        className="form-container"
        style={{ marginTop: "calc(100vh/2 - 300px)" }}
      >
        <div className="form-content page-not-found" style={{ width: "500px" }}>
          <h1 className="title">404</h1>
          <div className="desc">PAGE NOT FOUND</div>
          <pre>{JSON.stringify(pathname)}</pre>
          <Button to="/" className="mt15 go-back-btn" label="Back" />
        </div>
      </div>
    </Layout>
  );
};
