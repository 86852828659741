import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../hooks";
import {
  scanner,
  registerReceiver,
  sendNextInQueue,
} from "../store/IGWExtension";

export const IGWExtension = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { igwExtension } = useAppSelector(({ igwExtension }) => ({
    igwExtension,
  }));

  useEffect(() => {
    registerReceiver()(dispatch);
    scanner()(dispatch);
  }, []);

  useEffect(() => {
    // trigger next in queue once we received something from the extension
    sendNextInQueue(igwExtension)(dispatch);
  }, [igwExtension.queueStatus]);

  return null;
};
