import { Skeleton } from "../../components/UI/Layout";
import type { IStore } from "../../store/types";

interface IProps {
  loading: boolean;
  list: IStore[] | [];
  onSelect: Function;
}

const BrowserStores = ({
  loading,
  list = [],
  onSelect,
}: IProps): JSX.Element => {
  return loading ? (
    <Skeleton active />
  ) : (
    <div className="cards">
      {list.map((item: IStore, i: number) => {
        const {
          name,
          address: {
            zip = "",
            state = "",
            city = "",
            line1 = "",
            line2 = "",
          } = {},
        } = item || {};
        const address = (
          [zip, state, city, line1, line2].filter((n: string) => n) || []
        ).join(", ");
        return (
          <div key={i} className="cards__card" onClick={() => onSelect(item)}>
            <div>{name}</div>
            <div>{address}</div>
          </div>
        );
      })}
    </div>
  );
};

export default BrowserStores;
