import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import type { IUser } from "./types";
import { type ParamId, UserAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type UserState = {
  object?: IUser;
  loading: boolean;
  error: string;
};

const initialState: UserState = {
  object: undefined,
  loading: false,
  error: "",
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    start: (): UserState => ({ object: undefined, loading: true, error: "" }),
    fail: (state, action: PayloadAction<string>): UserState => ({
      object: undefined,
      loading: false,
      error: action.payload,
    }),
    success: (state, action: PayloadAction<IUser>): UserState => ({
      object: action.payload,
      loading: false,
      error: "",
    }),
  },
});

const { fail, success, start } = userSlice.actions;
export default userSlice.reducer;

// methods for public usage are below

export const fetchUser = (id: ParamId) => {
  return async (dispatch: AppDispatch): Promise<any> => {
    dispatch(start());
    UserAPI.fetchOne(id)
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          dispatch(success(response));
        } else {
          dispatch(fail(err));
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(fail(err));
      });
  };
};
