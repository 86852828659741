import { useAppSelector, useAppDispatch } from "../../hooks";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { HomeOutlined, CloseOutlined } from "@ant-design/icons";
import { isString, isEmptyString } from "../../util/variableTypes";
import { deleteOrderCustomer } from "../../store/order";
import { CustomerInput } from "./CustomerInput";
import { Button } from "../UI/Controls/Button";
import { IAddress } from "../../store/types";

export const OrderCustomer = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { customer } = useAppSelector((state) => {
    return {
      customer: state.order.customer,
    };
  });

  const noAddress: IAddress = { zip: "", state: "", city: "", line1: "" };
  const {
    employeeNum,
    employeeBarcode,
    phones,
    emails,
    address: addressObject = noAddress,
  } = customer;
  const {
    zip = "",
    state = "",
    city = "",
    line1 = "",
    line2 = "",
  } = addressObject;
  const name = customer
    ? [customer.firstName, customer.lastName].filter((o: string) => o).join(" ")
    : "";
  const address = (
    [zip, state, city, line1, line2].filter((n: string) => n) || []
  ).join(", ");
  return (
    <div
      style={{
        background: "#eff2f5",
        width: "100%",
        zIndex: 2,
      }}
    >
      <CustomerInput />
      {customer && name ? (
        <div
          className="flexFlowRow"
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ padding: "10px 5px 0 5px", lineHeight: "18px" }}>
            <b>{name}</b>
            {employeeNum ? (
              <div style={{ fontSize: "smaller" }}>
                Employee # <span className="attention">{employeeNum}</span>
              </div>
            ) : null}
            {employeeBarcode ? (
              <div style={{ fontSize: "smaller" }}>
                Barcode <span className="attention">{employeeBarcode}</span>
              </div>
            ) : null}
            {emails && Array.isArray(emails) && emails.length ? (
              <div style={{ fontSize: "smaller", whiteSpace: "nowrap" }}>
                <MailOutlined />
                &nbsp;
                {emails.map((m) => (
                  <a key={m} href={`mailto:${m}`}>
                    {m}
                  </a>
                ))}
              </div>
            ) : null}
            {phones && Array.isArray(phones) && phones.length ? (
              <div style={{ fontSize: "smaller", whiteSpace: "nowrap" }}>
                <PhoneOutlined />
                &nbsp;
                {phones.map((m) => (
                  <a key={m} href={`tel:${m}`}>
                    {m}
                  </a>
                ))}
              </div>
            ) : null}
          </div>
          <div style={{ padding: "10px 5px 0 5px", lineHeight: "18px" }}>
            {isString(address) && !isEmptyString(address) ? (
              <div style={{ fontSize: "smaller" }}>
                <HomeOutlined />
                &nbsp;
                <span>{address}</span>
              </div>
            ) : null}
          </div>
          <Button
            style={{ margin: "10px 0 0 5px" }}
            onClick={(_) => deleteOrderCustomer()(dispatch)}
          >
            <CloseOutlined />
          </Button>
        </div>
      ) : null}
    </div>
  );
};
