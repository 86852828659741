import { useAppDispatch } from "../../../hooks";
import { Statistic, Card, Row } from "../../UI/Layout";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "../../UI/Controls/Button";
import { removeOrderGiftCard } from "../../../store/order";

interface IProps {
  code: string;
  balance: number;
}

export const GiftCard = ({ code = "", balance = 0 }: IProps): JSX.Element => {
  const onRemove = () => {
    const dispatch = useAppDispatch();
    removeOrderGiftCard()(dispatch);
  };

  return (
    <Card
      title="Gift Card"
      style={{ marginTop: "15px" }}
      extra={
        <Button size="sm" type="danger" onClick={onRemove}>
          <CloseOutlined />
        </Button>
      }
    >
      <Row>
        <Statistic
          precision={0}
          style={{ flex: 1 }}
          title="Code"
          value={`${code.substr(0, 5)}****${code.substr(-2)}`}
          valueStyle={{ color: "#3f8600" }}
        />
        <Statistic
          prefix="$"
          precision={2}
          title="Balance"
          style={{ flex: 1 }}
          value={balance + ""}
          valueStyle={{ color: "#cf1322" }}
        />
      </Row>
    </Card>
  );
};
