import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";

type SiderMode = "view" | "";

type SiderState = {
  mode: SiderMode;
  collapsed: boolean;
};
const initialState: SiderState = { mode: "view", collapsed: false };

const siderSlice = createSlice({
  name: "sider",
  initialState,
  reducers: {
    mode: (state, action: PayloadAction<SiderMode>) => {
      return { ...state, mode: action.payload };
    },
    collapsed: (state, action: PayloadAction<boolean>) => {
      return { ...state, collapsed: action.payload, mode: "view" };
    },
  },
});

export const { mode, collapsed } = siderSlice.actions;
export default siderSlice.reducer;

// methods for public usage are below

export const setSiderMode = (m: SiderMode) => {
  return (dispatch: AppDispatch) => dispatch(mode(m));
};

export const setSiderCollapsed = (state: boolean) => {
  return (dispatch: AppDispatch) => dispatch(collapsed(state));
};
