export const getErrorString = (response: any): string => {
  let res = "";
  const data = response.body || response;
  if (data) {
    const level1 = ["statusText", "statusMessage", "errors", "error", "reason"];
    level1.forEach((field1) => {
      if (typeof data[field1] === "string") {
        res = data[field1];
      } else if (data[field1] && typeof data[field1] === "object") {
        if (data[field1].message) {
          res = data[field1].message;
        } else if (data[field1].reason) {
          res = data[field1].reason;
        }
      }
    });
  }
  if (res === "null value in entry: reason=null") {
    return "Please try again. Reason: value is null";
  }
  return res;
};

export const groupArrByKey = (arr: any, key: string, altKey: string = "") => {
  if (!arr.length) return {};
  return arr.reduce((acc: any, k: any) => {
    acc[k[key] || k[altKey]] = (acc[k[key] || k[altKey]] || []).concat(k);
    return acc;
  }, {});
};

export const isDigit = (value: any) =>
  !isNaN(value) ? !!/\d/.test(value) : false;
export const fixedTo2 = (value: any) =>
  isDigit(parseFloat(value).toFixed(2)) ? parseFloat(value).toFixed(2) : "0.00";

export const getMoneyString = (value: any) => {
  const options = { style: "currency", currency: "USD" };
  const num =
    parseFloat(value) >= 0 ? fixedTo2(value) : fixedTo2(Math.abs(value));
  const numUSD = new Intl.NumberFormat("en-US", options).format(+num);
  const amount = parseFloat(value) >= 0 ? numUSD : ` (${numUSD})`;
  return isDigit(value) ? amount : "$0.00";
};

export const ArrayGrouper = (src: any, key = "") => {
  const array = [...src];
  const res: object[] = [];
  const temp: { [key: string]: any } = {};

  if (!key) {
    array.forEach((item) => {
      if (res.indexOf(item) < 0) res.push(item);
    });
  } else {
    array.forEach((item) => {
      temp[item[key]] = item;
    });
    Object.keys(temp).forEach((itemKey) => res.push(temp[itemKey]));
  }

  return res;
};
