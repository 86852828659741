import { CartItem } from "./CartItem";
import type { ICartItem } from "../../../store/types";

interface IProps {
  items: ICartItem[] | [];
  currentItem?: ICartItem;
  onClickItem?: any;
}

export const CartItems = ({
  items = [],
  currentItem,
  onClickItem,
}: IProps): JSX.Element => {
  return (
    <div className="cart m15">
      {items.map((item: ICartItem, i: number) => {
        const className = [
          "cart__title_lg cart__title_selectable uppercase bold",
        ];
        if (currentItem && currentItem.hash === item.hash) {
          className.push("cart__title_selected");
        } else {
          className.push("cart__title_selectable");
        }
        return (
          <CartItem
            {...{
              key: i,
              classes: className.join(" "),
              item,
              onClickItem: () => onClickItem(item),
            }}
          />
        );
      })}
    </div>
  );
};
