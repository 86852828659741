import type { IProduct } from "../../store/types";
import { ShoppingOutlined } from "@ant-design/icons";

interface IProps {
  item: IProduct | undefined;
  count?: number;
}

export const ProductCard = ({ item, count }: IProps): JSX.Element => {
  const { name, price = 0 } = item || {};
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>{name}</div>
        {count && count > 1 ? (
          <span>
            <ShoppingOutlined /> x {count}
          </span>
        ) : null}
      </div>

      <div>{`$${(+price).toFixed(2)}`}</div>
    </>
  );
};
