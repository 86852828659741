import { createSelector } from "@reduxjs/toolkit";
import { STATIC_CATEGORIES } from "../../constants/api";
import type { ICategory } from "../types";
import type { RootState } from "../../store";

const getPartition = (arr: any, fn: Function) =>
  arr.reduce(
    (acc: any, val: any, i: number, arr: any) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[], []]
  );

const parseStringToUrl = (title: string) =>
  title.replace(/\s+/g, "-").toLowerCase();

const selector = (list: ICategory[] = [], item: ICategory) => {
  return !list.some((el: ICategory) => {
    const { subcategories = [] } = el;
    if (subcategories && subcategories.length) {
      const subs = subcategories
        .filter((o) => !o.productsLength)
        .map((o) => o.title);
      if (subs.includes(item.title) || item.isModifier) {
        return true;
      }
    }
    return item.isModifier;
  });
};

const partCategorySelector = (state: RootState) =>
  getPartition(categoryUrlSelector(state), (item: ICategory) =>
    selector(state.category.list, item)
  );

const checkListByProd = (list: ICategory[]): ICategory[] => {
  return list.filter((o: ICategory) => {
    if (!o.productsLength && !(o.subcategories && o.subcategories.length)) {
      return false;
    }
    if (o.subcategories && o.subcategories.length) {
      return checkListByProd(o.subcategories);
    }
    return true;
  });
};

export const categoryUrlSelector = (state: RootState) => {
  const list = state.category.list;
  const filtered = list.filter((o: ICategory) => {
    if (!o.productsLength && !(o.subcategories && o.subcategories.length)) {
      return false;
    }
    if (o.subcategories && o.subcategories.length) {
      return checkListByProd(o.subcategories);
    }
    return true;
  });
  return filtered.map((o: ICategory) => {
    const out = { ...o };
    if (o.subcategories) {
      out.subcategories = o.subcategories
        .filter((c: ICategory) =>
          filtered.some((f: ICategory) => f.title === c.title)
        )
        .map((s: ICategory) => ({
          ...s,
          urlFull: `c/${parseStringToUrl(o.title)}/sc/${parseStringToUrl(
            s.title
          )}`,
        }));
    }
    return {
      ...out,
      url: parseStringToUrl(o.title),
      urlFull: `c/${parseStringToUrl(o.title)}`,
    };
  });
};

export const catSelector = createSelector(
  partCategorySelector,
  (partCategorySelector) => {
    const categories = partCategorySelector[0].length
      ? STATIC_CATEGORIES.reduce((res: ICategory[], curr: string) => {
        const found = partCategorySelector[0].find(
          (o: ICategory) => o.title.toLowerCase() === curr
        );
        if (found) {
          res.push(found);
        } else {
          res.push({
            title: curr,
            subcategories: [],
            defaultArbitraryItem: false,
            markup: 0,
            image: "",
            url: parseStringToUrl(curr),
            urlFull: `c/${parseStringToUrl(curr)}`,
          });
        }
        return res;
      }, [])
      : [];
    const modifiers = partCategorySelector[1];
    return { categories, modifiers };
  }
);

export const catByUrlSelector = createSelector(categoryUrlSelector, (list) => {
  return list.reduce((result: any, curr: ICategory) => {
    return { ...result, [curr.url || ""]: curr };
  }, {});
});
