import { Modal } from "../../../UI/Layout";
import { Button } from "../../../UI/Controls/Button";
import GiftCardPayment from "../../../../containers/Payment/GiftCardPayment";

interface IProps {
  visible: boolean;
  handleCancel: any;
  disabled: boolean;
}

export const GiftCardModal = ({
  visible,
  handleCancel,
  disabled,
}: IProps): JSX.Element => (
  <Modal
    title="Use Gift Card"
    open={visible}
    okButtonProps={{ disabled }}
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>,
    ]}
  >
    <GiftCardPayment visible={visible} onClose={handleCancel} />
  </Modal>
);
