import { Form, Input, Select, Modal } from "../../components/UI/Layout";
import { states } from "../../constants/states";
import type { ICustomer } from "../../store/types";

interface IProps {
  visible: boolean;
  handleOk: Function;
  customer: ICustomer;
  handleCancel: Function;
}

export const FormCustomer = ({
  visible,
  customer,
  handleOk,
  handleCancel,
}: IProps): JSX.Element => {
  const [form] = Form.useForm();
  return (
    <Modal
      title="New Customer"
      open={visible}
      okText="Add"
      cancelText="Cancel"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleOk(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={() => {
        form.resetFields();
        handleCancel();
      }}
    >
      <Form
        form={form}
        name="card-form"
        layout="vertical"
        autoComplete="off"
        initialValues={{ ...customer }}
      >
        <Form.Item name="businessName" label="Business Name">
          <Input placeholder="Business Name" />
        </Form.Item>
        <Input.Group compact>
          <Form.Item
            name="firstName"
            label="First Name"
            style={{ width: "50%" }}
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            style={{ width: "50%" }}
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Input.Group>
        <Input.Group compact>
          <Form.Item
            name="emails"
            label="E-mail"
            style={{ width: "50%" }}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item name="phones" label="Phone" style={{ width: "50%" }}>
            <Input placeholder="Phone" />
          </Form.Item>
        </Input.Group>
        <Input.Group compact>
          <Form.Item
            name="employeeNum"
            label="Employee #"
            style={{ width: "50%" }}
          >
            <Input placeholder="Employee #" />
          </Form.Item>
          <Form.Item
            name="employeeBarcode"
            label="Employee Barcode"
            style={{ width: "50%" }}
          >
            <Input placeholder="Employee Barcode" />
          </Form.Item>
        </Input.Group>

        <Input.Group compact>
          <Form.Item
            name={["address", "line1"]}
            label="Address Line 1"
            style={{ width: "50%" }}
          >
            <Input placeholder="Address Line 1" />
          </Form.Item>
          <Form.Item
            name={["address", "line2"]}
            label="Address Line 2"
            style={{ width: "50%" }}
          >
            <Input placeholder="Address Line 2" />
          </Form.Item>
          <Form.Item
            name={["address", "city"]}
            label="City"
            style={{ width: "50%" }}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            name={["address", "state"]}
            label="State"
            style={{ width: "50%" }}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select a state"
              options={states}
            />
          </Form.Item>
          <Form.Item name={["address", "zip"]} label="Zip Code">
            <Input placeholder="Zip Code" />
          </Form.Item>
        </Input.Group>
      </Form>
    </Modal>
  );
};
