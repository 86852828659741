declare const window: any;

// 1. `window.` variables are in priority to avoid multiple builds
// 2. the second priority is the `import.meta.env` variables at the time of the build
// 3. the third priority is the local terraform environment

// Typescript wrapper meta variables
interface ImportMeta {
  env: {
    VITE_BUILD: string | null | undefined;
    VITE_MERCHANT: string | null | undefined;
    VITE_API_PUBLIC_BASE_URL: string | null | undefined;
    VITE_API_BASE_URL: string | null | undefined;
    VITE_API_BASE_URL_V2: string | null | undefined;
    VITE_APP_API_BASE_URL_V3: string | null | undefined;
  };
}
const w = (typeof window !== undefined && window != null) ? window : {};
export const MERCHANT =
  w.MERCHANT || import.meta.env.VITE_MERCHANT || "goikos";

export const BUILD = import.meta.env.VITE_BUILD || "";

// Base URL for the static assets
export const PUBLIC_BASE_URL =
  w.API_PUBLIC_BASE_URL ||
  import.meta.env.VITE_API_PUBLIC_BASE_URL ||
  "http://localhost:3080/" + MERCHANT;

//
export const BASE_URL =
  w.API_BASE_URL ||
  import.meta.env.VITE_API_BASE_URL ||
  "http://localhost:3080/api";

export const BASE_V2_URL =
  w.API_BASE_V2_URL ||
  import.meta.env.VITE_API_BASE_URL_V2 ||
  "http://localhost:3080/api/v2/" + MERCHANT;

export const BASE_V3_URL =
  w.API_BASE_V3_URL ||
  import.meta.env.VITE_API_BASE_URL_V3 ||
  "http://localhost:3080/v3/api";

export const getRootUrl = () => {
  return BASE_V3_URL.replace(/\/v3\/api/, "")
}

export const STATIC_CATEGORIES =
  (w.STATIC_CATEGORIES ||
    import.meta.env.VITE_API_STATIC_CATEGORIES ||
    "food,drinks,merchandise").split(",");
