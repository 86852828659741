import { useAppDispatch, useAppSelector } from "../../hooks";
import { Link, Navigate } from "react-router-dom";
import { SignUpForm } from "../../components/SignUp/SignUpForm";
import { signUp } from "../../store/auth";
import { Layout, Header } from "../../components/UI/Layout";

export const SignUp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const merchant = useAppSelector((state) => ({
    object: state.merchant.object,
  }));
  const auth = useAppSelector((state) => ({
    me: state.auth.me,
    loading: state.auth.loading,
  }));

  const handleSubmit = (values: any) => {
    const { email = "", phone = "" } = values;
    signUp({ ...values, login: email || phone })(dispatch);
  };
  if (auth.me && auth.me._id) {
    return <Navigate to="/" />;
  }
  const { object: { title } = {} } = merchant;

  return (
    <Layout>
      <Header>
        <div className="container">
          <Link to="/">{title}</Link>
        </div>
      </Header>
      <div
        className="form-container"
        style={{ marginTop: "calc(100vh/2 - 300px)" }}
      >
        <div className="form-content" style={{ width: "500px" }}>
          <h1 className="form-title">Sign Up</h1>
          <SignUpForm {...{ handleSubmit }} />
          <div className="mt15">
            <span style={{ fontSize: "14px" }}>
              Already have an account?&nbsp;
              <Link to="/login">Login</Link>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};
