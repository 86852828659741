import { configureStore } from "@reduxjs/toolkit";
import {loadState} from "./util/browser-storage";
import { reducer } from "./store/index";

export const store = configureStore({
  reducer,
  preloadedState: loadState(),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
