import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

const paymentSelector = (state: RootState) => state.payment.object || {};

export const checkFormCardSelector = createSelector(
  paymentSelector,
  (payment: any) => {
    const { cardHolder, cardNumber, cvv, expMonth, expYear } = payment;
    return [cardHolder, cardNumber, cvv, expMonth, expYear].every(
      (o: string | number) => o
    );
  }
);
