import { createSelector } from "@reduxjs/toolkit";
import type { ICustomer, ICustomerOption } from "../types";
import type { RootState } from "../../store";

const selector = (state: RootState) => {
  return state.customer.list.reduce(
    (res: ICustomerOption[], curr: ICustomer) => {
      return [
        ...res,
        {
          label: [curr.firstName, curr.lastName].filter((o) => o).join(" "),
          value: curr._id,
          details: curr.employeeNum,
        },
      ];
    },
    []
  );
};

export const customerOptions = createSelector(selector, (list) => list);
