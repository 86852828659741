import { Modal } from "../../../UI/Layout";
import { FormEmployee } from "../../../../containers/Order/FormEmployee";
import { Button } from "../../../UI/Controls/Button";

interface IProps {
  visible: boolean;
  handleOk: any;
  handleCancel: any;
  disabled: boolean;
}

export const PayrollDeductModal = ({
  visible,
  handleOk,
  handleCancel,
  disabled,
}: IProps): JSX.Element => (
  <Modal
    title="Payroll Deduct"
    open={visible}
    onOk={handleOk}
    okButtonProps={{ disabled }}
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        disabled={disabled}
        key="submit"
        type="primary"
        onClick={handleOk}
      >
        <span>&nbsp;Submit</span>
      </Button>,
    ]}
  >
    <FormEmployee hide={["firstName"]} required={["employeeNum"]} />
  </Modal>
);
