import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "../store";
import { CustomerAPI, getAccessToken, getMerchant, UserAPI } from "../util/API";
import { getErrorString } from "../util/helpers";
import type { IUser } from "./types";
import { dispatchFailure } from "./notify";

const STORAGE_ACCESS_TOKEN = "Authorization";
const STORAGE_MERCHANT = "Merchant";
const API_MERCHANT = getMerchant();

type AuthLoginType = {
  username: string;
  password: string;
};

type SignUpType = {
  login: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type AuthState = {
  me: IUser | undefined;
  loading: boolean;
  error: string;
};

const initialState: AuthState = { me: undefined, loading: false, error: "" };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    start: (): AuthState => ({ me: undefined, loading: true, error: "" }),
    fail: (state, action: PayloadAction<string>): AuthState => {
      console.warn("login fail", action.payload);
      return { me: undefined, loading: false, error: action.payload }
    },
    success: (state, action: PayloadAction<IUser | null>): AuthState => {
      console.info("login", action.payload);
      return { me: action.payload, loading: false, error: "" };
    },
  },
});

const { start, fail, success } = authSlice.actions;
export default authSlice.reducer;

export const authCheck = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const token = localStorage.getItem(
      STORAGE_ACCESS_TOKEN + API_MERCHANT.toUpperCase()
    );
    if (!token) {
      dispatch(success(null));
    } else {
      CustomerAPI.verify()
        .then((res) => {
          dispatch(success(res));
        })
        .catch((error) => {
          dispatch(fail(getErrorString(error)));
        });
    }
  };
};

export const signUp = (params: SignUpType) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const { firstName = "", lastName = "" } = params;
    CustomerAPI.signUp({
      first_name: firstName,
      last_name: lastName,
      login: params.login,
      password: params.password,
    })
      .then((data) => {
        const err = getErrorString(data);
        if (!err) {
          if (data.token) {
            localStorage.setItem(
              STORAGE_ACCESS_TOKEN + API_MERCHANT.toUpperCase(),
              data.token
            );
            sessionStorage.setItem(STORAGE_MERCHANT, API_MERCHANT);
          }
          CustomerAPI.verify()
            .then((res) => {
              dispatch(success(res));
            })
            .catch((error) => {
              dispatch(fail(getErrorString(error)));
            });
        } else {
          dispatch(fail(err));
          dispatchFailure(err)(dispatch);
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(fail(err));
        dispatchFailure(err)(dispatch);
      });
  };
};

export const doLogin = (loginParams: AuthLoginType) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const { username = "", password = "" } = loginParams;
    CustomerAPI.login({ login: username, password })
      .then((data) => {
        const err = getErrorString(data);
        if (!err) {
          if (data.token) {
            localStorage.setItem(
              STORAGE_ACCESS_TOKEN + API_MERCHANT.toUpperCase(),
              data.token
            );
            sessionStorage.setItem(STORAGE_MERCHANT, API_MERCHANT);
          }
          CustomerAPI.verify()
            .then((res) => {
              dispatch(success(res));
            })
            .catch((error) => {
              dispatch(fail(getErrorString(error)));
            });
        } else {
          dispatch(fail(err));
          dispatchFailure(err)(dispatch);
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(fail(err));
        dispatchFailure(err)(dispatch);
      });
  };
};

export const doLoginAdmin = (loginParams: AuthLoginType) => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());
    const { username, password } = loginParams;
    UserAPI.authCheck(username, password)
      .then((data) => {
        const err = getErrorString(data);
        if (!err) {
          if (data.authToken) {
            localStorage.setItem(
              STORAGE_ACCESS_TOKEN + API_MERCHANT.toUpperCase(),
              data.authToken
            );
            sessionStorage.setItem(STORAGE_MERCHANT, API_MERCHANT);
          }
          UserAPI.verify()
            .then((response) => {
              const err = getErrorString(response);
              if (!err) {
                dispatch(success(response));
              } else {
                dispatch(fail(err));
                dispatchFailure(err)(dispatch);
              }
            })
            .catch((error) => {
              const err = getErrorString(error);
              dispatch(fail(err));
              dispatchFailure(err)(dispatch);
            });
        } else {
          dispatch(fail(err));
          dispatchFailure(err)(dispatch);
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(fail(err));
        dispatchFailure(err)(dispatch);
      });
  };
};

export const doLogout = () => {
  return async (dispatch: AppDispatch) => {
    localStorage.removeItem(STORAGE_ACCESS_TOKEN + API_MERCHANT.toUpperCase());
    dispatch(success(null));
  };
};

// action that is executed on the very start, when we want 
// to check if we are already logged in
export const doAuthenticate = () => {
  return async (dispatch: AppDispatch) => {
    // DO nothing we do not have token stored
    if (!getAccessToken()) {
      console.info("please authenticate")
      return;
    }

    UserAPI.verify()
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          dispatch(success(response));
        } else {
          // if we failed with user, try it with customer
          CustomerAPI.verify()
            .then((res) => {
              const err = getErrorString(res);
              if (!err) {
                dispatch(success(res));
              } else {
                dispatch(fail(err));
              }
            })
            .catch((error) => {
              const err = getErrorString(error);
              dispatch(fail(err));
            });
        }
      })
      .catch((_) => {
        // const err = getErrorString(error);
        CustomerAPI.verify()
          .then((res) => {
            const err = getErrorString(res);
            if (!err) {
              dispatch(success(res));
            } else {
              dispatch(fail(err));
            }
          })
          .catch((error) => {
            const err = getErrorString(error);
            dispatch(fail(err));
          });
      });
  };
};
