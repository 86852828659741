export const states = [
  { value: "", label: "Select State..." },
  { value: "AB", label: "ALBERTA" },
  { value: "AK", label: "ALASKA" },
  { value: "AL", label: "ALABAMA" },
  { value: "AR", label: "ARKANSAS" },
  { value: "AS", label: "AMERICAN SAMOA" },
  { value: "AZ", label: "ARIZONA" },
  { value: "BC", label: "BRITISH COLUMBIA" },
  { value: "CA", label: "CALIFORNIA " },
  { value: "CO", label: "COLORADO" },
  { value: "CT", label: "CONNECTICUT" },
  { value: "DC", label: "DISTRICT OF COLUMBIA" },
  { value: "DE", label: "DELAWARE" },
  { value: "FL", label: "FLORIDA" },
  { value: "FM", label: "FEDERATED STATES OF MICRONESIA" },
  { value: "GA", label: "GEORGIA" },
  { value: "GU", label: "GUAM" },
  { value: "HI", label: "HAWAII" },
  { value: "IA", label: "IOWA" },
  { value: "ID", label: "IDAHO" },
  { value: "IL", label: "ILLINOIS" },
  { value: "IN", label: "INDIANA" },
  { value: "KS", label: "KANSAS" },
  { value: "KY", label: "KENTUCKY" },
  { value: "LA", label: "LOUISIANA" },
  { value: "MA", label: "MASSACHUSETTS" },
  { value: "MB", label: "MANITOBA" },
  { value: "MD", label: "MARYLAND" },
  { value: "ME", label: "MAINE" },
  { value: "MH", label: "MARSHALL ISLANDS" },
  { value: "MI", label: "MICHIGAN" },
  { value: "MN", label: "MINNESOTA" },
  { value: "MO", label: "MISSOURI" },
  { value: "MP", label: "NORTHERN MARIANA ISLANDS" },
  { value: "MS", label: "MISSISSIPPI" },
  { value: "MT", label: "MONTANA" },
  { value: "NB", label: "NEW BRUNSWICK" },
  { value: "NC", label: "NORTH CAROLINA" },
  { value: "ND", label: "NORTH DAKOTA" },
  { value: "NE", label: "NEBRASKA" },
  { value: "NH", label: "NEW HAMPSHIRE" },
  { value: "NJ", label: "NEW JERSEY" },
  { value: "NL", label: "NEWFOUNDLAND" },
  { value: "NM", label: "NEW MEXICO" },
  { value: "NS", label: "NOVA SCOTIA" },
  { value: "NV", label: "NEVADA" },
  { value: "NY", label: "NEW YORK" },
  { value: "OH", label: "OHIO" },
  { value: "OK", label: "OKLAHOMA" },
  { value: "ON", label: "ONTARIO" },
  { value: "OR", label: "OREGON" },
  { value: "PA", label: "PENNSYLVANIA" },
  { value: "PE", label: "PRINCE EDWARD ISLAND" },
  { value: "PR", label: "PUERTO RICO" },
  { value: "PW", label: "PALAU" },
  { value: "QC", label: "QUEBEC" },
  { value: "RI", label: "RHODE ISLAND" },
  { value: "SC", label: "SOUTH CAROLINA" },
  { value: "SD", label: "SOUTH DAKOTA" },
  { value: "SK", label: "SASKATCHEWAN" },
  { value: "TN", label: "TENNESSEE" },
  { value: "TX", label: "TEXAS" },
  { value: "UT", label: "UTAH" },
  { value: "VA", label: "VIRGINIA" },
  { value: "VI", label: "VIRGIN ISLANDS" },
  { value: "VT", label: "VERMONT" },
  { value: "WA", label: "WASHINGTON" },
  { value: "WI", label: "WISCONSIN" },
  { value: "WV", label: "WEST VIRGINIA" },
  { value: "WY", label: "WYOMING" },
];
