import styled from "styled-components";
import { useAppSelector } from "../../../../hooks";
import { DeleteOutlined, HourglassOutlined } from "@ant-design/icons";
import { Button } from "../../../UI/Controls/Button";

interface IProps {
  onSave: Function;
  onReset: Function;
}

const ButtonOnHold = styled(Button)`
  &:hover {
    border-color: #1890ff;
    background: #1890ff;
  }
`;

export const CartTopButton = ({ onSave, onReset }: IProps): JSX.Element => {
  const { auth } = useAppSelector((state) => {
    return {
      auth: state.auth.me,
    };
  });
  return (
    <div className="site-button-ghost-wrapper">
      <ButtonOnHold
        disabled={auth && auth.role && auth.role === "customer"}
        type="link"
        className="ml15"
        size="lg"
        onClick={onSave}
      >
        <HourglassOutlined style={{ fontSize: "22px" }} />
        <span>&nbsp;On Hold</span>
      </ButtonOnHold>
      <Button type="danger" className="mr15 fright" size="lg" onClick={onReset}>
        <DeleteOutlined style={{ fontSize: "22px" }} />
        <span>&nbsp;Clear</span>
      </Button>
    </div>
  );
};
