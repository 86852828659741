import { useEffect } from "react";
import { useAppDispatch } from "../../hooks";
import { notification } from "antd";
import type { INotification } from "../../store/types";
import { deleteNotification } from "../../store/notify";

interface IGlobalNotificationsProps {
  notifications: INotification[] | [];
}

export const GlobalNotifications = ({
  notifications = [],
}: IGlobalNotificationsProps): JSX.Element => {
  const [notificationApi, notificationContext] = notification.useNotification();
  const dispatch = useAppDispatch();

  useEffect(() => {
    notifications.forEach(({ key, type, message, placement = "topRight" }) => {
      const config = {
        key,
        message,
        placement,
        duration: 7,
        onClose: () => deleteNotification(key)(dispatch),
      };

      switch (type) {
        case "success":
          notificationApi.success(config);
          break;
        case "info":
          notificationApi.info(config);
          break;
        case "warning":
          notificationApi.warning(config);
          break;
        case "error":
          notificationApi.error(config);
          break;
        default:
          notificationApi.open(config);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, dispatch, notificationApi]);

  return <div className="notifications-container">{notificationContext}</div>;
};
