import moment from "moment";
import { Card, DatePicker, Form, Input } from "../../components/UI/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setPayment } from "../../store/payment";

export const FormCard = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { payment = { amount: 0 } } = useAppSelector((state) => ({
    payment: state.payment.object,
  }));

  const onFormChange = (fieldsValue: any) => {
    if (fieldsValue["month-picker"]) {
      const date = fieldsValue["month-picker"].format("MM/YYYY");
      const [expMonth, expYear] = date.split("/");
      setPayment({ ...payment, expMonth: +expMonth, expYear: +expYear })(
        dispatch
      );
    } else {
      setPayment({ ...payment, ...fieldsValue })(dispatch);
    }
  };

  const disabledDate = (current: any) => {
    return current && current < moment().endOf("day");
  };

  return (
    <Card bordered={false}>
      <Form
        layout="vertical"
        name="card-form"
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Form.Item label="Card Number">
          <Form.Item
            name="cardNumber"
            noStyle
            rules={[{ required: true, message: "Card Number is required" }]}
          >
            <Input placeholder="Please input Card Number" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Card Holder">
          <Form.Item
            name="cardHolder"
            noStyle
            rules={[{ required: true, message: "Card Holder is required" }]}
          >
            <Input placeholder="Please input Card Holder" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Expiry date">
          <Form.Item
            name="month-picker"
            noStyle
            rules={[{ required: true, message: "Card Holder is required" }]}
          >
            <DatePicker
              picker="month"
              format="MM/YYYY"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Security Code" style={{ marginBottom: 0 }}>
          <Form.Item
            name="cvv"
            noStyle
            rules={[{ required: true, message: "CVV required" }]}
          >
            <Input style={{ width: 160 }} placeholder="Please input CVV" />
          </Form.Item>
        </Form.Item>
      </Form>
    </Card>
  );
};
