import { Skeleton } from "../../components/UI/Layout";
import { ProductCard } from "./ProductCard";
import type { ICartItem, IProduct } from "../../store/types";

interface IProps {
  view?: string;
  loading: boolean;
  list: IProduct[] | [];
  items?: ICartItem[] | [];
  onSelect: any;
}

export const BrowserProductCards = ({
  loading,
  items = [],
  view = "group",
  list = [],
  onSelect,
}: IProps): JSX.Element => {
  return loading ? (
    <Skeleton active />
  ) : (
    <div className={`card card-${view}`}>
      {list.length ? (
        list.map((item: IProduct, i: number) => {
          const { _id = "" } = item || { _id: "" };
          const existItem = items
            ? items.find((o: ICartItem) => o._id === _id)
            : null;
          return (
            <div
              key={i}
              className={`card__item ${existItem ? "card__item-selected" : ""}`}
              onClick={() => onSelect(item)}
            >
              <ProductCard
                item={item}
                count={existItem ? existItem.quantity : 0}
              />
            </div>
          );
        })
      ) : (
        <div style={{ color: "#999" }}>Loaded 0 items</div>
      )}
    </div>
  );
};
