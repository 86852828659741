import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IBreadcrumb } from "./types";
import type { AppDispatch } from "../store";

type BreadcrumbState = {
  list: IBreadcrumb[];
};

const initialState: BreadcrumbState = { list: [] };
const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IBreadcrumb[]>) => {
      return { list: action.payload };
    },
  },
});
const { set } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;

// methods for public usage are below

export const setBreadcrumbs = (breadcrumb: IBreadcrumb[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(set(breadcrumb));
  };
};
