import { createSelector } from "@reduxjs/toolkit";
import { paymentTypesIcons } from "../../constants/cart";
import type { RootState } from "../../store";

const typesSelector = (state: RootState) => {
  const store = state.store.selected || { paymentTypes: [] };
  const { paymentTypes = [] } = store;
  return paymentTypes
    .filter((o) => o.isActive && o.id !== "customerPoints")
    .map((o) => ({ ...o, icon: paymentTypesIcons[o.id] || "" }));
};

export const paymentTypesSelector = createSelector(
  typesSelector,
  (paymentTypes) => {
    return paymentTypes;
  }
);
