import { type ChangeEvent, type FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Link, Navigate } from "react-router-dom";
import { Layout, Header } from "../../components/UI/Layout";
import { doLogin } from "../../store/auth";
import { LoginForm } from "../../components/Login/LoginForm";

export const Login = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const merchant = useAppSelector((state) => ({
    object: state.merchant.object,
  }));
  const auth = useAppSelector((state) => ({
    me: state.auth.me,
    loading: state.auth.loading,
  }));
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    if (inputName === "username") {
      setUsername(inputValue);
    }
    if (inputName === "password") {
      setPassword(inputValue);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    doLogin({ username, password })(dispatch);
  };

  if (auth.me && auth.me._id) {
    return <Navigate to={{ pathname: "/" }} />;
  }

  const { object: { title } = {} } = merchant;

  return (
    <Layout>
      <Header>
        <div className="container">
          <Link to="/">{title}</Link>
        </div>
      </Header>
      <div
        className="form-container"
        style={{ marginTop: "calc(100vh/2 - 300px)" }}
      >
        <div className="form-content" style={{ width: "500px" }}>
          <h1 className="form-title">Login</h1>
          <LoginForm
            {...{ handleSubmit, handleInputChange, username, password }}
          />
          <div className="mt15">
            <span style={{ fontSize: "14px" }}>
              Don't have an account yet?&nbsp;
              <Link to="/signup">Register now</Link>
            </span>
          </div>
          <div className="mt15">
            <span style={{ fontSize: "14px" }}>
              IGW employees&nbsp;
              <Link to="/admin">sign in here</Link>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};
