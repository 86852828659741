import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import type { RootState } from "../../store";
import { ChargeComponent } from "../../components/UI/ChargeComponent";
import { PaymentProcessDetails } from "../../components/Order/Payment/PaymentProcessDetails";
import {
  onStartPaymentDejavoo,
  onCheckDejavoo,
  setPayment,
} from "../../store/payment";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const DejavooPayment = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state); // NEEDS CHECKING

  const {
    station,
    payment = { amount: 0 },
    loading,
  } = useAppSelector((state) => ({
    payment: state.payment.object,
    station: state.store.station,
    result: state.payment.result,
    loading: state.payment.loading,
  }));

  useEffect(() => {
    return () => {
      setPayment(null)(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendingToDevice = loading;
  const disabled = sendingToDevice || !payment || payment.amount == 0;

  const onStartPayment = async () => {
    console.log("onStartPayment", JSON.stringify(payment));

    setPayment({ amount: payment.amount })(dispatch);
    const requestId: string = await onStartPaymentDejavoo(state)(dispatch);
    let done = false;
    while (!done) {
      await timeout(2000);
      done = await onCheckDejavoo(requestId, state)(dispatch);
    }
  };

  return (
    <div>
      <ChargeComponent
        {...{
          sendingToDevice,
          disabled,
          device: station,
          onStart: onStartPayment,
        }}
      />
      <PaymentProcessDetails {...{ ...payment }} />
    </div>
  );
};
