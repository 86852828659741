/**
 * @param {mixed} variable
 * @returns {boolean}
 */

export const isString = (variable: any) => {
  return typeof variable === "string" || variable instanceof String;
};

export const isEmptyString = (variable: any) => {
  return isString(variable) && !variable.trim();
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isNumber = (variable: any) => {
  return typeof variable === "number" || variable instanceof Number;
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isBoolean = (variable: any) => {
  return typeof variable === "boolean";
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isArray = (variable: any) => {
  return Array.isArray(variable);
};

export const isEmptyArray = (variable: any) => {
  return isArray(variable) && !variable.length;
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isObject = (variable: any) => {
  return (
    !isNull(variable) &&
    !isArray(variable) &&
    !isString(variable) &&
    !isNumber(variable) &&
    typeof variable === "object"
  );
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isEmptyObject = (variable: any) => {
  return isObject(variable) && Object.keys(variable).length === 0;
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isNull = (variable: any) => {
  return variable === null;
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isUndefined = (variable: any) => {
  return variable === undefined || typeof variable === "undefined";
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isValue = (variable: any) => {
  return !isUndefined(variable) && !isNull(variable);
};

/**
 * @param {mixed} variable
 * @returns {boolean}
 */
export const isDate = (variable: any) => {
  try {
    return new Date(variable).toString() !== "Invalid Date";
  } catch (_) {
    return false;
  }
};

export const VarType = {
  isString,
  isEmptyString,
  isNumber,
  isBoolean,
  isArray,
  isEmptyArray,
  isObject,
  isEmptyObject,
  isNull,
  isUndefined,
  isValue,
  isDate,
};
