import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { ICartItem, IOrder, INewOrder, IPayment, ICustomer } from "./types";
import {cartItemsFlatSelector, itemsSelector, totalSelector} from "./selector/cart";
import { fixedTo2, groupArrByKey, isDigit } from "../util/helpers";
import { CustomerAPI, GiftCardAPI, OrderAPI } from "../util/API";
import type { IStation, IStore, IUser, IGiftCard } from "./types";
import type { RootState, AppDispatch } from "../store";
import { getErrorString } from "../util/helpers";
import { VarType } from "../util/variableTypes";
import { PrinterModule } from "./IGWExtension";

const { isString, isValue, isArray, isEmptyArray, isObject, isEmptyObject } =
  VarType;

type IOrderMode = "category" | "thankyou";

type ICart = {
  items: ICartItem[]
}

type IHashWrapper = {
  hash: string;
};

const countItemsById = (items: ICartItem[]) => {
  const grouped = groupArrByKey(items, "_id");
  const result = Object.keys(grouped).reduce(
    (result: ICartItem[], key: string) => {
      const items = grouped[key];
      const item = items[0];
      const q = items.reduce((sum: number, obj: ICartItem) => {
        const { quantity = 0 } = obj;
        return sum + quantity;
      }, 0);
      return [...result, { ...item, quantity: q }];
    },
    []
  );
  return result;
};

const checkOrderItemDiscount = (items: ICartItem[]) => {
  const flatItems = cartItemsFlatSelector({ order: { items } });
  return items.reduce((result: ICartItem[], item: ICartItem) => {

    // item.discountedPrice = undefined;
    // item.appliedDiscounts = [];

    const volumeDiscount = findVolumeDiscount(flatItems, item);

    let newItem =
      item.individualDiscount && +item.individualDiscount > 0
        ? applyIndividualDiscount(item)
        : applyVolumeDiscount(item, volumeDiscount);

    const { modifiers = [] } = item;
    if (modifiers && modifiers.length) {
      const newModifiers = modifiers.reduce(
        (result: ICartItem[], item: ICartItem) => {
          // item.discountedPrice = undefined;
          // item.appliedDiscounts = [];
          const volumeDiscount = findVolumeDiscount(flatItems, item);
          const newItem =
            item.individualDiscount && +item.individualDiscount > 0
              ? applyIndividualDiscount(item)
              : applyVolumeDiscount(item, volumeDiscount);
          return [...result, newItem];
        },
        []
      );
      newItem = { ...newItem, modifiers: newModifiers };
    }
    return [...result, newItem];
  }, []);
};

export const getPercentage = (
  amount: number | string,
  percent: number | string
) => {
  if (!(isValue(percent) && isDigit(percent))) return 0;
  if (isValue(amount) && isDigit(amount)) {
    return +(
      (parseFloat(amount + "") / 100) *
      parseFloat(percent + "")
    ).toFixed(2);
  }
  return 0;
};

const getDiscountedAmount = (
  amount: number | string,
  percent: number | string
) => {
  if (!(isValue(percent) && isDigit(percent))) return amount;
  if (isValue(amount) && isDigit(amount)) {
    return +((parseFloat(amount + "") / 100) * (100 - +percent)).toFixed(2);
  }
  return amount;
};

const isAlreadyActive = (dateStart: string) =>
  !dateStart || new Date(dateStart) < new Date();

const isStillActive = (dateEnd: string) =>
  !dateEnd || new Date(dateEnd) >= new Date();

const findVolumeDiscount = (items: ICartItem[], item: ICartItem) => {
  const groupedById = countItemsById(items);
  const commonItem = groupedById.find((o: any) => o._id === item._id);
  const { volumeDiscounts = [], quantity } = commonItem || {};
  if (
    commonItem &&
    quantity &&
    isDigit(quantity) &&
    isArray(volumeDiscounts) &&
    !isEmptyArray(volumeDiscounts)
  ) {
    return volumeDiscounts.reduce((res: any, obj: any) => {
      if (isObject(obj) && !isEmptyObject(obj)) {
        const {
          list,
          type,
          name,
          range: { dateStart = "", dateEnd = "" } = {},
          isActive,
        } = obj;
        if (
          isActive &&
          isAlreadyActive(dateStart) &&
          isStillActive(dateEnd) &&
          list &&
          isArray(list) &&
          !isEmptyArray(list)
        ) {
          const foundVolumeDiscount = list.reduce((result: any, o: any) => {
            if (isObject(o) && !isEmptyObject(o)) {
              const { quantity: reqQty } = o;
              const { quantity: prevQty = 0 } = result;
              if (
                reqQty &&
                isDigit(reqQty) &&
                parseFloat(prevQty) < parseFloat(reqQty)
              ) {
                return parseFloat(reqQty) <= parseFloat(quantity + "")
                  ? o
                  : result;
              }
            }
            return result;
          }, {});
          if (
            foundVolumeDiscount &&
            isObject(foundVolumeDiscount) &&
            !isEmptyObject(foundVolumeDiscount)
          ) {
            const { quantity: prevQty = 0 } = res;
            if (
              parseFloat(prevQty) < parseFloat(foundVolumeDiscount.quantity)
            ) {
              return {
                ...foundVolumeDiscount,
                type,
                name,
                range: obj.range,
                isActive,
              };
            }
          }
        }
      }
      return res;
    }, {});
  }
  return null;
};

const applyVolumeDiscount = (item: ICartItem, volumeDiscount: any) => {
  if (isObject(volumeDiscount) && !isEmptyObject(volumeDiscount)) {
    const { type, discount } = volumeDiscount;
    const { price } = item;
    if (type === "percent") {
      const discountAmount = getPercentage(price, discount);
      const discountedPrice = +getDiscountedAmount(price, discount);
      item.discountedPrice = discountedPrice;
      if (
        parseFloat(price + "") >= parseFloat(discountedPrice + "") &&
        parseFloat(discountAmount + "") > 0
      ) {
        const details = {
          type: "volumeDiscount",
          discountType: "percent",
          discountAmount,
          discountedPrice,
          doc: volumeDiscount,
        };

        const newItem = {...item, appliedDiscounts: [details]};
        return newItem;
      }
    }
    if (
      type === "dollar" &&
      parseFloat(price + "") >= parseFloat(discount) &&
      parseFloat(discount) > 0
    ) {
      item.discountedPrice = discount;
      const discountAmount = fixedTo2(+price - discount);
      const details = {
        type: "volumeDiscount",
        discountType: "dollar",
        discountAmount: discountAmount,
        discountedPrice: discount,
        doc: volumeDiscount,
      };
      item.appliedDiscounts = [details];
      return item;
    }
  }
  return item;
};

const applyIndividualDiscount = (item: ICartItem) => {
  if (item.individualDiscount && +item.individualDiscount > 0) {
    const { individualDiscount, price } = item;
    const discountAmount = getPercentage(price, +individualDiscount);
    const discountedPrice = +getDiscountedAmount(price, +individualDiscount);
    item.discountedPrice = discountedPrice;
    item.price = price;
    if (
      parseFloat(price + "") >= parseFloat(discountedPrice + "") &&
      parseFloat(discountAmount + "") > 0
    ) {
      const details = {
        type: "individualDiscount",
        discountType: "percent",
        discountAmount,
        discountedPrice,
      };

      const newItem = {...item, appliedDiscounts: [details]}

      return newItem;
    }
  }
  return item;
};

const reduceAddOrderItem = (
  state: ICart,
  selectedProduct: any
): ICartItem[] => {
  let isExist = false;

  const list = state.items.map((item: ICartItem) => {
    if (selectedProduct.hash) {
      const foundItem =
        selectedProduct.hash && item.hash === selectedProduct.hash;
      if (foundItem) {
        isExist = true;
      }
      return foundItem
        ? {
          ...item,
          quantity: item.quantity + 1,
          updated: new Date().valueOf(),
        }
        : item;
    }
    return { ...item };
  });
  const items = isExist
    ? list
    : [
      ...list,
      {
        ...selectedProduct,
        originalPrice: +selectedProduct.price,
        quantity: selectedProduct.quantity || 1,
        updated: new Date().valueOf(),
      },
    ];
  return checkOrderItemDiscount(items);
};

const reduceUpdateOrderPrice = (
  state: ICart,
  selectedProduct: IHashWrapper,
  price: number
): ICartItem[] => {
  const items = state.items.map((item: ICartItem) => {
    const foundItem =
      selectedProduct.hash && item.hash === selectedProduct.hash;
    return foundItem ? { ...item, price, updated: new Date().valueOf() } : item;
  });
  return checkOrderItemDiscount(items);
};

const reduceUpdateOrderQuantity = (
  state: ICart,
  selectedProduct: IHashWrapper,
  quantity: number
): ICartItem[] => {
  const items = state.items.map((item: ICartItem) => {
    const foundItem =
      selectedProduct.hash && item.hash === selectedProduct.hash;
    return foundItem
      ? { ...item, quantity, updated: new Date().valueOf() }
      : item;
  });
  return checkOrderItemDiscount(items);
};

const reduceUpdateOrderItem = (
  state: ICart,
  selectedProduct: IHashWrapper,
  field: string,
  value: any
): ICartItem[] => {
  const items = state.items.map((item: ICartItem) => {
    const foundItem =
      selectedProduct.hash && item.hash === selectedProduct.hash;
    return foundItem
      ? { ...item, [field]: value, updated: new Date().valueOf() }
      : item;
  });
  return checkOrderItemDiscount(items);
};

const reduceUpdateOrderModifiers = (
  state: ICart,
  selectedProduct: IHashWrapper,
  modifier: any
): ICartItem[] => {
  const items = state.items.map((item: ICartItem) => {
    if (item.hash === selectedProduct.hash) {
      const { modifiers = [] } = item;
      let isExist = false;
      const list = modifiers.map((el: ICartItem) => {
        const foundItem = el._id === modifier._id;
        if (foundItem) {
          isExist = true;
        }
        return foundItem ? { ...el, quantity: el.quantity + 1 } : el;
      });
      const newModifiers = isExist
        ? list
        : [...list, { ...modifier, quantity: 1 }];
      return {
        ...item,
        modifiers: [...newModifiers],
        updated: new Date().valueOf(),
      };
    }
    return item;
  });
  return checkOrderItemDiscount(items);
};

// ---- PAYMENT HELPERS ---- //
export const reduceAddOrderPayment = (
  state: IOrder,
  object: IPayment
): IPayment[] => {
  let isExist = false;
  const payments = state.payment.map((item: IPayment) => {
    if (object.hash) {
      const foundItem = item.hash && item.hash === object.hash;
      if (foundItem) {
        isExist = true;
      }
      return foundItem
        ? { ...item, amount: +fixedTo2(item.amount + object.amount) }
        : item;
    }
    return { ...item };
  });
  return isExist ? payments : [...payments, { ...object }];
};

// ---- STATE REDUCER ---- //

export type OrderState = IOrder & {
  loading: boolean;
  mode: IOrderMode;
  error: string;
};

const initialState: OrderState = {
  _id: "",
  _rev: "",
  dateCreated: "",
  user: undefined,
  customer: { _id: "", firstName: "", lastName: "" },
  store: undefined,
  station: undefined,
  totals: undefined,
  mode: "category",
  items: [],
  payment: [],
  notes: {},
  giftCard: undefined,
  loading: false,
  error: "",
  tips: 0,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    saveStart: (state, action: PayloadAction<string>): OrderState => ({
      ...state,
      loading: true,
      error: "",
    }),
    saveFail: (state, action: PayloadAction<string>): OrderState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),

    setNote: (state, action: PayloadAction<[string, string]>): OrderState => {
      const key = action.payload[0];
      const value = action.payload[1];
      return { ...state, notes: { ...state.notes, [key]: value } };
    },
    setUser: (state, action: PayloadAction<IUser>): OrderState => ({
      ...state,
      user: action.payload,
    }),
    setCustomer: (state, action: PayloadAction<ICustomer>): OrderState => {
      const obj = { ...action.payload };
      if (obj.phones && isString(obj.phones)) {
        const phones = [];
        phones.push(obj.phones);
        obj.phones = phones;
      }
      if (obj.emails && isString(obj.emails)) {
        const emails = [];
        emails.push(obj.emails);
        obj.emails = emails;
      }
      return { ...state, customer: obj };
    },
    setStore: (state, action: PayloadAction<IStore>): OrderState => ({
      ...state,
      store: action.payload,
    }),
    setStation: (state, action: PayloadAction<IStation>): OrderState => ({
      ...state,
      station: action.payload,
    }),
    setGiftCard: (
      state,
      action: PayloadAction<IGiftCard | null>
    ): OrderState => ({ ...state, giftCard: action.payload }),
    setMode: (state, action: PayloadAction<IOrderMode>): OrderState => ({
      ...state,
      mode: action.payload,
    }),
    setTotals: (state, action: PayloadAction<any>): OrderState => ({
      ...state,
      totals: action.payload,
    }),
    setItems: (state, action: PayloadAction<ICartItem[]>): OrderState => ({
      ...state,
      items: action.payload,
    }),
    setPayment: (state, action: PayloadAction<IPayment[]>): OrderState => ({
      ...state,
      payment: action.payload,
    }),
    setTips: (state, action: PayloadAction<number>): OrderState => ({
      ...state,
      tips: action.payload,
    }),
    setError: (state, action: PayloadAction<string>): OrderState => ({
      ...state,
      error: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>): OrderState => ({
      ...state,
      loading: action.payload,
    }),
    updateCustomer: (state, action: PayloadAction<ICustomer>): OrderState => {
      const object = action.payload;
      if (object.phones && isString(object.phones)) {
        const phones = [];
        phones.push(object.phones);
        object.phones = phones; // THERE was an error initially
      }
      if (object.emails && isString(object.emails)) {
        const emails = [];
        emails.push(object.emails);
        object.emails = emails; // THERE was an error initially
      }
      return { ...state, customer: { ...state.customer, ...action.payload } };
    },
    deleteCustomer: (state): OrderState => ({
      ...state,
      customer: initialState.customer,
    }),
    addItem: (state, action: PayloadAction<ICartItem>): OrderState => {
      const item = action.payload;
      const newItem = reduceAddOrderItem(state, item);
      return { ...state, items: newItem };
    },
    updateItem: (
      state,
      action: PayloadAction<[IHashWrapper, string, any]>
    ): OrderState => {
      const [selectedProduct, field, value] = action.payload;
      const newItem = reduceUpdateOrderItem(
        state,
        selectedProduct,
        field,
        value
      );
      return { ...state, items: newItem };
    },
    updatePrice: (
      state,
      action: PayloadAction<[IHashWrapper, number]>
    ): OrderState => {
      const [selectedProduct, price] = action.payload;
      const newItem = reduceUpdateOrderPrice(state, selectedProduct, price);
      return { ...state, items: newItem };
    },
    updateQuantity: (
      state,
      action: PayloadAction<[any, number]>
    ): OrderState => {
      const [selectedProduct, quantity] = action.payload;
      const newItem = reduceUpdateOrderQuantity(
        state,
        selectedProduct,
        quantity
      );
      return { ...state, items: newItem };
    },
    updateModifiers: (
      state,
      action: PayloadAction<[IHashWrapper, any]>
    ): OrderState => {
      const [product, modifier] = action.payload;
      const newItem = reduceUpdateOrderModifiers(state, product, modifier);
      return { ...state, items: newItem };
    },
    checkItemDiscount: (
      state,
      action: PayloadAction<ICartItem>
    ): OrderState => {
      const item = action.payload;
      const newItem = checkOrderItemDiscount(state.items);
      return { ...state, items: newItem };
    },
    deleteItem: (state, action: PayloadAction<ICartItem>): OrderState => {
      const item = action.payload;
      const filtered = state.items.filter((o) => o.hash !== item.hash) || [];
      const newItems = checkOrderItemDiscount(filtered);
      return {
        ...state,
        items: newItems,
      };
    },
    deleteAllItems: (state, action: PayloadAction<ICartItem>): OrderState => ({
      ...state,
      items: [],
    }),
    addPayment: (state, action: PayloadAction<IPayment>): OrderState => {
      const payment = action.payload;
      const newPayment = reduceAddOrderPayment(state, payment);
      return { ...state, payment: newPayment };
    },
    deletePayment: (state, action: PayloadAction<IPayment>): OrderState => {
      return {
        ...state,
        payment:
          state.payment.filter((o) => o.hash !== action.payload.hash) || [],
      };
    },
    deleteAllPayments: (
      state,
      action: PayloadAction<IPayment>
    ): OrderState => ({ ...state, payment: [] }),
    set: (state, action: PayloadAction<IOrder>): OrderState => {
      const order = action.payload;
      return { ...state, ...order };
    },
    reset: (state, action: PayloadAction<IOrder>): OrderState => ({
      ...initialState,
    }),
    thankyou: (state, action: PayloadAction<IOrder>): OrderState => ({
      ...state,
      ...action.payload,
      mode: "thankyou",
    }),
  },
});

// this is probably the only reducer that makes them public
const {
  saveStart,
  saveFail,
  setNote,
  setUser,
  setCustomer,
  setStore,
  setStation,
  setGiftCard,
  setMode,
  setTotals,
  setItems,
  setPayment,
  setTips,
  setError,
  setLoading,
  updateCustomer,
  deleteCustomer,
  addItem,
  updateItem,
  updatePrice,
  updateQuantity,
  updateModifiers,
  checkItemDiscount,
  deleteItem,
  deleteAllItems,
  addPayment,
  deletePayment,
  deleteAllPayments,
  thankyou,
  set,
  reset,
} = orderSlice.actions;
export default orderSlice.reducer;
export const _actions = orderSlice.actions;

// these little modules are just wrappers around the action types
// which are supposed to remain private

export const setOrder = (order: IOrder) => {
  return async (dispatch: AppDispatch) => {
    dispatch(set(order));
  };
};

export const thankyouOrder = (newOrder: IOrder) => {
  console.log({ newOrder });
  return async (dispatch: AppDispatch) => {
    dispatch(thankyou(newOrder));
  };
};


export const resetOrder = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(reset());
  };
};

export const setOrderStore = (store: IStore) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setStore(store));
  };
};

export const setOrderTips = (tips: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setTips(tips));
  };
};

export const setOrderCustomer = (customer: ICustomer) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setCustomer(customer));
  };
};

export const updateOrderCustomer = (customer: ICustomer) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateCustomer(customer));
  };
};

export const deleteOrderCustomer = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(deleteCustomer());
  };
};

export const addOrderItem = (item: ICartItem) => {
  return async (dispatch: AppDispatch) => {
    dispatch(addItem(item));
  };
};

export const updateOrderModifier = (product: any, modifier: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateModifiers([product, modifier]));
  };
};

export const removeOrderPayment = (payment: IPayment) => {
  return async (dispatch: AppDispatch) => {
    dispatch(deletePayment(payment));
  };
};

export const addOrderPayment = (payment: IPayment) => {
  return async (dispatch: AppDispatch) => {
    dispatch(addPayment(payment));
  };
};

export const setOrderGiftCard = (doc: IGiftCard) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGiftCard(doc));
  };
};

export const removeOrderGiftCard = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setGiftCard(null));
  };
};

export const deleteOrderItem = (item: ICartItem) => {
  return async (dispatch: AppDispatch) => {
    dispatch(deleteItem(item));
  };
};

export const updateOrderItem = (
  item: IHashWrapper,
  field: string,
  value: any
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateItem([item, field, value]));
  };
};

export const saveOrder = (object: INewOrder) => {
  return async (dispatch: AppDispatch) => {
    dispatch(saveStart());
    OrderAPI.save(object)
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          dispatch(set(response));
          dispatch(
            thankyou({
              ...object,
              _id: response.id,
              _rev: response.rev || response._rev,
              dateCreated: response.dateCreated,
            })
          );
        } else {
          dispatch(saveFail(err));
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(saveFail(err));
      });
  };
};

export const deleteOrder = (object: IOrder) => {
  return async (dispatch: AppDispatch) => {
    OrderAPI.remove(object._id, object).catch((error) => {
      const err = getErrorString(error);
      dispatch(saveFail(err));
    });
  };
};

export const completeOrder = (state: RootState) => {
  return async (dispatch: AppDispatch) => {
    // console.log(">> 1")
    // const state = getState();
    // console.log(">> 2")
    const merchantConfig = state.merchant.object;
    const order = {
      status: "ready",
      dateCreated: new Date().toISOString,
      store: state.store.selected,
      station: state.store.station,
      user: state.auth.me,
      customer: state.order.customer,
      items: state.order.items,
      payment: state.order.payment,
      notes: state.order.notes,
      giftCard: state.order.giftCard,
      totals: totalSelector(state),
    };
    dispatch(saveStart());

    OrderAPI.save(order)
      .then((response) => {
        const err = getErrorString(response);
        if (!err) {
          const newOrder = {
            ...order,
            _id: response.id,
            _rev: response.rev,
            dateCreated: response.dateCreated,
          };
          if (order.giftCard) {
            const { payment } = order;
            const usedGiftCard = payment.find(
              (o: IPayment) => o.type === "giftCard"
            );
            GiftCardAPI.saveOne({
              id: order.giftCard._id,
              balance: usedGiftCard.balance,
            }).catch((e) => {
              const err = getErrorString(e);
              console.log({ err });
            });
          }
          const customer = state.order.customer;
          if (customer && customer.lastName && !customer._id) {
            const newCustomer = { ...customer };
            delete newCustomer._id;
            delete newCustomer._rev;
            CustomerAPI.create(newCustomer)
              .then((res) => {
                const err = getErrorString(res);
                if (!err) {
                  newCustomer._id = res._id;
                  newCustomer._rev = res._rev;
                  newCustomer.dateCreated = res.dateCreated;
                  newOrder.customer = newCustomer;
                  OrderAPI.save(newOrder)
                    .then((_) => {
                      PrinterModule.send(newOrder, merchantConfig)(dispatch);
                      dispatch(thankyou(newOrder));
                    })
                    .catch((error) => {
                      dispatch(saveFail(getErrorString(error)));
                    });
                } else {
                  console.log({ createCustomerError: err });
                }
              })
              .catch((error) => {
                console.log({ createCustomerError: getErrorString(error) });
              });
          } else {
            PrinterModule.send(newOrder, merchantConfig)(dispatch);
            dispatch(thankyou(newOrder));
          }
        } else {
          dispatch(saveFail(err));
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(saveFail(err));
      });
  };
};


/// Autocomplete order

export const onStartAutosubmitPayment = (state: RootState) => {
  return async (dispatch: AppDispatch) => {
    dispatch(saveStart());
    const merchantConfig = state.merchant.object;
    const order = {
      status: "ready",
      dateCreated: new Date().toISOString(),
      store: state.store.selected,
      station: state.store.station,
      user: state.auth.me,
      customer: state.order.customer,
      items: state.order.items,
      payment: state.payment.object,
      notes: state.order.notes,
      giftCard: state.order.giftCard,
      totals: totalSelector(state),
    };
    OrderAPI.autosubmit(order)
      .then((response: any) => {
        const err = getErrorString(response);
        if (!err) {
          OrderAPI.fetch(response._id)
            .then((order: IOrder) => {
              const newOrder = {
                ...order
              };
              if (order.giftCard) {
                const { payment } = order;
                const usedGiftCard = payment.find(
                  (o: IPayment) => o.type === "giftCard"
                );
                GiftCardAPI.saveOne({
                  id: order.giftCard._id,
                  balance: usedGiftCard.balance,
                }).catch((e) => {
                  const err = getErrorString(e);
                  console.log({ err });
                });
              }
              const customer = state.order.customer;
              if (customer && customer.lastName && !customer._id) {
                const newCustomer = { ...customer };
                delete newCustomer._id;
                delete newCustomer._rev;
                CustomerAPI.create(newCustomer)
                  .then((res) => {
                    const err = getErrorString(res);
                    if (!err) {
                      newCustomer._id = res._id;
                      newCustomer._rev = res._rev;
                      newCustomer.dateCreated = res.dateCreated;
                      newOrder.customer = newCustomer;
                      OrderAPI.save(newOrder)
                        .then((_) => {
                          PrinterModule.send(newOrder, merchantConfig)(dispatch);
                          dispatch(thankyou(newOrder));
                        })
                        .catch((error) => {
                          dispatch(saveFail(getErrorString(error)));
                        });
                    } else {
                      console.log({ createCustomerError: err });
                    }
                  })
                  .catch((error) => {
                    console.log({ createCustomerError: getErrorString(error) });
                  });
              } else {
                PrinterModule.send(newOrder, merchantConfig)(dispatch);
                dispatch(thankyou(newOrder));
              }
            })
            .catch((e: any) => {
              const error = getErrorString(e);
              dispatch(saveFail(error));
            });
        } else {
          dispatch(saveFail(err));
        }
      })
      .catch((error) => {
        const err = getErrorString(error);
        dispatch(saveFail(err));
      })
  }
}

