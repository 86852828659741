import type { ITotals } from "../../../store/types";
import { fixedTo2 } from "../../../util/helpers";

interface IProps {
  totals: ITotals | undefined;
}

export const CartTotals = ({ totals }: IProps): JSX.Element => {
  const {
    subTotal = 0,
    grandTotal = 0,
    taxTotal = 0,
    discountTotal = 0,
  } = totals;

  if (!(subTotal || grandTotal || taxTotal)) return null;
  const skipSubTotal = subTotal == grandTotal;
  return (
    <div className="text-xs">
      {skipSubTotal ? null : (
        <div className="flexFlowRow flexJCSpaceBetween">
          <div>
            <b>Sub Total</b>
          </div>
          <div className="text-right">${fixedTo2(subTotal)}</div>
        </div>
      )}
      {discountTotal > 0 ? (
        <div className="flexFlowRow flexJCSpaceBetween">
          <div>
            <b>Discount</b>
          </div>
          <div className="text-right">${fixedTo2(discountTotal)}</div>
        </div>
      ) : null}
      {taxTotal > 0 ? (
        <div className="flexFlowRow flexJCSpaceBetween">
          <div>
            <b>Tax</b>
          </div>
          <div className="text-right">${fixedTo2(taxTotal)}</div>
        </div>
      ) : null}
      <div className="text-sm flexFlowRow flexJCSpaceBetween">
        <div>
          <b>Grand Total</b>
        </div>
        <div className="text-right bold">${fixedTo2(grandTotal)}</div>
      </div>
    </div>
  );
};
