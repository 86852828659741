import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { Input, Divider } from "../../components/UI/Layout";
import { Button } from "../../components/UI/Controls/Button";
import { GiftCardAPI } from "../../util/API";
import { getErrorString } from "../../util/helpers";
import { dispatchFailure } from "../../store/notify";
import { setOrderGiftCard } from "../../store/order";

import {
  isArray,
  isEmptyArray,
  isObject,
  isEmptyObject,
  isString,
  isEmptyString,
} from "../../util/variableTypes";

const decodeGiftCode = (code: string) => {
  if (!(isString(code) && !isEmptyString(code))) return "";
  if (![";", "%"].includes(code.trim().substr(0, 1))) {
    if (["=", "?"].includes(code.trim().substr(-1))) {
      return code.trim().slice(0, -1);
    } else {
      return code.trim();
    }
  } else {
    const parts = code
      .trim()
      .substr(1)
      .split(code.trim().includes("=") ? "=" : "?");
    return parts[0].trim();
  }
};

const hide = {
  boxShadow: "none",
  display: "block",
  outline: "none",
  fontSize: "1px",
  color: "white",
  border: 0,
  margin: 0,
  padding: 0,
};

interface IProps {
  onClose: Function;
  visible: boolean;
}

const GiftCardPayment = ({ visible, onClose }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const [swipedData, setSwipedData] = useState<string>("");
  const [swipeInProgress, setSwipeInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (inputRef) {
      inputRef.current!.focus({
        cursor: "all",
      });
    }
  }, []);

  useEffect(() => {
    setSwipeInProgress(false);
  }, [visible]);

  const onStartSwipe = () => {
    setSwipeInProgress(true);
    inputRef.current.focus({
      cursor: "start",
    });
    setSwipedData("");
  };

  const onChange = (e) => {
    const value = e.target.value;
    setSwipedData(value);
  };

  const onEnter = (e: any) => {
    const value = e.target.value;
    onSearch(value);
  };

  const onSearch = (value: string) => {
    const code = decodeGiftCode(value);
    if (!code) {
      const err =
        "Card reader can not recognize this card. Please try again or cancel.";
      dispatchFailure(err)(dispatch);
      setSwipedData("");
    } else {
      GiftCardAPI.fetchOne(code)
        .then((response) => {
          const err = getErrorString(response);
          if (err) {
            dispatchFailure(err)(dispatch);
            setSwipedData("");
          } else {
            const { rows = [] } = response;
            if (isArray(rows) && !isEmptyArray(rows)) {
              const [object] = rows;
              if (isObject(object) && !isEmptyObject(object) && object.doc) {
                const { doc } = object;
                setOrderGiftCard(doc)(dispatch);
                onClose();
              }
            } else {
              const err = `Gift Card not found by code '${code}'`;
              dispatchFailure(err)(dispatch);
              setSwipedData("");
            }
          }
        })
        .catch((e) => {
          const err = getErrorString(e) || "";
          dispatchFailure(err)(dispatch);
          setSwipedData("");
        });
    }
    setSwipeInProgress(false);
  };

  return (
    <div>
      <Input
        key="swipe"
        ref={inputRef}
        placeholder=""
        value={swipedData}
        style={hide}
        id="focusedSwipeInput"
        onChange={onChange}
        onPressEnter={onEnter}
      />
      {swipeInProgress ? (
        <div>
          <span className="text-primary">Please swipe gift card...</span>
        </div>
      ) : (
        <>
          <Input.Group compact>
            <Input
              style={{ width: "calc(100% - 74px)" }}
              placeholder="Input Gift Card"
              onChange={onChange}
              value={swipedData}
              onPressEnter={onEnter}
            />
            <Button onClick={() => onSearch(swipedData)}>
              <span>APPLY</span>
            </Button>
          </Input.Group>
          <Divider orientation="center">OR</Divider>
          <Button size="lg" onClick={onStartSwipe} style={{ width: "100%" }}>
            <span>SWIPE Gift Card</span>
          </Button>
        </>
      )}
    </div>
  );
};

export default GiftCardPayment;
