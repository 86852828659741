import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BrowserProductCards } from "../../components/Product/BrowserProductCards";
import type { ICartItem, ICategory, IProduct } from "../../store/types";
import { fetchProductsByCategory } from "../../store/product";
import { addOrderItem, updateOrderModifier } from "../../store/order";
import { genHash, itemsSelector } from "../../store/selector/cart";
import { catSelector } from "../../store/selector/category";
import type { IMode } from "../../util/Navigation";

interface IProps {
  category?: ICategory;
  mode: IMode;
}

export const ProductsByCategory = ({ category, mode }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<{ c: string; sc: string; p: string }>();
  const { p = "" } = params;
  const url = useLocation().pathname;

  const { items, products, product, view, loading } = useAppSelector(
    (state) => {
      return {
        items: itemsSelector(state),
        categoryList: catSelector(state),
        product: itemsSelector(state).find((o: ICartItem) => o.hash === p),
        products: category
          ? (state.product.list[category.title] || { rows: [] }).rows
          : [],
        loading: state.product.loading || state.category.loading,
        view: state.product.view,
      };
    }
  );

  const categoryName = (category && category.title) || "";
  useEffect(() => {
    if (category) {
      fetchProductsByCategory(category.title)(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  const onProduct = (doc: IProduct) => {
    if (doc) {
      const newElement = { ...doc, quantity: 1, url };
      if (doc.subcategories && doc.subcategories.length) {
        newElement.hash = genHash();
      } else {
        const existItem = items.find((o: ICartItem) => o._id === doc._id);
        if (existItem && existItem.hash) {
          newElement.hash = existItem.hash;
        } else {
          newElement.hash = genHash();
        }
      }
      const { hash, subcategories: categoryModifiers = [] } = newElement;
      const parent = url.split("/p/")[0];

      if (categoryModifiers && categoryModifiers.length) {
        navigate(`${parent}/p/${hash}/m`);
      } else {
        if (!p) {
          newElement.url = parent.includes("/p") ? `${parent}` : `${parent}/p`;
          navigate(`${url || ""}`);
        }
      }
      addOrderItem(newElement)(dispatch);
    }
  };

  const onProductModifier = (modifier: IProduct) => {
    if (modifier && modifier != null) {
      updateOrderModifier(product, modifier)(dispatch);
    }
  };
  const currentProduct = product
    ? items.find((o: ICartItem) => o.hash === product.hash)
    : null;

  return (
    <BrowserProductCards
      {...{
        loading,
        view,
        list: products,
        items: currentProduct ? currentProduct.modifiers || [] : [],
        onSelect: mode !== "categoryModifier" ? onProduct : onProductModifier,
      }}
    />
  );
};
