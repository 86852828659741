import { Statistic } from "../../UI/Layout";
import styled from "styled-components";
import { isEmptyString, isString } from "../../../util/variableTypes";

const { Countdown } = Statistic;

interface IProps {
  requestId?: string;
  status?: string;
  message?: string;
}

const CountDownDiiv = styled.div`
  text-align: center;
  font-weight: bold;
  .ant-statistic {
    font-size: xxx-large;
  }
  .ant-statistic div {
    font-size: inherit;
  }
`;

export const PaymentProcessDetails = ({
  requestId,
  status,
  message,
}: IProps): JSX.Element => (
  <div>
    {isString(requestId) && !isEmptyString(requestId) ? (
      <div className="tacenter m25 text-uppercase bold">
        <span className="text-muted">Request Id:&nbsp;</span>
        <span className="text-primary">{requestId}</span>
      </div>
    ) : null}
    {isString(status) && !isEmptyString(status) ? (
      <div className="tacenter m25 text-uppercase bold">
        <span className="text-muted">Status:&nbsp;</span>
        <span className="text-primary">{status}</span>
      </div>
    ) : null}
    {isString(message) && !isEmptyString(message) ? (
      <div className={`tacenter m25 text-uppercase bold`}>
        <span className="text-muted">Message:&nbsp;</span>
        <span
          className={`text-${
            message !== "Transaction Completed" ? "danger" : "primary"
          }`}
        >
          {message}
        </span>
      </div>
    ) : null}
    {status === "Transaction in progress" ? (
      <CountDownDiiv>
        <Countdown title="Please wait..." value={Date.now() + 90 * 1000} />
      </CountDownDiiv>
    ) : null}
  </div>
);
