import { Statistic, Card, Row, Col } from "../../UI/Layout";

interface IProps {
  totalDue: number;
  balance: number;
}

export const Balance = ({ totalDue = 0, balance = 0 }: IProps): JSX.Element => (
  <Row gutter={[16, 16]}>
    <Col span={12}>
      <Card>
        <Statistic
          prefix="$"
          precision={2}
          style={{ flex: 1 }}
          title="Total Due"
          value={totalDue}
          valueStyle={{ color: "#3f8600" }}
        />
      </Card>
    </Col>
    <Col span={12}>
      <Card>
        <Statistic
          prefix="$"
          precision={2}
          title="Balance"
          style={{ flex: 1 }}
          value={balance + ""}
          valueStyle={{ color: "#cf1322" }}
        />
      </Card>
    </Col>
  </Row>
);
