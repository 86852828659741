import { useEffect } from "react";
import { Heartbeat } from "../util/API";

export const HeartbeatContainer = ({ user }: { user: string }): JSX.Element => {
  useEffect(() => {
    if (window.location.hostname === "localhost") return; // turn off heartbeat on localhost

    window.setInterval(() => {
      Heartbeat.ping(user);
    }, 20000);
  }, [user]);

  return null;
};
