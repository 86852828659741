import { useEffect, useRef } from "react";
import { Card, Form, Input } from "../../components/UI/Layout";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { updateOrderCustomer } from "../../store/order";

interface IProps {
  required?: string[];
  hide?: string[];
}

export const FormEmployee = ({
  required = [],
  hide = [],
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);

  const { customer = { firstName: "", employeeNum: "" } } = useAppSelector(
    (state) => ({
      customer: state.order.customer,
    })
  );

  useEffect(() => {
    if (inputRef) {
      inputRef.current!.focus({
        cursor: "all",
      });
    }
  }, []);

  const onFormChange = (fieldsValue: any) => {
    updateOrderCustomer({ ...customer, ...fieldsValue })(dispatch);
  };

  const { firstName } = customer;

  return (
    <Card bordered={false} id="form-customer">
      <Form layout="vertical" name="card-form" onValuesChange={onFormChange}>
        {!hide.includes("firstName") ? (
          <Form.Item label="Customer Name">
            <Form.Item name="firstName" noStyle>
              <Input value={firstName} placeholder="Please input Name" />
            </Form.Item>
          </Form.Item>
        ) : null}
        {!hide.includes("employeeNum") ? (
          <Form.Item label="Employee Number">
            <Form.Item
              name="employeeNum"
              noStyle
              rules={[
                {
                  required: required.includes("employeeNum"),
                  message: "Employee Number is required",
                },
              ]}
            >
              <Input
                ref={inputRef}
                value={customer.employeeNum}
                placeholder="Please input Employee Number"
              />
            </Form.Item>
          </Form.Item>
        ) : null}
      </Form>
    </Card>
  );
};
