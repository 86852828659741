import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getMoneyString } from "../../util/helpers";
import { Button } from "../../components/UI/Controls/Button";
import { LoadingIndicator } from "../../components/UI/LoadingIndicator";
import { CartItemName } from "../../components/Order/Cart/CartItemName";
import { fetchOrderList } from "../../store/orderList";
import { CartTotals } from "../../components/Order/Cart/CartTotals";
import { deleteOrder, setOrder } from "../../store/order";
import { isObject, isEmptyObject } from "../../util/variableTypes";
import { negativePaymentsAliases } from "../../constants/cart";
import type { ICartItem, ICustomer, IPayment } from "../../store/types";
import type { IStore, ITotals, IUser } from "../../store/types";
import { MailOutlined, PhoneOutlined, HomeOutlined } from "@ant-design/icons";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import { fixedTo2 } from "../../util/helpers";

interface IOrder {
  _id: string;
  dateCreated: string;
  user?: IUser;
  customer?: ICustomer;
  store?: IStore;
  items?: ICartItem[];
  payment?: IPayment[];
  totals?: ITotals;
}

interface ICartItemProps {
  item: ICartItem;
}

export const CartItem = ({ item }: ICartItemProps): JSX.Element => {
  const {
    name,
    price = 0,
    discountedPrice = 0,
    quantity = 1,
    modifiers = [],
  } = item;
  return (
    <div key={item.hash} className={`p-0 mb-1`}>
      <div className="text-sm flex" style={{ alignItems: "baseline" }}>
        <div className="flex-1">
          <CartItemName {...{ name, price }} />
        </div>
        <div
          className="mr-5 text-right"
          style={{ width: 60, whiteSpace: "nowrap" }}
        >
          {quantity}&nbsp;x
        </div>
        <div>
          <div
            style={{
              whiteSpace: "nowrap",
              color: discountedPrice ? "#aaa" : "",
              textDecoration: discountedPrice ? "line-through" : "",
            }}
          >
            ${fixedTo2(+price)}
          </div>
          {discountedPrice ? (
            <div style={{ whiteSpace: "nowrap" }}>
              ${fixedTo2(discountedPrice)}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {modifiers.map((el: any, i: number) => (
          <div key={i} className="text-xs ml-2 pl-2 border-blue-900 border-l-2">
            <div className="flex">
              <div className="flex-1">
                <span>{el.name}</span>
              </div>
              <div
                className="mr-7 text-right"
                style={{ width: 60, whiteSpace: "nowrap" }}
              >
                {el.quantity}&nbsp;x
              </div>
              <div>
                <div
                  className="text-right"
                  style={{
                    whiteSpace: "nowrap",
                    color: el.discountedPrice ? "#aaa" : "",
                    textDecoration: el.discountedPrice ? "line-through" : "",
                  }}
                >
                  ${fixedTo2(+el.price)}
                </div>
                {el.discountedPrice >= 0 ? (
                  <div style={{ whiteSpace: "nowrap" }}>
                    ${fixedTo2(el.discountedPrice)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ColumnDateCreated = ({ order }: { order: IOrder }) => (
  <div>
    <div className="font-bold">
      {new Date(order.dateCreated).toLocaleDateString()}
    </div>
    <div className="text-sm">
      {new Date(order.dateCreated).toLocaleTimeString()}
    </div>
  </div>
);

const ColumnStore = ({ order }: { order: IOrder }) => {
  const { user, store } = order;
  const { address, name } = store || {};
  const { line1, line2, city, state, zip } = address || {};
  const userName = [user.firstName, user.lastName].filter((o) => o).join(" ");
  const l1 = [line1, line2].filter((o) => o).join(" ");
  const l2 = [city, state, zip].filter((o) => o).join(" ");
  return (
    <div className="text-xs">
      {userName ? (
        <div>
          <UserOutlined style={{ verticalAlign: "2px" }} />
          &nbsp;
          <span className="text-sm font-bold">{userName}</span>
        </div>
      ) : null}
      {name ? (
        <div>
          <ShopOutlined style={{ verticalAlign: "2px" }} />
          &nbsp;
          <span className="text-sm font-bold">{name}</span>
        </div>
      ) : null}
      {/* {user.phones &&
          Array.isArray(user.phones) &&
          user.phones.length ? (
            <div>
              <PhoneOutlined  style={{ verticalAlign: "2px" }}/>
              &nbsp;
              <span>{user.phones.join(", ")}</span>
            </div>
          ) : null} */}
      {address && isObject(address) && !isEmptyObject(address) ? (
        <div>
          <HomeOutlined style={{ verticalAlign: "2px" }} />
          &nbsp;
          {l1 ? <span>{l1}</span> : null}
          {l2 ? <div>{l2}</div> : null}
        </div>
      ) : null}
    </div>
  );
};

const ColumnCustomer = ({ order }: { order: IOrder }) => {
  const { customer } = order;
  if (!customer || !(isObject(customer) && !isEmptyObject(customer)))
    return null;
  const { address, firstName = "", lastName = "" } = customer;
  const { line1, line2, city, state, zip } = address || {};
  const name = [firstName, lastName].filter((o) => o).join(" ");
  const l1 = [line1, line2].filter((o) => o).join(" ");
  const l2 = [city, state, zip].filter((o) => o).join(" ");
  return (
    <div className="text-xs">
      <div className="text-sm font-bold">{name}</div>
      {customer.phones &&
      Array.isArray(customer.phones) &&
      customer.phones.length ? (
        <div>
          <PhoneOutlined style={{ verticalAlign: "2px" }} />
          &nbsp;
          <span>{customer.phones.join(", ")}</span>
        </div>
      ) : null}
      {customer.emails &&
      Array.isArray(customer.emails) &&
      customer.emails.length ? (
        <div>
          <MailOutlined style={{ verticalAlign: "2px" }} />
          &nbsp;
          <span>{customer.emails.join(", ")}</span>
        </div>
      ) : null}
      {customer.address &&
      isObject(customer.address) &&
      !isEmptyObject(customer.address) ? (
        <div>
          <HomeOutlined style={{ verticalAlign: "2px" }} />
          &nbsp;
          {l1 ? <span>{l1}</span> : null}
          {l2 ? <div>{l2}</div> : null}
        </div>
      ) : null}
    </div>
  );
};

const ColumnPayments = ({ order }: { order: IOrder }) => {
  const { payment } = order;

  if (!payment || !(Array.isArray(payment) && payment.length)) return null;
  return (
    <div>
      {payment.map((item: IPayment, i: number) => {
        const {
          type,
          amount,
          fee = "",
          convenienceFee = "",
          surcharge = "",
          SHFee = "",
        } = item;
        const isNegative = negativePaymentsAliases.includes(type);
        const value = !isNegative ? amount : amount * -1;
        const label = type;

        return (
          <div key={i}>
            <div title={label}>
              <span style={{ padding: "0 5px", border: "1px solid black" }}>
                {label}
              </span>
              &nbsp;{getMoneyString(value)}
            </div>
            {+fee > 0 ? (
              <div>
                <span>Fee:</span>&nbsp;{getMoneyString(fee)}
              </div>
            ) : null}
            {+convenienceFee > 0 ? (
              <div>
                <span>Convenience Fee:</span>
                &nbsp;{getMoneyString(convenienceFee)}
              </div>
            ) : null}
            {+surcharge > 0 ? (
              <div>
                <span>Surcharge:</span>&nbsp;{getMoneyString(surcharge)}
              </div>
            ) : null}
            {+SHFee > 0 ? (
              <div>
                <span>SHFee:</span>&nbsp;{getMoneyString(SHFee)}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export const OrderList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { list, loading, error } = useAppSelector((state) => ({
    list: state.orderList.list,
    loading: state.orderList.loading,
    error: state.orderList.error,
  }));

  useEffect(() => {
    fetchOrderList()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingIndicator error={error} loading={loading}>
      <div>
        <div className="xl:hidden">
          {list.map((order, i) => {
            const onRelease = () => {
              const newOrder = { ...order };
              delete newOrder._id;
              delete newOrder._rev;
              delete newOrder.status;
              setOrder(newOrder)(dispatch);
              deleteOrder(order)(dispatch);
              navigate("/register");
            };

            const onPrint = () => {
              setOrder(order)(dispatch);
              navigate("/register/thankyou");
            };

            return (
              <div
                key={i}
                className="mt-5 pb-5 max-w-screen-sm sm:mx-auto sm:flex sm:flex-row xs:m-5"
                style={{ borderBottom: "3px #a2d2ff solid" }}
              >
                <div className="sm:flex-1 xs:text-center sm:text-left">
                  <div className="text-xs">{order._id}</div>
                  <ColumnDateCreated order={order} />
                  <Button label="Print" type="default" onClick={onPrint} />
                  <ColumnStore order={order} />
                  <ColumnCustomer order={order} />
                </div>
                <div>
                  {order.items.map((item, i) => (
                    <CartItem key={i} item={item} />
                  ))}
                  <CartTotals totals={order.totals} />
                  <ColumnPayments order={order} />
                  <div className="text-right">
                    <Button label="Release" className="" onClick={onRelease} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="max-w-screen-xl mx-auto hidden xl:block">
          <table className="table">
            <thead>
              <tr>
                <th className="text-left">Created</th>
                <th className="text-left">Store</th>
                <th className="text-left">Customer</th>
                <th className="text-left">Details</th>
                <th className="text-right">Totals</th>
              </tr>
            </thead>
            <tbody>
              {list.map((order, i) => {
                const onRelease = () => {
                  const newOrder = { ...order };
                  delete newOrder._id;
                  delete newOrder._rev;
                  delete newOrder.status;
                  setOrder(newOrder)(dispatch);
                  deleteOrder(order)(dispatch);
                  navigate("/register");
                };

                const onPrint = () => {
                  setOrder(order)(dispatch);
                  navigate("/register/thankyou");
                };

                return (
                  <tr key={i}>
                    <td>
                      <div className="text-xs">{order._id}</div>
                      <ColumnDateCreated order={order} />
                      <Button label="Print" type="default" onClick={onPrint} />
                    </td>
                    <td>
                      <ColumnStore order={order} />
                    </td>
                    <td>
                      <ColumnPayments order={order} />
                      <ColumnCustomer order={order} />
                    </td>
                    <td className="pr-10">
                      {order.items.map((item, i) => (
                        <CartItem key={i} item={item} />
                      ))}
                    </td>
                    <td>
                      <CartTotals totals={order.totals} />
                      <div className="text-right">
                        <Button
                          label="Release"
                          className=""
                          onClick={onRelease}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </LoadingIndicator>
  );
};
