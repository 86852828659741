import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IStation, IStore } from "./types";
import type { AppDispatch } from "../store";
import { isObject, isEmptyObject } from "../util/variableTypes";
import { DepartmentAPI, StoreAPI } from "../util/API";
import { getErrorString } from "../util/helpers";

type StoreState = {
  list: IStore[];
  selected: IStore | null;
  station: IStation | null;
  loading: boolean;
  error: string;
};

const initialState: StoreState = {
  list: [],
  selected: null,
  station: null,
  loading: false,
  error: "",
};
const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    start: (): StoreState => ({
      list: [],
      selected: null,
      station: null,
      loading: true,
      error: "",
    }),
    fail: (state, action: PayloadAction<string>): StoreState => ({
      list: [],
      selected: null,
      station: null,
      loading: false,
      error: getErrorString(action.payload),
    }),
    success: (state, action: PayloadAction<IStore[]>): StoreState => ({
      list: action.payload,
      selected: null,
      station: null,
      loading: false,
      error: "",
    }),
    set: (state, action: PayloadAction<IStore>): StoreState => ({
      list: state.list,
      selected: action.payload,
      station: null,
      loading: false,
      error: "",
    }),
    setStation: (state, action: PayloadAction<IStation>): StoreState => ({
      list: state.list,
      selected: state.selected,
      station: action.payload,
      loading: false,
      error: "",
    }),
  },
});
const { fail, success, set, setStation, start } = storeSlice.actions;
export default storeSlice.reducer;

// public methods are below

export const fetchAllStores = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(start());

    DepartmentAPI.fetchAll()
      .then((departmentsDocs: any) => {
        const { rows: departments } = departmentsDocs;
        StoreAPI.fetchAll()
          .then((response) => {
            const err = getErrorString(response);
            if (!err) {
              const { rows = [] } = response;
              const list = rows.map((o: any) => o.doc);
              dispatch(success(list));
              const store = list[0];
              const { department: departmentName = "" } = store;
              const departmentDoc =
                departments.find((obj: any) => {
                  const { doc } = obj;
                  return doc.name === departmentName;
                }) || null;
              const { doc: department = {} } = departmentDoc || {};
              const {
                payments: { paymentTypes = [], paymentEngine = {} } = {},
              } = department;
              dispatch(
                set({
                  ...store,
                  paymentTypes,
                  paymentEngine: paymentEngine.type
                    ? { type: paymentEngine.type }
                    : {},
                })
              );

              if (store && store.stations && store.stations.length) {
                const station = store.stations.find((o) => o.isActive);
                if (isObject(station) && !isEmptyObject(station)) {
                  dispatch(setStation(station));
                }
              }
            } else {
              dispatch(fail(err));
            }
          })
          .catch((error) => {
            dispatch(fail(getErrorString(error)));
          });
      })
      .catch((error) => {
        dispatch(fail(getErrorString(error)));
      });
  };
};

export const setStore = (store: IStore | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(set(store));
  };
};
