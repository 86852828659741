import { useState, useEffect } from "react";
import { debounce } from "debounce";
import { Layout } from "./components/UI/Layout";
import { doAuthenticate } from "./store/auth";
import { useAppSelector, useAppDispatch } from "./hooks";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { fetchMerchant, resetMerchant } from "./store/merchant";
import { LoadingIndicator } from "./components/UI/LoadingIndicator";
import { UserRoute, type IUserRouteProps } from "./components/UI/UserRoute";
import { LoginAdmin } from "./containers/Login/LoginAdmin";
import { NotFound } from "./components/NotFound/NotFound";
import { fetchAllStores } from "./store/store";
import { Home } from "./components/Home/Home";
import { SignUp } from "./containers/SignUp/SignUp";
import { Login } from "./containers/Login/Login";
import { IGWExtension } from "./containers/IgwExtension";
import { OrderSider } from "./components/Order/OrderSider";
import { Stores } from "./containers/Order/Stores";
import { MainMenu } from "./components/Menu/MainMenu";
import { CategoryList } from "./containers/Order/CategoryList";
import { GlobalNotifications } from "./components/UI/GlobalNotications";
import { Checkout } from "./containers/Order/Checkout";
import { OrderThankyou } from "./components/Order/OrderThankyou";
import { PaymentCard } from "./containers/Order/PaymentCard";
import { OrderList } from "./containers/Order/OrderList";
import { HeartbeatContainer } from "./containers/HeartbeatContainer";
import {saveState} from "./util/browser-storage";
import {store} from "./store";

const stores: IUserRouteProps = {
  pageTitle: "Stores",
  deniedRoles: ["customer"],
  left: undefined,
  right: undefined,
  children: <Stores />,
};

const onhold: IUserRouteProps = {
  pageTitle: "Orders On Hold",
  deniedRoles: ["customer"],
  left: <MainMenu mode="orders" />,
  right: undefined,
  children: <OrderList />,
};

const categories: IUserRouteProps = {
  pageTitle: "Order",
  right: <OrderSider />,
  left: <MainMenu mode="category" />,
  children: <CategoryList mode="category" />,
};

const subcategories: IUserRouteProps = {
  pageTitle: "Order",
  right: <OrderSider />,
  left: <MainMenu mode="subcategory" />,
  children: <CategoryList mode="subcategory" />,
};

const products: IUserRouteProps = {
  pageTitle: "Order",
  right: <OrderSider />,
  left: <MainMenu mode="product" />,
  children: <CategoryList mode="product" />,
};

const product: IUserRouteProps = {
  pageTitle: "Order",
  right: <OrderSider />,
  left: <MainMenu mode="product" />,
  children: <CategoryList mode="product" />,
};

const categoryModifiers: IUserRouteProps = {
  pageTitle: "Order",
  right: <OrderSider />,
  left: <MainMenu mode="categoryModifier" />,
  children: <CategoryList mode="categoryModifier" />,
};

const productModifiers: IUserRouteProps = {
  pageTitle: "Order",
  right: <OrderSider />,
  left: <MainMenu mode="productModifier" />,
  children: <CategoryList mode="productModifier" />,
};

const checkout: IUserRouteProps = {
  pageTitle: "Checkout",
  deniedRoles: ["customer"],
  left: undefined,
  right: undefined,
  children: <Checkout />,
};

const thankyou: IUserRouteProps = {
  pageTitle: "Thank You",
  deniedRoles: ["customer"],
  left: undefined,
  right: undefined,
  children: <OrderThankyou />,
};

const paymentCard: IUserRouteProps = {
  pageTitle: "Payment Card",
  deniedRoles: ["customer"],
  left: undefined,
  right: undefined,
  children: <PaymentCard />,
};

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

export const App = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const merchant = useAppSelector((state) => state.merchant);
  const auth = useAppSelector((state) => state.auth);
  const { notifications } = useAppSelector((state) => ({
    notifications: state.notify.list,
  }));

  const { loading, error } = merchant;


  useEffect(() => {
    fetchMerchant()(dispatch);
    doAuthenticate()(dispatch);
    return () => {
      resetMerchant()(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      auth &&
      auth.me &&
      auth.me._id &&
      auth.me.role &&
      auth.me.role !== "customer"
    ) {
      fetchAllStores()(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.me]);

  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
      <IGWExtension />
      <GlobalNotifications {...{ notifications }} />
      {auth.me ? <HeartbeatContainer user={auth.me._id} /> : null}
      <LoadingIndicator {...{ loading, error }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/customer/login" element={<Login />} />
            <Route path="/login" element={<LoginAdmin />} />
            {auth.me
              ? [
                  <Route
                    key="c/c/sc/sc/p/p/m/m"
                    path="/c/:c/sc/:sc/p/:p/m/:m"
                    element={<UserRoute {...{ ...productModifiers }} />}
                  />,
                  <Route
                    key="c/c/p/p/m/m"
                    path="/c/:c/p/:p/m/:m"
                    element={<UserRoute {...{ ...productModifiers }} />}
                  />,
                  <Route
                    key="c/c/sc/sc/p/p/m"
                    path="/c/:c/sc/:sc/p/:p/m"
                    element={<UserRoute {...{ ...categoryModifiers }} />}
                  />,
                  <Route
                    key="c/c/p/p/m"
                    path="/c/:c/p/:p/m"
                    element={<UserRoute {...{ ...categoryModifiers }} />}
                  />,
                  <Route
                    key="c/c/sc/sc/p/p"
                    path="/c/:c/sc/:sc/p/:p"
                    element={<UserRoute {...{ ...product }} />}
                  />,
                  <Route
                    key="c/c/p/p"
                    path="/c/:c/p/:p"
                    element={<UserRoute {...{ ...product }} />}
                  />,
                  <Route
                    key="c/c/sc/p"
                    path="/c/:c/sc/:sc/p"
                    element={<UserRoute {...{ ...products }} />}
                  />,
                  <Route
                    key="c/c/p"
                    path="/c/:c/p"
                    element={<UserRoute {...{ ...products }} />}
                  />,
                  <Route
                    key="c/c/sc/sc"
                    path="/c/:c/sc/:sc"
                    element={<UserRoute {...{ ...subcategories }} />}
                  />,
                  <Route
                    key="c/c"
                    path="/c/:c"
                    element={<UserRoute {...{ ...subcategories }} />}
                  />,
                  <Route
                    key="register/store"
                    path="/register/store"
                    element={<UserRoute {...{ ...stores }} />}
                  />,
                  <Route
                    key="register/checkout"
                    path="/register/checkout"
                    element={<UserRoute {...{ ...checkout }} />}
                  />,
                  <Route
                    key="register/thankyou"
                    path="/register/thankyou"
                    element={<UserRoute {...{ ...thankyou }} />}
                  />,
                  <Route
                    key="register/payment"
                    path="/register/payment"
                    element={<UserRoute {...{ ...paymentCard }} />}
                  />,
                  <Route
                    key="register"
                    path="/register"
                    element={<UserRoute {...{ ...categories }} />}
                  />,
                  <Route
                    key="on-hold"
                    path="/on-hold"
                    element={<UserRoute {...{ ...onhold }} />}
                  />,
                ]
              : null}
            <Route element={<NotFound />} path="*" />
          </Routes>
        </BrowserRouter>
      </LoadingIndicator>
    </Layout>
  );
};
