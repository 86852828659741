import type { ICartItem } from "../../../store/types";
import { fixedTo2 } from "../../../util/helpers";
import { CartItemName } from "./CartItemName";

interface IProps {
  item: ICartItem;
  classes?: string;
  classesWrap?: string;
  onClickItem?: any;
}

export const CartItem = ({
  item,
  classesWrap = "",
  classes = "cart__title",
  onClickItem,
}: IProps): JSX.Element => {
  const {
    name,
    price = 0,
    discountedPrice = 0,
    quantity = 1,
    modifiers = [],
  } = item;
  const className = [];
  if (classes) {
    className.push(classes);
  }
  return (
    <div
      key={item.hash}
      className={`p5 mb10 cart__element ${classesWrap}`}
      onClick={() => (onClickItem ? onClickItem(item) : null)}
    >
      <div
        className="flexFlowRow flexJCSpaceBetween"
        style={{ alignItems: "baseline" }}
      >
        <div className={className.join(" ")}>
          <CartItemName {...{ name, price }} />
        </div>
        <div className="mr15" style={{ whiteSpace: "nowrap" }}>
          {quantity}&nbsp;x
        </div>
        <div>
          <div
            style={{
              whiteSpace: "nowrap",
              color: discountedPrice ? "#aaa" : "",
              textDecoration: discountedPrice ? "line-through" : "",
            }}
          >
            ${fixedTo2(+price)}
          </div>
          {discountedPrice ? (
            <div style={{ whiteSpace: "nowrap" }}>
              ${fixedTo2(discountedPrice)}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {modifiers.map((el: any, i: number) => (
          <div key={i}>
            <div className="flexFlowRow flexJCSpaceBetween">
              <div className="cart__title_sm cart__title_selectable">
                <span>{el.name}</span>
              </div>
              <div className="mr15" style={{ whiteSpace: "nowrap" }}>
                {el.quantity}&nbsp;x
              </div>
              <div>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    color: el.discountedPrice ? "#aaa" : "",
                    textDecoration: el.discountedPrice ? "line-through" : "",
                  }}
                >
                  ${fixedTo2(+el.price)}
                </div>
                {el.discountedPrice >= 0 ? (
                  <div style={{ whiteSpace: "nowrap" }}>
                    ${fixedTo2(el.discountedPrice)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
