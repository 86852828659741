import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Sider } from "../../components/UI/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { catByUrlSelector, catSelector } from "../../store/selector/category";
import { fetchCategories } from "../../store/category";
import { BUILD } from "../../constants/api";
import type { IMode } from "../../util/Navigation";
import { ICategory } from "../../store/types";

const style = { height: "45px", background: `center / contain no-repeat` };

export const MainMenu = ({ mode }: { mode: IMode }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const params = useParams<{
    c: string;
    sc: string;
    0: string;
    m: string;
    p: string;
  }>();
  const urlParam = (params[0] || "").replace("/register", "");
  const urlParts = urlParam.split("/c/");
  const current = params["c"] || "";
  const parent = urlParts.find((o) => o) || current;

  const { category, categoryList, collapsed } = useAppSelector((state) => ({
    category: catByUrlSelector(state)[parent],
    collapsed: state.sider.collapsed,
    categoryList: catSelector(state),
  }));

  const { categories = [], modifiers = [] } = categoryList || {};
  const { subcategories: oldSubcategories = [] } = category || {};
  const subcategories = [...categories, ...modifiers].filter((o: any) =>
    oldSubcategories.some((el: any) => o.title === el.title)
  );
  const { categories: list = [] } = categoryList || {};
  const onCategory = (category: ICategory) => {
    if (category) {
      const url = category.urlFull;
      if (category && category.subcategories && category.subcategories.length) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}/p`);
      }
    }
  };

  const onViewOnHold = () => {
    navigate(`/on-hold`);
  };

  useEffect(() => {
    fetchCategories()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!category && list.length && pathname !== "/on-hold") {
      onCategory(list[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const baseUrl = import.meta.env.PUBLIC_URL || "";
  const { title } = category || {};
  const renderSwitch = (param: string) => {
    switch (param.toLowerCase()) {
      case "food":
        return (
          <div
            style={{
              ...style,
              backgroundImage: `url(${baseUrl + "/image/Food.svg"})`,
            }}
          />
        );
      case "drinks":
        return (
          <div
            style={{
              ...style,
              backgroundImage: `url(${baseUrl + "/image/Beverage.svg"})`,
            }}
          />
        );
      case "merchandise":
        return (
          <div
            style={{
              ...style,
              backgroundImage: `url(${baseUrl + "/image/Merchandise.svg"})`,
            }}
          />
        );
      case "onhold":
        return (
          <div
            style={{
              ...style,
              backgroundImage: `url(${baseUrl + "/image/Pause.svg"})`,
            }}
          />
        );
      default:
        return null;
    }
  };

  const menu = list.map((category: ICategory) => (
    <div
      className={`main-menu--item ${
        title === category.title ? "main-menu--item_selected" : ""
      }`}
      onClick={() => onCategory(category)}
      key={category.title}
    >
      <div>
        {renderSwitch(category.title || "")}
        {category.title.toLowerCase() !== "merchandise" ? (
          <span>
            <b>{category.title}</b>
          </span>
        ) : (
          <span>
            <b>merch</b>
          </span>
        )}
      </div>
    </div>
  ));

  menu.push(
    <div
      className={`main-menu--item ${
        pathname === "/on-hold" ? "main-menu--item_selected" : ""
      } `}
      onClick={onViewOnHold}
      key="onhold"
    >
      <div>
        {renderSwitch("onhold")}
        <span>
          <b>onhold</b>
        </span>
      </div>
    </div>
  );
  const subMenu = subcategories.map((category: ICategory) => (
    <div
      className={`sub-menu--item ${
        current === category.url ? "sub-menu--item_selected" : ""
      }`}
      key={category.title}
    >
      <Link
        to={`${baseUrl}/c/${current}/sc/${category.title}`}
        style={{ display: "block" }}
      >
        <b>{category.title}</b>
      </Link>
    </div>
  ));
  return (
    <Sider
      width={300}
      style={{
        marginTop: "64px",
        background: "#093965",
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div
        style={{
          position: "absolute",
          bottom: collapsed ? "48px" : "64px",
        }}
        className="text-center text-xs bg-color-sidebar-darken text-color-sidebar-text w-full z-40"
      >
        {BUILD}
      </div>
      <div id="main-menu">{menu}</div>
      <div id="sub-menu">{subMenu}</div>
    </Sider>
  );
};
