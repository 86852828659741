import { TextArea, AutoComplete } from "../../../UI/Layout";
import { useAppSelector } from "../../../../hooks";
import { CloseOutlined } from "@ant-design/icons";
import { getMoneyString } from "../../../../util/helpers";
import { QuantityInput } from "../../../UI/Controls/QuantityInput";
import { PriceInput } from "../../../UI/Controls/PriceInput";
import type { ICartItem } from "../../../../store/types";
import { Button } from "../../../UI/Controls/Button";
import { CartItemName } from "../CartItemName";

interface IProps {
  item: ICartItem | null;
  classes?: string;
  descriptionEditable?: boolean;
  onChange: Function;
  onRemove?: Function;
  onBackMode?: Function;
}

export const CartProductEdit = ({
  item,
  descriptionEditable = false,
  classes = "cart__title",
  onBackMode,
  onRemove,
  onChange,
}: IProps): JSX.Element => {
  const className = [];
  if (classes) {
    className.push(classes);
  }

  const {
    name = "",
    price = 0,
    quantity = 1,
    individualDiscount = 0,
    discountedPrice = -1,
    description,
  } = item || {};
  const onSelect = (value: string) => {
    onChange("individualDiscount", value);
  };

  const { auth } = useAppSelector((state) => {
    return {
      auth: state.auth.me,
    };
  });

  return (
    <>
      <div className={`flexFlowRow flexAICenter flexJCSpaceBetween m15`}>
        <div className={className.join()}>
          <CartItemName {...{ name, price }} />
        </div>
        <div>
          {onBackMode ? (
            <Button onClick={onBackMode} type="primary" size="sm">
              Done
            </Button>
          ) : null}
          {onRemove ? (
            <Button onClick={onRemove} type="danger" className="ml5" size="sm">
              <CloseOutlined />
            </Button>
          ) : null}
        </div>
      </div>
      <div className="flexFlowRow flexAICenter m15">
        <div className="form-label__cart">
          <label>Price</label>
        </div>
        <div className="form-control-wrap__cart form-control-wrap__price">
          <PriceInput
            value={+price}
            disabled={auth && auth.role && auth.role === "customer"}
            className="form-control form-control__cart"
            onChange={(value: number) => onChange("price", value)}
          />
        </div>
      </div>
      {auth && auth.role && auth.role !== "customer" ? (
        <div className="flexFlowRow flexAICenter m15">
          <div className="form-label__cart">
            <label>Discount</label>
          </div>
          <div className="form-control-wrap__cart flexFlowRow">
            <AutoComplete
              placeholder="Select a discount"
              optionFilterProp="children"
              style={{ width: "86px" }}
              onSelect={onSelect}
              onChange={(data: string) => onChange("individualDiscount", data)}
              value={individualDiscount}
              options={Array.from(Array(21), (_, x) => x * 5).map((o) => ({
                value: `${o}`,
                label: `${o}%`,
              }))}
            />
            <span className="pr5 pl5">%</span>
            <Button
              onClick={(_) => onChange("price", 0)}
              type="primary"
              disabled={+price <= 0}
              title="Price $0.00, No tax"
            >
              FREE
            </Button>
          </div>
        </div>
      ) : null}
      {discountedPrice >= 0 ? (
        <div className="flexFlowRow flexAICenter m15">
          <div className="form-label__cart">
            <label>Discounted Price</label>
          </div>
          <div
            className="form-control-wrap__cart form-control-wrap__price"
            style={{ textAlign: "right", width: "100%" }}
          >
            <b>{getMoneyString(discountedPrice)}</b>
          </div>
        </div>
      ) : null}
      <div className="flexFlowRow flexAICenter m15">
        <div className="form-label__cart">
          <label>Quantity</label>
        </div>
        <div className="form-control-wrap__cart form-control-wrap__qty">
          <QuantityInput
            value={quantity}
            className="form-control__cart"
            onChange={(value: number) => onChange("quantity", value)}
          />
        </div>
      </div>
      {descriptionEditable ? (
        <div className="flexFlowRow flexAICenter m15">
          <div className="form-label__cart">
            <label>Notes</label>
          </div>
          <div className="form-control-wrap__cart form-control-wrap__input">
            <TextArea
              rows={1}
              value={description || ""}
              className="form-control__cart"
              style={{ padding: "2px 15px", height: "38px", paddingTop: "5px" }}
              onChange={(e: any) => {
                onChange("description", e.target.value);
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
