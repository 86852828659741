import {
  Breadcrumb,
  Button,
  Card,
  DatePicker,
  Drawer,
  Divider,
  Dropdown,
  Form,
  Layout,
  List,
  Row,
  Col,
  Menu,
  Modal,
  Progress,
  PageHeader,
  Result,
  Skeleton,
  Select,
  Statistic,
  Tabs,
  Input,
  InputNumber,
  AutoComplete,
  Table,
} from "antd";

import type { TableColumnsType } from "antd";
export { type TableColumnsType };

export const { Header, Content, Sider } = Layout;
export const { TextArea } = Input;

export {
  Breadcrumb,
  Button,
  Card,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Layout,
  List,
  Row,
  Col,
  Menu,
  Modal,
  Progress,
  PageHeader,
  Result,
  Skeleton,
  Statistic,
  Tabs,
  Table,
  Input,
  InputNumber,
  Select,
  AutoComplete,
  Dropdown,
};
