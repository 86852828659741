import { Button, InputNumber } from "../../UI/Layout";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface IProps {
  className?: string;
  value: number;
  onChange: any;
}

export const QuantityInput = (props: IProps): JSX.Element => {
  const onDecline = () => props.onChange(props.value - 1);
  const onIncrease = () => props.onChange(props.value + 1);

  return (
    <Button.Group>
      <Button disabled={props.value === 1} size="large" onClick={onDecline}>
        <MinusOutlined />
      </Button>
      <InputNumber
        min={1}
        value={props.value}
        onChange={props.onChange}
        className={props.className}
      />
      <Button size="large" onClick={onIncrease}>
        <PlusOutlined />
      </Button>
    </Button.Group>
  );
};
