import { createElement, useEffect } from "react";
import { Header, Layout, Button } from "./Layout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Navigate, Link, useLocation } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { setSiderCollapsed } from "../../store/sider";
import { isArray, isEmptyArray } from "../../util/variableTypes";

export interface IUserRouteProps {
  redirect?: string;
  children: JSX.Element;
  pageTitle?: string;
  deniedRoles?: any;
  left?: JSX.Element;
  right?: JSX.Element;
}

export const UserRoute = (props: IUserRouteProps): JSX.Element => {
  const { left, right, pageTitle, deniedRoles, redirect = "/login" } = props;
  console.log("UserRoute", useLocation().pathname);

  const dispatch = useAppDispatch();
  const { me, merchant, collapsed } = useAppSelector((state) => ({
    me: state.auth.me,
    merchant: state.merchant.object,
    collapsed: state.sider.collapsed,
  }));

  const { title = "" } = merchant || {};
  const { firstName = "", lastName = "", role = "" } = me || {};
  const toggleCollapsed = () => {
    setSiderCollapsed(!collapsed)(dispatch);
  };

  const classes = `${left ? "has-sider" : ""} ${
    left && collapsed ? "has-sider-collapsed" : ""
  } `;

  useEffect(() => {
    const id = setInterval(() => {
      if (window.innerWidth < 600) {
        setSiderCollapsed(true)(dispatch);
      }
    }, 1500);

    return () => clearInterval(id);
  }, [dispatch]);

  const validRole = !(isArray(deniedRoles) && !isEmptyArray(deniedRoles))
    ? true
    : !deniedRoles.includes(role);

  if (!me) {
    console.warn("no auth, redirected to ", { redirect });
    return <Navigate to={redirect} />;
  }
  if (!validRole) {
    console.warn("no permission, redirected to ", { redirect });
    return <Navigate to={redirect} />;
  }
  return (
    <Layout className="scroll-hide">
      <Header
        className="flexFlowRow"
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div className="flexFlowRow">
          {left ? (
            <Button
              type="primary"
              id="toggle-button"
              onClick={toggleCollapsed}
              style={{ margin: "15px 15px 0 0" }}
            >
              {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
          ) : null}
          <div className="app-branding mr30">
            <Link to="/" className="app-title">
              {title}
            </Link>
          </div>
        </div>
        {props.pageTitle ? (
          <h1
            style={{
              color: "#fff",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {props.pageTitle}
          </h1>
        ) : null}
        <div style={{ paddingRight: "15px" }}>
          {firstName} {lastName}
        </div>
      </Header>
      <Layout hasSider style={{ marginTop: "64px" }}>
        {left ? props.left : null}
        <Layout id="section-center" className={classes}>
          <Layout>
            {props.children}
            {right ? right : null}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};
