import { Button } from "./Controls/Button";
import { PaymentDevice } from "./PaymentDevice";
import { isEmptyObject, isObject } from "../../util/variableTypes";

interface IProps {
  device: any;
  sendingToDevice: boolean;
  disabled: boolean;
  onStart: any;
}

export const ChargeComponent = ({
  device,
  disabled,
  sendingToDevice,
  onStart,
}: IProps): JSX.Element => (
  <div className="tacenter mt15">
    {sendingToDevice ? (
      <div>
        <img
          style={{ maxWidth: "500px", width: "100%" }}
          src="https://s3.amazonaws.com/indywebhost/loading/small_animation.gif"
          alt="charge"
        />
      </div>
    ) : (
      <Button
        size="lg"
        onClick={onStart}
        style={{ width: "100%" }}
        disabled={disabled}
      >
        <span>Charge @{device.stationName}</span>
      </Button>
    )}
    {isObject(device) && !isEmptyObject(device) ? (
      <PaymentDevice {...device} />
    ) : null}
  </div>
);
