import { Button } from "../UI/Controls/Button";
import type { FormEventHandler, ChangeEventHandler } from "react";

interface IProps {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleInputChange: ChangeEventHandler;
  username?: string;
  password?: string;
}

export const LoginForm = ({
  handleSubmit,
  handleInputChange,
  username,
  password,
}: IProps): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-item">
        <input
          name="username"
          className="form-control"
          placeholder="Username"
          value={username}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-item">
        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="Password"
          value={password}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-item">
        <Button actionType="submit" size="lg" onClick={() => {}}>
          Login
        </Button>
      </div>
    </form>
  );
};
