import { createSelector } from "@reduxjs/toolkit";
import type { ICartItem } from "../types";
import { isValue } from "../../util/variableTypes";
import { fixedTo2, isDigit } from "../../util/helpers";
import { negativePaymentsAliases } from "../../constants/cart";
import { RootState } from "../../store";

export const genHash = () =>
  window.performance.now().toString().split(".").join("");

const cartItemsSelector = (state: RootState) => state.order.items || [];

export const itemsSelector = createSelector(cartItemsSelector, (items) =>
  items.reduce((acc: ICartItem[], item: any) => {
    const { quantity = 1, price = 0 } = item;
    return [...acc, { ...item, subTotal: quantity * price }];
  }, [])
);

type ICartState = {
  order: { items: ICartItem[] }
}

export const cartItemsFlatSelector = (state: ICartState) => {
  const { items = [] } = state.order;
  const list = items.reduce(
    (res: any, item: any) => [...res, ...(item.modifiers || [])],
    []
  );
  return [...items, ...list];
};

export const getPercentage = (
  amount: number | string,
  percent: number | string
) => {
  if (!(isValue(percent) && isDigit(percent))) return 0;
  if (isValue(amount) && isDigit(amount)) {
    return +(
      (parseFloat(amount + "") / 100) *
      parseFloat(percent + "")
    ).toFixed(2);
  }
  return 0;
};

const tipsSelector = (state: RootState) => {
  return state.order.tips || 0;
};

const taxPercentSelector = (state: RootState) => {
  if (state.order.store) {
    return (state.order.store && state.order.store.taxRate) || 0;
  }
  const me = state.auth.me;
  const { stores } = me;
  return stores && stores[0] ? stores[0].taxRate : 0;
};

const subtotalSelector = createSelector(cartItemsFlatSelector, (items) => {
  const subTotals = items.reduce((acc: number, item: any) => {
    const { quantity = 1, price = 0 } = item;
    return acc + quantity * price;
  }, 0);
  return subTotals;
});

const taxableSelector = createSelector(cartItemsFlatSelector, (items) => {
  return items.reduce((acc: number, item: any) => {
    const { taxable, quantity = 1, price = 0 } = item;
    return taxable ? acc + quantity * price : acc;
  }, 0);
});

const discountSelector = createSelector(cartItemsSelector, (items) => {
  const total = items.reduce((amount: number, item: ICartItem) => {
    const { quantity, appliedDiscounts, modifiers = [] } = item;
    if (appliedDiscounts && appliedDiscounts.length) {
      const discountAmount = appliedDiscounts.reduce(
        (sum: number, curr: any) => {
          const { discountAmount: amount = 0 } = curr;
          return sum + amount;
        },
        0
      );
      if (discountAmount && parseFloat(discountAmount + "") > 0) {
        amount += +fixedTo2(+discountAmount * quantity);
      }
    }
    if (modifiers && modifiers.length) {
      const totalModifiers = modifiers.reduce(
        (amount: number, item: ICartItem) => {
          const { quantity, appliedDiscounts } = item;
          if (appliedDiscounts && appliedDiscounts.length) {
            const discountAmount = appliedDiscounts.reduce(
              (sum: number, curr: any) => {
                const { discountAmount: amount = 0 } = curr;
                return sum + amount;
              },
              0
            );
            if (discountAmount && parseFloat(discountAmount + "") > 0) {
              amount += +fixedTo2(+discountAmount * quantity);
            }
          }
          return amount;
        },
        0
      );
      if (totalModifiers && totalModifiers > 0) {
        amount += +fixedTo2(+totalModifiers * quantity);
      }
    }
    return amount;
  }, 0);
  return +fixedTo2(total);
});

const taxSelector = createSelector(
  taxableSelector,
  taxPercentSelector,
  (subtotal, taxPercent) => {
    return subtotal * (+taxPercent / 100);
  }
);

const feeSelector = (state: RootState) =>
  state.order.payment && state.order.payment.length
    ? state.order.payment.reduce((sum: number, curr: any) => {
      const { fee = 0 } = curr;
      return sum + fee;
    }, 0)
    : 0;

const convenienceFeeSelector = (state: RootState) =>
  state.order.payment && state.order.payment.length
    ? state.order.payment.reduce((sum: number, curr: any) => {
      const { convenienceFee = 0 } = curr;
      return sum + convenienceFee;
    }, 0)
    : 0;

const surchargeSelector = (state: RootState) =>
  state.order.payment && state.order.payment.length
    ? state.order.payment.reduce((sum: number, curr: any) => {
      const { surcharge = 0 } = curr;
      return sum + surcharge;
    }, 0)
    : 0;

const SHFeeSelector = (state: RootState) =>
  state.order.payment && state.order.payment.length
    ? state.order.payment.reduce((sum: number, curr: any) => {
      const { SHFee = 0 } = curr;
      return sum + SHFee;
    }, 0)
    : 0;

export const totalSelector = createSelector(
  subtotalSelector,
  taxSelector,
  discountSelector,
  tipsSelector,
  feeSelector,
  convenienceFeeSelector,
  surchargeSelector,
  SHFeeSelector,
  (
    subTotal,
    taxTotal,
    discountTotal,
    tips,
    fee,
    convenienceFee,
    surcharge,
    SHFee
  ) => {
    return {
      tips,
      fee: +fixedTo2(fee),
      convenienceFee: +fixedTo2(convenienceFee),
      surcharge: +fixedTo2(surcharge),
      SHFee: +fixedTo2(SHFee),
      subTotal: +fixedTo2(subTotal),
      taxTotal: +fixedTo2(taxTotal),
      discountTotal: +fixedTo2(discountTotal),
      grandTotal: +fixedTo2(
        subTotal +
        taxTotal -
        discountTotal +
        tips +
        +fee +
        +convenienceFee +
        +surcharge +
        +SHFee
      ),
    };
  }
);

export const totalSelectorNoTips = createSelector(
  subtotalSelector,
  taxSelector,
  discountSelector,
  feeSelector,
  convenienceFeeSelector,
  surchargeSelector,
  SHFeeSelector,
  (
    subTotal,
    taxTotal,
    discountTotal,
    fee,
    convenienceFee,
    surcharge,
    SHFee
  ) => {
    return {
      subTotal: +fixedTo2(subTotal),
      taxTotal: +fixedTo2(taxTotal),
      discountTotal: +fixedTo2(discountTotal),
      grandTotal: +fixedTo2(
        subTotal +
        taxTotal -
        discountTotal +
        +fee +
        +convenienceFee +
        +surcharge +
        +SHFee
      ),
    };
  }
);

const totalAmountSelector = (state: RootState) => totalSelector(state);

const paidSelector = (state: RootState) =>
  state.order.payment && state.order.payment.length
    ? state.order.payment.reduce((sum: number, curr: any) => {
      if (curr && curr.type) {
        return (
          sum +
          (!negativePaymentsAliases.includes(curr.type)
            ? curr.amount
            : curr.amount * -1)
        );
      }
      return sum;
    }, 0)
    : 0;

export const balanceSelector = createSelector(
  totalAmountSelector,
  paidSelector,
  (totals: any, paid: any) => +fixedTo2(totals.grandTotal - paid)
);
