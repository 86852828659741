import { isEmptyString, isString } from "./variableTypes";

export const getSecureCardNumber = (cardNumber: any) => {
  if (isString(cardNumber) && !isEmptyString(cardNumber)) {
    const l = cardNumber.toString().length;
    if (l > 4) {
      const count = 11;
      const masked = "X".repeat(count);
      const prefix = cardNumber[0];
      const suffix = cardNumber.substr(cardNumber.length - 4);
      return `${prefix}${masked}${suffix}`;
    } else {
      const count = 16 - l;
      const masked = "X".repeat(count);
      return `${masked}${cardNumber}`;
    }
  }
  return "";
};

export const getCardType = (value: string) => {
  switch (parseInt(value.substring(0, 1), 10)) {
    case 3:
      return "AMEX";
    case 4:
      return "VISA";
    case 5:
      return "MC";
    case 6:
      return "DISC";
    default:
      return "";
  }
};

export const getMaskedCardNumber = (fistNum: string, lastNum: string) => {
  if (isString(fistNum) && !isEmptyString(fistNum)) {
    const hasLast = isString(lastNum) && !isEmptyString(lastNum);
    const suffix = hasLast
      ? lastNum.substr(lastNum.length - 4)
      : fistNum.substr(fistNum.length - 4);
    const prefix = fistNum[0];
    const count = 11;
    const masked = "X".repeat(count);
    return `${prefix}${masked}${suffix}`;
  } else {
    if (isString(lastNum) && !isEmptyString(lastNum)) {
      const count = 12;
      const masked = "X".repeat(count);
      const suffix = lastNum.substr(lastNum.length - 4);
      return `${masked}${suffix}`;
    }
  }
  return fistNum;
};
