import { Button } from "../../../UI/Controls/Button";
import { CoffeeOutlined, CloseOutlined } from "@ant-design/icons";

interface IProps {
  onBackMode: Function;
  onRemove: Function;
}

export const CartTopButtons = ({
  onBackMode,
  onRemove,
}: IProps): JSX.Element => {
  return (
    <div className="site-button-ghost-wrapper">
      <Button type="primary" className="ml15" size="lg" onClick={onBackMode}>
        <CoffeeOutlined style={{ fontSize: "22px" }} />
        <span>&nbsp;Done</span>
      </Button>
      <Button
        type="danger"
        className="mr15 fright"
        size="lg"
        onClick={onRemove}
      >
        <CloseOutlined style={{ verticalAlign: "2px" }} />
        <span>&nbsp;Clear</span>
      </Button>
    </div>
  );
};
