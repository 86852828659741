import moment from "moment";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Sider } from "../../components/UI/Layout";
import { useQuery } from "../../hooks";
import type { ICartItem } from "../../store/types";
import { CartView } from "./Cart/ViewScreen/CartView";
import { CartEdit } from "./Cart/EditScreen/CartEdit";
import { setCategory } from "../../store/category";
import { setProduct } from "../../store/product";
import { itemsSelector, totalSelector } from "../../store/selector/cart";
import { resetOrder, saveOrder } from "../../store/order";
import { resetPayment } from "../../store/payment";
import { deleteOrderItem, updateOrderItem } from "../../store/order";

export const OrderSider = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ c: string; p: string; edit: string }>();
  const { p = "" } = params;

  const query = useQuery();
  const formMode = !!query.get("edit") === true ? "edit" : "view";

  const dispatch = useAppDispatch();
  const { order, store, station, product, scaleWeight } = useAppSelector(
    (state) => ({
      order: {
        ...state.order,
        user: state.auth.me,
        items: itemsSelector(state),
        totals: totalSelector(state),
      },
      product: itemsSelector(state).find((o: ICartItem) => o.hash === p),
      store: state.store.selected,
      station: state.store.station,
      scaleWeight: state.igwExtension.scaleWeight,
    })
  );

  useEffect(() => {
    const hasItems = order.items && order.items.length;
    if (hasItems && scaleWeight && formMode === "view") {
      const { items = [] } = order;
      const dates = items
        .filter((o: ICartItem) => o.updated)
        .map((o: ICartItem) => moment(o.updated));
      const maxDate = moment.max(dates);
      const item = items.find(
        (o: ICartItem) => o.updated && moment(o.updated).isSame(moment(maxDate))
      );
      if (item) {
        updateOrderItem(item, "quantity", scaleWeight)(dispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleWeight]);

  const onChangeCartItem = (
    item: ICartItem | null,
    field: string,
    value: any
  ) => {
    if (item && item.hash) {
      if (field === "individualDiscount" && +value > 100) {
        return;
      }
      updateOrderItem({ hash: item.hash }, field, value)(dispatch);
    }
  };

  const onBackMode = () => {
    navigate("/register");
    setCategory(null)(dispatch);
    setProduct(null)(dispatch);
  };

  const onRemoveItem = () => {
    onBackMode();
    deleteOrderItem(product)(dispatch);
  };

  const onResetOrder = () => {
    onBackMode();
    resetOrder()(dispatch);
    resetPayment()(dispatch);
  };

  const onSetCartItem = (item: ICartItem) => {
    const { subcategories: categoryModifiers = [] } = item;
    if (categoryModifiers && categoryModifiers.length) {
      navigate(`${item.url || ""}/p/${item.hash}/m?edit=true`);
    } else {
      navigate(`${item.url || ""}/${item.hash}?edit=true`);
    }
  };

  const onCheckout = () => {
    navigate("/register/checkout");
  };

  const collapsed = !(order.items && order.items.length);
  const currentProduct = product
    ? order.items.find((o: ICartItem) => o.hash === product.hash)
    : null;

  return (
    <Sider
      width={window.innerWidth > 600 ? 300 : window.innerWidth - 80}
      trigger={null}
      id={"cart-sider"}
      style={{
        borderTopLeftRadius: "5px",
        transition: "all 0.2s",
        marginTop: "160px",
        paddingTop: "5px",
        position: "fixed",
        overflow: "hidden",
        height: "100vh",
        bottom: 0,
        zIndex: 2,
        right: collapsed ? "-80px" : 0,
        top: 0,
      }}
      collapsible
      collapsed={collapsed}
    >
      {formMode === "view" ? (
        <>
          <CartView
            {...{
              order,
              item: currentProduct,
              onSave: () => {
                const { _id, _rev, ...holdOrder } = order;
                saveOrder({ ...holdOrder, status: "hold", store, station })(
                  dispatch
                );
              },
              onReset: onResetOrder,
              onSetCartItem,
              onCheckout,
            }}
          />
        </>
      ) : null}
      {formMode === "edit" ? (
        <CartEdit
          {...{
            item: currentProduct,
            scaleWeight,
            onBackMode,
            onRemoveItem,
            onChangeCartItem,
          }}
        />
      ) : null}
    </Sider>
  );
};
