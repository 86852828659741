import { BUILD, MERCHANT, BASE_V2_URL, BASE_URL, BASE_V3_URL, getRootUrl } from "../constants/api";

export interface IReqOptionsProps {
  url: string;
  method: string;
  body?: any;
}

export type ParamId = string | number;
export type ParamName = string;
export type ParamObject = any;
export type Response = any;

export const getMerchant = () => MERCHANT;

export const getEnv = (variable: string, defaultValue: string = "") =>
  defaultValue;

export const getAccessToken = (): string =>
  localStorage.getItem(
    getEnv("API_ACCESS_TOKEN", `Authorization${getMerchant().toUpperCase()}`)
  );

const getRequestHeaders = () => {
  const reqHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (getMerchant()) {
    reqHeaders["X-Merchant"] = getMerchant();
  }
  const authToken = getAccessToken();
  if (authToken) {
    reqHeaders["X-Authorization"] = `Bearer ${authToken}`;
  }
  return reqHeaders;
};

const request = (options: IReqOptionsProps) => {
  const headers = getRequestHeaders();
  const defaults = { headers: headers, mode: "cors", redirect: "follow" };
  options = { ...defaults, ...options };

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export const UserAPI = {
  fetchOne: (userId: ParamId) => {
    return request({
      url: BASE_V2_URL + "/users/" + userId,
      method: "GET",
    });
  },
  authCheck: (username: string, password: string) => {
    return request({
      url: BASE_URL + "/auth/check",
      method: "POST",
      body: JSON.stringify({ username: username, password: password }),
    });
  },
  verify: () => {
    return request({
      url: BASE_URL + "/auth/verify",
      method: "POST",
    });
  },
};

export const GiftCardAPI = {
  fetchOne: (query: string) => {
    return request({
      url: BASE_V2_URL + "/gift_card/search",
      method: "POST",
      body: JSON.stringify(query),
    });
  },
  saveOne: (object: ParamObject) => {
    return request({
      url: BASE_V2_URL + "/gift_card/update",
      method: "POST",
      body: JSON.stringify(object),
    });
  },
};

export const OnlineTransactionAPI = {
  sale: (props: any) => {
    return request({
      url: BASE_URL + "/transaction/sale",
      method: "POST",
      body: JSON.stringify(props),
    });
  },
};

export const MerchantAPI = {
  fetchOne: () => {
    return request({
      url: BASE_V2_URL + "/config",
      method: "GET",
    });
  },
};

export const StoreAPI = {
  fetchAll: () => {
    return request({
      url: BASE_V2_URL + "/stores",
      method: "GET",
    });
  },
};

export const DepartmentAPI = {
  fetchOne: (departmentId: ParamId) => {
    return request({
      url: BASE_V2_URL + "/departments/" + departmentId,
      method: "GET",
    });
  },
  fetchAll: () => {
    return request({
      url: BASE_V2_URL + "/departments",
      method: "GET",
    });
  },
};

export const CategoryAPI = {
  fetchAll: () => {
    return request({
      url: BASE_V2_URL + "/categories",
      method: "GET",
    });
  },
};

export const ProductAPI = {
  fetchByCategory: (category: ParamName) => {
    return request({
      url: BASE_V3_URL + "/products/list?m=" + MERCHANT + `&category=${category}&limit=100000`,
      method: "GET",
    });
  },
  fetchByCategories: (categories: Array<ParamName>) => {
    return request({
      url: BASE_V3_URL + "/products/list?m=" + MERCHANT + "category=" + categories.join("|") + "&limit=100000",
      method: "GET",
    });
  },
  fetchByCategoryStat: () => {
    return request({
      url: BASE_V3_URL + "/products/categories/stat?m=" + MERCHANT,
      method: "GET",
    });
  },
  search: (params: string) => {
    return request({
      url: BASE_V3_URL + "/products/list?m=" + MERCHANT + `&limit=100000&${params}`,
      method: "GET",
    });
  },
};

export const CustomerAPI = {
  search: (params: string) => {
    return request({
      url: BASE_V3_URL + "/customers/list?m=" + MERCHANT + `&${params}&limit=100000`,
      method: "GET",
    });
  },
  create: (object: ParamObject) => {
    return request({
      url: BASE_V3_URL + "/customers?m=" + MERCHANT,
      method: "POST",
      body: JSON.stringify({ ...object, type: "customer" }),
    });
  },
  signUp: (object: ParamObject) => {
    return request({
      url: BASE_V3_URL + "/customers/auth/signup?m=" + MERCHANT,
      method: "POST",
      body: JSON.stringify(object),
    });
  },
  login: (object: ParamObject) => {
    return request({
      url: BASE_V3_URL + "/customers/auth/login?m=" + MERCHANT,
      method: "POST",
      body: JSON.stringify(object),
    });
  },
  verify: () => {
    return request({
      url: BASE_V3_URL + "/customers/auth/verify?m=" + MERCHANT,
      method: "GET",
    });
  },
};

// export const DocumentAPI = {
//   fetchById: (id: string) => {
//     return request({
//       url: BASE_URL + "/db/" + id,
//       method: "GET",
//     });
//   },
// };

export const OrderAPI = {
  autosubmit: (object: ParamObject) => {
    return request({
      url: BASE_V2_URL + "/autosubmit",
      method: "POST",
      body: JSON.stringify(object),
    });
  },
  fetch: (id: string) => {
    console.log({ id });
    return request({
      url: BASE_URL + `/db/${id}`,
      method: "GET",
    });
  },
  fetchOnHold: () => {
    return request({
      url: BASE_URL + `/orders/hold?limit=10000&skip=0`,
      method: "POST",
      body: JSON.stringify({
        filterModel: {
          type: {
            type: "not.equals",
            filterType: "text",
            filter: "deleted-order",
          },
        },
      }),
    });
  },
  save: (object: ParamObject) => {
    return request({
      url: BASE_URL + "/orders/create",
      method: "POST",
      body: JSON.stringify({ ...object, type: "order" }),
    });
  },
  remove: (id: string, object: ParamObject) => {
    return request({
      url: BASE_URL + `/db/${id}`,
      method: "PUT",
      body: JSON.stringify({ ...object, _deleted: true }),
    });
  },
};

export const POSLink = {
  start: (payload: any) => {
    return request({
      url: BASE_URL + "/poslink-station",
      method: "POST",
      body: JSON.stringify({ ...payload, type: "order" }),
    });
  },
  check: (requestId: ParamName) => {
    return request({
      url: BASE_URL + `/poslink-station/${requestId}`,
      method: "GET",
    });
  },
  stop: (requestId: ParamName) => {
    return request({
      url: BASE_URL + `/poslink-station/${requestId}`,
      method: "DELETE",
      body: JSON.stringify({ message: "stopped" }),
    });
  },
};


export const Dejavoo = {
  start: (payload: any) => {
    return request({
      url: BASE_URL + "/dejavoo-station",
      method: "POST",
      body: JSON.stringify({ ...payload, type: "order" }),
    });
  },
  check: (requestId: ParamName) => {
    return request({
      url: BASE_URL + `/dejavoo-station/${requestId}`,
      method: "GET",
    });
  },
  stop: (requestId: ParamName) => {
    return request({
      url: BASE_URL + `/dejavoo-station/${requestId}`,
      method: "DELETE",
      body: JSON.stringify({ message: "stopped" }),
    });
  },
};

export const Heartbeat = {
  ping: (user: string) => {
    // const w = window,
    //   d = w.document,
    //   e = d.documentElement,
    //   g = d.getElementsByTagName("body")[0],
    //   x = w.innerWidth || e.clientWidth || g.clientWidth,
    //   y = w.innerHeight || e.clientHeight || g.clientHeight;
    // const ha = window.location.pathname;
    // const where =
    //   ha.indexOf("?") === -1 ? ha : ha.substring(1, ha.indexOf("?"));
    // fetch(getRootUrl() + "/heartbeat", {
    //   method: "POST",
    //   body: "",
    //   headers: {
    //     "X-User": user,
    //     "X-Merchant": getMerchant(),
    //     "X-Where": where,
    //     "X-Screen": x + "x" + y,
    //     "X-Version": "pos",
    //     "X-Build": BUILD,
    //   },
    // });
  },
};
