import { useAppSelector, useAppDispatch } from "../../../hooks";
import { Card } from "../../UI/Layout";
import {removeOrderGiftCard, removeOrderPayment, setOrderCustomer} from "../../../store/order";
import { paymentTypesSelector } from "../../../store/selector/store";
import type { IPayment } from "../../../store/types";
import { PaymentRender } from "./PaymentRender";

export const PaidList = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { order, paymentTypes } = useAppSelector((state) => ({
    order: state.order,
    paymentTypes: paymentTypesSelector(state),
  }));

  const onRemove = (object: IPayment) => {
    if (
      object &&
      object.type === "employeePayrollDeduct" &&
      order.customer &&
      !order.customer._id
    ) {
      setOrderCustomer({ ...order.customer, employeeNum: "" })(dispatch);
    }
    if (object && object.type === "giftCard") {
      removeOrderGiftCard()(dispatch);
    }
    removeOrderPayment(object)(dispatch);
  };

  const { payment } = order;
  return (
    <Card>
      <PaymentRender
        payment={payment}
        paymentTypes={paymentTypes}
        onRemove={onRemove}
      />
    </Card>
  );
};
